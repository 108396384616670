import React from "react";


import {Text,  Flex, Link } from "@chakra-ui/react";
import "../../../views/pages/style.css";
import Spaceimage from "../../../assets/images/Media&newsroomPage/space.webp";
import Awardimage from "../../../assets/images/Media&newsroomPage/award.webp";
import Abbbieimage from "../../../assets/images/Media&newsroomPage/abbie.webp";
import Madpotatoimage from "../../../assets/images/Media&newsroomPage/madpotato.webp";
import Roehillimage from "../../../assets/images/Media&newsroomPage/roehillnew.webp";
import Spot from "../../../assets/images/Media&newsroomPage/spot.webp";
function Newsslides(props) {
 
  return (
   
    <Flex mt={"4%"} width={"100%"} flexDir={"column"}>
      <Flex
        // p={{ base: "10px", md: "10px", lg: "40px" }}
        width={"100%"}
      >
        <Flex  width={{ base: "90%", md: "90%", lg: "70%" }} mx={"auto"}>
          <Link
          isExternal
            // mx={{ base: "1px", md: "3px", lg: "10px" }}
            width={"40%"}
            href="https://www.pressandjournal.co.uk/fp/business/5404933/aberdeen-firm-receives-10000-to-track-food-from-space/?utm_source=linkedin%22%20\t%20%22_blank"
            _hover={{ transform: "scale(1.05)" }}
            transition="transform 0.3s ease-in-out"
          >
            <Flex
              border={"1px solid #01B1D8"}
              width={"100%"}
              borderRadius={"10px"}
              height={{ base: "100px", md: "150px", lg: "230px" }}
              backgroundImage={Spaceimage}
              backgroundRepeat={"no-repeat"}
              backgroundSize={"fill"}
            >
              <Text
                mt={"20px"}
                ml={{ base: "10px", md: "10px", lg: "20px" }}
                pr={"10px"}
                fontSize={{ base: "10px", md: "12px", lg: "16px" }}
                color={"white"}
                className="content_font"
                fontWeight={"light"}
                width={{ base: "", md: "", lg: "350px" }}
                lineHeight={{ base: "15px", md: "20px", lg: "30px" }}
              >
                Aberdeen firm receives £10,000 to track food from outer space
              </Text>
            </Flex>
          </Link>

          <Link
          isExternal
          ml={{ base: "7px", md: "13px", lg: "20px" }}
            // mx={"10px"}
            width={"60%"}
            href="https://www.thenational.scot/news/17538250.scotlands-best-entrepreneurs-pick-6000-sie-fresh-ideas-awards/"
            _hover={{ transform: "scale(1.05)" }}
            transition="transform 0.3s ease-in-out"
          >
            <Flex
              border={"1px solid #01B1D8"}
              width={"100%"}
              borderRadius={"10px"}
              height={{ base: "100px", md: "150px", lg: "230px" }}
              backgroundImage={Awardimage}
              backgroundRepeat={"no-repeat"}
              backgroundSize={"cover"}
            >
              <Text
                mt={"20px"}
                ml={{ base: "10px", md: "10px", lg: "20px" }}
                pr={"10px"}
                fontSize={{ base: "10px", md: "12px", lg: "16px" }}
                color={"white"}
                className="content_font"
                fontWeight={"light"}
                width={{ base: "", md: "", lg: "350px" }}
                lineHeight={{ base: "15px", md: "20px", lg: "30px" }}
              >
                Scotland's best entrepreneurs pick up £6000 at SIE Fresh Ideas
                awards
              </Text>
            </Flex>
          </Link>
        </Flex>
      </Flex>

      <Flex mt={{ base: "10px", md: "10px", lg: "20px" }} width={"100%"}>
        <Flex width={{ base: "90%", md: "90%", lg: "70%" }} mx={"auto"}>
          <Link
          isExternal
            // mx={"10px"}
            width={"60%"}
            href="https://www.pressandjournal.co.uk/fp/news/moray/5045488/abbies-sparkle-foundation-gin/"
            _hover={{ transform: "scale(1.05)" }}
            transition="transform 0.3s ease-in-out"
          >
            <Flex
              border={"1px solid #01B1D8"}
              width={"100%"}
              borderRadius={"10px"}
              height={{ base: "100px", md: "150px", lg: "230px" }}
              backgroundImage={Abbbieimage}
              backgroundRepeat={"no-repeat"}
              backgroundSize={"fill"}
            >
              <Text
                mt={"20px"}
                ml={{ base: "10px", md: "10px", lg: "20px" }}
                pr={"10px"}
                fontSize={{ base: "10px", md: "12px", lg: "16px" }}
                color={"white"}
                className="content_font"
                fontWeight={"light"}
                width={{ base: "", md: "", lg: "350px" }}
                lineHeight={{ base: "15px", md: "20px", lg: "30px" }}
              >
                Cheers to Abbie! Charity launches limited edition gin complete
                with signature sparkle
              </Text>
            </Flex>
          </Link>

          <Link
          isExternal
            // mx={{ base: "1px", md: "3px", lg: "10px" }}
            ml={{ base: "7px", md: "13px", lg: "20px" }}
            width={"40%"}
            href="https://www.pressandjournal.co.uk/fp/lifestyle/society/3640971/aberdeens-mad-potato-launches-new-technology-to-change-how-we-shop/"
            _hover={{ transform: "scale(1.05)" }}
            transition="transform 0.3s ease-in-out"
          >
            <Flex
              border={"1px solid #01B1D8"}
              width={"100%"}
              borderRadius={"10px"}
              height={{ base: "100px", md: "150px", lg: "230px" }}
              backgroundImage={Madpotatoimage}
              backgroundRepeat={"no-repeat"}
              backgroundSize={"fill"}
            >
              <Text
                mt={"20px"}
                ml={{ base: "10px", md: "10px", lg: "20px" }}
                pr={"10px"}
                fontSize={{ base: "10px", md: "12px", lg: "16px" }}
                color={"white"}
                className="content_font"
                fontWeight={"light"}
                width={{ base: "", md: "", lg: "350px" }}
                lineHeight={{ base: "15px", md: "20px", lg: "30px" }}
              >
                Aberdeen’s Mad Potato launches new technology to change how we
                shop
              </Text>
            </Flex>
          </Link>
        </Flex>
      </Flex>

      <Flex mt={{ base: "10px", md: "10px", lg: "20px" }} width={"100%"}>
        <Flex width={{ base: "90%", md: "90%", lg: "70%" }} mx={"auto"}>
          <Link
          isExternal
            // mx={{ base: "1px", md: "3px", lg: "10px" }}
            width={"40%"}
            href="https://www.digit.fyi/spotlight-some-of-aberdeens-most-interesting-tech-startups/?utm_content=buffer34d0d&utm_medium=social&utm_source=linkedin.com&utm_campaign=buffer"
            _hover={{ transform: "scale(1.05)" }}
            transition="transform 0.3s ease-in-out"
          >
            <Flex
              border={"1px solid #01B1D8"}
              width={"100%"}
              borderRadius={"10px"}
              height={{ base: "100px", md: "150px", lg: "230px" }}
              backgroundImage={Spot}
              backgroundRepeat={"no-repeat"}
              backgroundSize={"fill"}
            >
              <Text
                mt={"20px"}
                ml={{ base: "10px", md: "10px", lg: "20px" }}
                pr={"10px"}
                fontSize={{ base: "10px", md: "12px", lg: "16px" }}
                color={"white"}
                className="content_font"
                fontWeight={"light"}
                width={{ base: "", md: "", lg: "350px" }}
                lineHeight={{ base: "15px", md: "20px", lg: "30px" }}
              >
                Spotlight | Some of Aberdeen’s Most Interesting Tech Startups
              </Text>
            </Flex>
          </Link>

          <Link
          isExternal
          ml={{ base: "7px", md: "13px", lg: "20px" }}
            // mx={"10px"}
            width={"60%"}
            href="https://www.digit.fyi/scots-gin-distillery-uses-blockchain-for-water-clarity/"
            _hover={{ transform: "scale(1.05)" }}
            transition="transform 0.3s ease-in-out"
          >
            <Flex
              border={"1px solid #01B1D8"}
              width={"100%"}
              borderRadius={"10px"}
              height={{ base: "100px", md: "150px", lg: "230px" }}
              backgroundImage={Roehillimage}
              backgroundRepeat={"no-repeat"}
              backgroundSize={"cover"}
            >
              <Text
                mt={"20px"}
                ml={{ base: "10px", md: "10px", lg: "20px" }}
                pr={"10px"}
                fontSize={{ base: "10px", md: "12px", lg: "16px" }}
                color={"white"}
                className="content_font"
                fontWeight={"light"}
                width={{ base: "", md: "", lg: "350px" }}
                lineHeight={{ base: "15px", md: "20px", lg: "30px" }}
              >
                Scots Gin Distillery Uses Blockchain for Water Clarity
              </Text>
            </Flex>
          </Link>
         
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Newsslides;
