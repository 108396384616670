
import rajesh from  "../../src/assets/images/AboutUsPage/rajesh.webp";
import justin from  "../../src/assets/images/AboutUsPage/justin.webp";
import anju from  "../../src/assets/images/AboutUsPage/anju.webp";
import christina from  "../../src/assets/images/AboutUsPage/christina.webp";
import akhil from  "../../src/assets/images/AboutUsPage/akhil.webp";
import jamsheela from  "../../src/assets/images/AboutUsPage/jamsheela.webp";
import syam from  "../../src/assets/images/AboutUsPage/syam.webp";
import nayana from  "../../src/assets/images/AboutUsPage/nayana.webp";
import sarath from  "../../src/assets/images/AboutUsPage/sarath.webp";
import remya from  "../../src/assets/images/AboutUsPage/remya.webp";
import aneesha from  "../../src/assets/images/AboutUsPage/aneesha.webp";
import sherin from  "../../src/assets/images/AboutUsPage/sherin.webp";
import aiswarya from  "../../src/assets/images/AboutUsPage/aiswarya.webp";
import roshan from  "../../src/assets/images/AboutUsPage/roshan.webp";
import simon from  "../../src/assets/images/AboutUsPage/simon.webp";
import katerina from  "../../src/assets/images/AboutUsPage/katerina.webp";
import tony from  "../../src/assets/images/AboutUsPage/tony.webp";
const data = [
  {
    name: "Rajesh Kumar Plamthottathil",

    imagePath: rajesh,
    certificate:"Certified Blockchain Architect",
    post: "Founder & CEO",
    linkedinlink: "https://www.linkedin.com/in/rajesh-kumar-plamthottathil/",
  },
  {
    name: "Katerina Hayes",

    imagePath: katerina,
    certificate:"Development",
    post: "Business",
    linkedinlink: "https://www.linkedin.com/in/katerinahayes/",
  },
  {
    name: "Tony Zaccarini",

    imagePath: tony,
    certificate:"Development Consultant",
    post: "Business",
    linkedinlink: "https://www.linkedin.com/in/tony-zaccarini-a1a03312/",
  },
  {
    name: "Justin J Daniel",
    imagePath: justin,
    certificate:"Certified Full-Stack Developer",
    post: "Certified Blockchain Architect",
    linkedinlink: "https://www.linkedin.com/in/justin-j-daniel/",
  },
  {
    name: "Anju K S",
    imagePath: anju,
    certificate:"Certified Full-Stack Developer",
    post: "Certified Blockchain Architect",
    linkedinlink: "https://www.linkedin.com/in/anju-k-s-26571a212/",
  },
 
  {
    name: "Christina Thomas",
    imagePath: christina,
    certificate:"Certified Full-Stack Developer",
    post: "Certified Blockchain Architect",
    linkedinlink: "https://www.linkedin.com/in/christina-thomas-ba0a15ba/",
  },
 
  {
    name: "Akhil J P",
    imagePath: akhil,
    post: "AR/VR Developer",
    certificate:"Certified Blockchain Associate",
    linkedinlink: "https://www.linkedin.com/in/akhiljptvm/",
  },
  {
    name: "Remya Jose",
    imagePath: remya,
    post: "AR/VR Developer",
    certificate:"Certified Blockchain Associate",
    linkedinlink: "https://www.linkedin.com/in/remya-jose-575b99215/",
  },
  {
    name: "Jamsheela M K",
    imagePath: jamsheela,
    post: "Certified Blockchain Developer",
    certificate:"Certified Full-Stack Developer",
    linkedinlink: "https://www.linkedin.com/in/jamsheela-m-k-270b87126/",
  },
 
  {
    name: "Syam Kumar Susanthan",
    imagePath: syam,
    post: "AR/VR Developer",
    certificate:"Certified Blockchain Developer",
    linkedinlink: "https://www.linkedin.com/in/syam-kumar-susanthan-aaa245219/",
  },
  {
    name: "Nayana Narayanan",
    imagePath: nayana,
    post: "AR/VR Developer",
    certificate:"Certified Blockchain Developer",
    linkedinlink: "https://www.linkedin.com/in/nayana-narayanan-464336163/",
  },
  
  
  {
    name: "Sherin Thomas",
    imagePath: sherin,
    post: "Google Certified Digital Marketer",
    linkedinlink: "https://www.linkedin.com/in/sherin-thomas-b6b582223/",
  },
  {
    name: "Aiswarya P Nair",
    imagePath: aiswarya,
    post: "Certified Blockchain Associate",
    certificate:"Certified Full-Stack Developer",
    linkedinlink: "https://www.linkedin.com/in/aiswaryapnair/",
  },
  {
    name: "Sarath Kumar Susanthan",
    imagePath: sarath,
    post: "Certified Blockchain Developer",
    certificate:"Certified Full-Stack Developer",
    linkedinlink: "https://www.linkedin.com/in/sarath-kumar-susanthan-620b55127/",
  },
  {
    name: "Aneesha M S",
    imagePath: aneesha,
    post: "Certified Blockchain Developer",
    certificate:"Certified Full-Stack Developer",
    linkedinlink: "https://www.linkedin.com/in/aneeshams2311/",
  },
 
  {
    name: "Roshan K",
    imagePath: roshan,
    post: "Certified Blockchain Developer",
    certificate:"Certified Full-Stack Developer",
    linkedinlink: "https://www.linkedin.com/in/roshan-k-468288231/",
  },
  {
    name: "Simon Thomas",
    imagePath: simon,
    post: "AR/VR Developer",
    certificate:"Certified 3D Designer",
    linkedinlink: "https://www.linkedin.com/in/simon-thomas-9b67091b7/",
  },
  
];

export default data;
