import { Flex, Heading, Image, Text } from "@chakra-ui/react";
import React from "react";
import Mobilenavbar from "../../../components/sections/Mobilenavbar";
import Navbar from "../../../components/sections/Navbar";
import Footer from "../../../components/sections/Footer";
import { ListItem, UnorderedList } from "@chakra-ui/react";
import sherinprofile from "../../../assets/images/BlogsPage/sherinprofile.webp";
import blogfour from "../../../assets/images/BlogsPage/blogfour1.webp";
import blogfourimage from "../../../assets/images/BlogsPage/blogfourimage.webp";
import { Helmet } from "react-helmet";

function Blogpagefour(props) {
  return (
    <>
      {/* helmet */}
      <Helmet>
        <title>
          Blogs | Supplychain | TrackGenesis&reg; | Aberdeen Scotland
        </title>
        <meta
          name="title"
          content="Blogs| Supplychain | TrackGenesis | Aberdeen Scotland"
        />
        <meta
          name="description"
          content="How Blockchain Revolutionize Supply Chain Management?"
        />
        <link
          href="https://trackgenesis.com/blogs"
          rel="canonical"
          content="max-age: 1209600, no-cache, no-store, must-revalidate"
        />
     
      </Helmet>
      {/* helmet */}
      <Flex
        backgroundColor={"black"}
        flexDirection={"column"}
        id="blog-supplychain"
      >
        {/* heading Section */}
        <Flex
          backgroundColor={"black"}
          width="100%"
          flexDirection={"column"}
          alignItems="center"
          justifyContent={"center"}
          height={"400px"}
        >
          <Heading
            className="heading"
            color={"#94DAF8"}
            fontSize={{ md: "33px", base: "25px", lg: "37px" }}
            fontWeight="bold"
            textShadow="1px 1px #000000"
            mt={{ base: "36px", md: "35px", lg: "10px" }}
            textAlign="center"
            maxWidth={{ base: "80%", md: "65%", lg: "60%" }}
          >
            How Does Blockchain Revolutionize Supply Chain Management?
          </Heading>
        </Flex>

        <Navbar />
        <Mobilenavbar />

        <Flex
          backgroundColor={"black"}
          alignItems={"center"}
          justifyContent="center"
          width="100%"
        >
          <Image
            zIndex={2}
            ml={{ base: "-5%", lg: "-5%" }}
            mt={{ base: "-90px", md: "-90px", lg: "-110px" }}
            width={{ base: "91%", md: "80%", lg: "70%" }}
            src={blogfour}
          />
        </Flex>
        <Flex
          flexDir={"column"}
          position={"relative"}
          className="mainborder"
          my={{ base: "2%", md: "2%", lg: "2%" }}
          mx={{ base: "10px", md: "15px", lg: "30px" }}
          backgroundColor={"rgba(27, 21, 78, 0.38)"}
          backdropFilter="auto"
          backdropBlur="8px"
          pb={"100px"}
        >
          <Flex flexDir={"column"}>
            <Flex
              color={"white"}
              className="content_font"
              width={"100%"}
              px={{ base: "10%", md: "15%", lg: "20%" }}
              fontSize={{ base: "15px", md: "16px", lg: "17px" }}
              flexDirection="column"
            >
              <Text
                fontSize={{ base: "13px", md: "14px", lg: "15px" }}
                mt={"30px"}
                className="content_font"
              >
                The supply chain network is built by multiple stakeholders and
                units working together. Real-time recording of all transactional
                and administrative data is a laborious undertaking. To ensure
                security in the supply chain is yet another challenge.  
              </Text>

              <Text mt={"30px"} className="content_font">
                Blockchain maintains a distributed ledger of unchangeable
                information about a product dating back to its origin. Multiple
                stakeholders in the supply chain network can access each piece
                of data that is updated in real time. 
              </Text>

              <Text mt={"30px"} className="content_font">
                <Text as={"b"}>
                  {" "}
                  Advantages of Blockchain in Supply Chain Network:
                </Text>
                <UnorderedList ml={"50px"}>
                  <ListItem>Tamper-proof records of data  </ListItem>
                  <ListItem>
                    Multiple stakeholders can share/access the data in
                    real-time 
                  </ListItem>
                  <ListItem>Save time and effort</ListItem>
                  <ListItem>Secured data management system</ListItem>
                  <ListItem>Eradicate fraud </ListItem>
                  <ListItem>Eliminate counterfeits </ListItem>
                  <ListItem>Businesses gain the trust of consumers</ListItem>
                  <ListItem>
                    Consumers learn about a product’s provenance, before they
                    make a purchase
                  </ListItem>
                  <ListItem>Better risk management </ListItem>
                </UnorderedList>
              </Text>

              <Text mt={"30px"} className="content_font">
                Let’s take the example of an apple.
              </Text>
              <Text mt={"30px"} className="content_font">
                A farmer who harvests apples from his farm records the data
                including his name, farm, location, kind of faming,
                pesticides/chemicals used if any, date of harvest, organic
                certified or not and other details. Data is stored in the
                blockchain network with a unique batch id. 
              </Text>
              <Text mt={"30px"} className="content_font">
                Now, apples are taken to the supplier who sells them in the
                wholesale market. He updates his name, location, purchase/sale
                date, and other pertinent information. From there, it might be
                shipped as freight or sold to a business that makes food. 
                Likewise, the supply chain continues. Each stakeholder records
                tamper-proof data into the blockchain network in real-time.
                Evidence for claims such as FSA-certified, vegan, sustainable or
                sugar-free can also be recorded in the same blockchain network.
              </Text>
              <Text mt={"30px"} className="content_font">
                The end supply chain participant stamps a QR code on products
                that go to the consumer.
                <Text fontWeight={"bold"} as={"i"}>
                  {" "}
                  Anyone can scan the QR code and get the entire journey of the
                  product on the mobile screen/desktop.
                </Text>
              </Text>
              <Flex justifyContent={"center"}>
                <Image
                  borderRadius={"10px"}
                  mt="30px"
                  width={{ base: "70%", md: "60%", lg: "60%" }}
                  src={blogfourimage}
                ></Image>
              </Flex>
              <Text mt={"30px"} className="content_font">
                As the product details from provenance are made available to all
                consumers, no stakeholder will dares to modify expiration dates,
                assert false certifications, or introduce spurious and unhealthy
                products into the market.
              </Text>
              <Text mt={"30px"} className="content_font">
                Stakeholders even benefit from integrating blockchain to the
                supply chain network. Data available from the blockchain ensure
                that, they purchase the best quality ingredients and deliver
                quality products to the consumers. Thereby, blockchain enable
                businesses build trust and transparency in the supply chain
                network.
              </Text>
            </Flex>
            <Flex my={"20px"} width={"100%"}>
              <Flex ml={{ base: "40%", md: "65%", lg: "65%" }}>
                <Text
                  color={"white"}
                  className="content_font"
                  fontSize={{ md: "23px", base: "15px", lg: "25px" }}
                >
                  - by Sherin Thomas
                </Text>
                <Image
                    objectFit={"contain"}
                    ml={{ base: "10px", md: "10px", lg: "30px" }}
                    mt={"-10px"}
                    width={{ base: "30px", md: "40px", lg: "55px" }}
                  src={sherinprofile}
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Footer />
      </Flex>
    </>
  );
}

export default Blogpagefour;
