import { Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import { HashLink } from "react-router-hash-link";

import BlockchainInFinance from "../../../assets/images/IndustrysolutionsPage/blockchain-in-finance.webp";
import CommonButton from "../../../components/CommonButton.js";
import Underline from "../../../assets/images/HomePage/underline.webp";
function Finance() {
  return (
   

    <Flex mt={{ base: "20px", md: "80px", lg: "200px" }} width={"100%"}>
      {/* title */}
      <Flex mx={"auto"} flexDir={{ base: "column", md: "row", lg: "row" }}>
        <Flex p={"20px"} width={{ base: "100%", md: "60%", lg: "50%" }}>
          <Image
            my={{ base: "30px", md: "0px", lg: "0px" }}
            objectFit={"contain"}
            src={BlockchainInFinance}
            // srcSet={`${BlockchainInFinanceSmall} 1000w, ${BlockchainInFinance} 2000w`}
            width={"100%"}
            alt="Fintech Blockchain "
            pointerEvents={"none"}
          />
        </Flex>

        <Flex
          width={{ base: "100%", md: "60%", lg: "50%" }}
          flexDir={"column"}
          ml={{ base: "6%", md: "6%", lg: "10%" }}
        >
          <Text
            className="heading"
            color={"#94DAF8"}
            fontSize={{ base: "24px", md: "38px", lg: "45px" }}
          >
            Blockchain in Fintech
          </Text>
          <Image
            mt={"-10px"}
            ml={"-10px"}
            width={"70px"}
            src={Underline}  alt="underline"
          ></Image>

          <Text
            mt={{ base: "30px", md: "40px", lg: "80px" }}
            pr={{ base: "40px", md: "30px", lg: "30px" }}
            maxW={{ base: "", md: "380px", lg: "650px" }}
            fontSize={{ base: "14px", md: "14px", lg: "16px" }}
            color={"white"}
            className="content_font"
            fontWeight={"light"}
          >
            The security of online transactions has always been a concern. A
            blockchain solution promises security, transparency, and trust in
            the transfer of digital assets. It implements a decentralized system
            that saves huge cost and time on maintaining transaction history and
            administration.
          </Text>
          <HashLink smooth to={"/contact-us#contact"}>
            <CommonButton
            width="100px"
              btn="Book a Demo"
              mt={{ base: "30px", md: "60px", lg: "30px" }}
            />
          </HashLink>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Finance;
