/** @format */

import {  Image, Flex, Text } from "@chakra-ui/react";
import React from "react";
import "./style.css";
import Navbar from "../../components/sections/Navbar";
import Footer from "../../components/sections/Footer";
import Mobilenavbar from "../../components/sections/Mobilenavbar";



import { Helmet } from "react-helmet";

import Softheader from "../../assets/images/SoftwarePage/softheader.webp";

import UiuxDesign from "../../components/sections/Software/UiuxDesign";
import Webapp from "../../components/sections/Software/Webapp";
import IotDevices from "../../components/sections/Software/IotDevices";
import CustomWebsite from "../../components/sections/Software/CustomWebsite";
import ParticleBackground from "../../components/sections/home/ParticleBackground";
import ParticleTrail from "../../components/sections/home/ParticleTrail";
import BlkRing from "../../assets/images/IndustrysolutionsPage/ring.webp";
import Softnew from "../../assets/images/SoftwarePage/newsoft.gif";
function SoftwareSolutions(props) {
  return (
    <>
      {/* helmet */}
      <Helmet>
        <title>
        Best Software Solutions developers | Software Solutions creation | TrackGenesis® | Scotland UK 
        </title>
        <meta
          name="title"
          content="Top Software Company |App Developers | TrackGenesis®️ | Scotland UK"
        />
        <meta
          name="description"
          content="We design exceptional UI, develop custom software and apps tailored for your business. We serve as a dedicated technology partner to achieve complete digital transformation."
        />
        <link
          href="https://trackgenesis.com/services/software-solutions"
          rel="canonical"
          content="max-age: 1209600, no-cache, no-store, must-revalidate"
        />
      
              {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=UA-197747980-1"
    ></script>
      </Helmet>
      {/* helmet */}

      <Flex
        id="softsection"
        flexDirection={"column"}
        backgroundColor={"black"}
        overflowX={"hidden"}
      >
       
        {/* new heading Section */}
        <Flex>
          <Image
           alt="Software Development TrackGenesis"
            ml={{base:"-50px",md:"-40px",lg:"0px"}}
            width={{ base: "270px", md: "400px", lg: "500px" }}
            src={Softheader}
          ></Image>
          <Text
            mt={"auto"}
            className="heading"
            
            mb={{base:"15px",md:"15px",lg:"30px"}}
            color={"#94DAF8"}
            fontSize={{ base: "30px", md: "45px", lg: "50px" }}
            ml={{ base: "-29%", md: "-19%", lg: "-10%" }}
          >
            Software Solutions
          </Text>
        </Flex>

        <Navbar />
        <Mobilenavbar />

        {/* new content */}
     
      <ParticleTrail/>
        <Flex
          flexDir={"column"}
          position={"relative"}
          className="mainborder"
      
          mx={{ base: "10px", md: "15px", lg: "30px" }}
          backgroundColor={"rgba(27, 21, 78, 0.38)"}
          backdropFilter="auto"
          backdropBlur="4px"
          pb={"100px"}
        >
          {/* nft ontro */}
          <Flex
            my={{ base: "30px", md: "30px", lg: "90px" }}
            // p={"60px"}
            mx={"auto"}
            width={{ base: "80%", md: "80%", lg: "900px" }}
            flexDir={{
              base: "column-reverse",
              md: "column-reverse",
              lg: "row",
            }}
          >
            <Flex
              flexDir={"column"}
              mt={{ base: "-60px", md: "-50px", lg: "0px" }}
              justifyContent={"center"}
              alignItems={"center"}
              backgroundColor={"rgba(0, 0, 0, 0.15)"}
              borderRadius={"5px"}
              backdropFilter="auto"
              backdropBlur="2px"
              boxShadow={"0px 0px 32.5px -12px rgba(1, 177, 216, 0.45) inset"}
            >
              
              <Text
                pr={{ base: "20px", md: "30px", lg: "150px" }}
                pl={{ base: "20px", md: "30px", lg: "50px" }}
                pb={{ base: "40px", md: "30px", lg: "30px" }}
                pt={{ base: "90px", md: "90px", lg: "30px" }}
                fontSize={{ base: "14px", md: "14px", lg: "17px" }}
                color={"white"}
                className="content_font"
                fontWeight={"light"}
                maxW={{ base: "600px", md: "600px", lg: "800px" }}
              >
                TrackGenesis is an award-winning software development company
                based in Aberdeen, Scotland. We develop custom software
                solutions that fit your organization. Our team has a
                client-focused mindset, and our approach is to deep dive into
                and understand your business and its requirements to design and
                develop the software that fits your business needs.
              </Text>
            </Flex>
            

              <Image
              mr={{ base: "auto", md: "auto", lg: "0px" }}
              alt="Software Development TrackGenesis"
              
              ml={{ base: "auto", md: "auto", lg: "-150px" }}
              zIndex={2}
              width={{ base: "180px", md: "180px", lg: "280px" }}
              objectFit={"contain"}
              src={Softnew}
            ></Image>
            <Image 
            alt="Software Development TrackGenesis"
            width={{ base: "210px", md: "210px", lg: "330px" }} 
            objectFit={"contain"} 
            zIndex={1}
            mr={{ base: "auto", md: "auto", lg: "0px" }}
            mb={{base:"-180px",md:"-180px",lg:"0px"}}
            ml={{base:"auto",md:"auto",lg:"-300px"}}
            src={BlkRing}>

            </Image>

          </Flex>

          {/* UI UX Design */}
            <UiuxDesign/>

          {/* Webapp */}
          <Webapp/>

          {/* IOT Devices */}
          <IotDevices/>

          {/* Custom website */}
          <CustomWebsite/>

         
        </Flex>
        <ParticleBackground />
        <Footer />
      </Flex>
    </>
  );
}

export default SoftwareSolutions;
