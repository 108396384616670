import React, { useState } from "react";
import "./style.css";
import TrackGenSplash from "../../components/TGSplash/TrackGenSplash";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

function Mainpage(props) {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  setTimeout(() => {
    setLoading(false);
  }, 11200);

  return (
    <>
      {/* helmet */}
      <Helmet>
        <title>
          TrackGenesis&reg; | Top Software & Blockchain Company | Scotland
        </title>

        <meta
          name="description"
          content="TrackGenesis is the leading software and blockchain development company that supports sustainable business by ensuring transparency and immutability."
        />
        <link href="https://trackgenesis.com" rel="canonical" />
     
      
        {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-197747980-1"
        ></script>
      </Helmet>
      {/* helmet */}
      <div>
        {/* <Home/> */}
        {loading ? <TrackGenSplash /> : navigate("/home", { replace: true })}
      </div>
    </>
  );
}

export default Mainpage;
