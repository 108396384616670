import {
  Flex,
  Button,
  Text,
  Image,
  Box,
  SimpleGrid,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useState } from "react";
import "../section.css";

import { HashLink } from "react-router-hash-link";

import Underline from "../../../assets/images/HomePage/underline.webp";
import Servicerobo from "../../../assets/images/HomePage/Homeservices-final.webp";
import Blk from "../../../assets/images/HomePage/blk.webp";
import ARVR from "../../../assets/images/HomePage/ar.webp";

import Soft from "../../../assets/images/HomePage/soft.webp";

function HomeServiceSection(props) {
  const [openIndex, setOpenIndex] = useState(null);

  const handleBoxClick = (index) => {
    setOpenIndex(index === openIndex ? null : index);
  };
  const boxHeightopen = useBreakpointValue({
    base: "500px",
    md: "500px",
    lg: "520px",
  });

  const boxHeightclose = useBreakpointValue({
    base: "190px",
    md: "240px",
    lg: "270px",
  });

  return (
    // Our Service section(new)
    <Flex
    
      zIndex={4}
      mt={{ base: "50px", md: "0", lg: "0" }}
      py={{ base: "20px", md: "", lg: "" }}
      height={{ base: "fit-content", md: "fit-content", lg: "fit-content" }}
      width={"100%"}
    >
      <Flex
        flexDir={"column"}
        mb={{ base: "100px", md: "200px", lg: "300px" }}
        width={"100%"}
      >
        {/* Heading */}
        <Flex my={{ base: "30px", md: "10px", lg: "10px" }} mx={"auto"}>
          <Image
            mt={{ base: "6px", lg: "15px" }}
            height={"40px"}
            width={"70px"}
            src={Underline}  alt="underline"
          ></Image>
          <Text
            className="heading"
            color={"#94DAF8"}
            fontSize={{ base: "24px", md: "38px", lg: "45px" }}
          >
            Our Services
          </Text>
          <Image
            mt={{ base: "6px", lg: "15px" }}
            height={"40px"}
            width={"70px"}
            src={Underline}  alt="underline"
          ></Image>
        </Flex>

        {/* Content */}
        <Flex  width={"100%"} mx={"auto"} mt={"12%"}>
          <SimpleGrid
        
            mx={"auto"}
            // width={"fit-content"}
            width={{ base: "90%", md: "95%", lg: "70%" }}
            columns={[2, null, 3]}
            spacing={{ base: "20px", md: "20px", lg: "20px" }}
          >
            {/* Blockchain */}
            <Box
         
            
              _hover={{
                backgroundColor: "rgba(0, 0, 0, 0.3)",
              }}
              cursor={"pointer"}
              style={{
                height: 1 === openIndex ? boxHeightopen : boxHeightclose,
                transition: "height 0.4s linear",
              }}
              overflow={"hidden"}
              backgroundColor={"rgba(0, 0, 0, 0.15)"}
              borderRadius={"5px"}
              backdropFilter="auto"
              backdropBlur="2px"
              boxShadow={"0px 0px 32.5px -12px rgba(1, 177, 216, 0.45) inset"}
              onClick={() => handleBoxClick(1)}
              // width={{ base: "135px", md: "170px", lg: "290px" }}
            >
              <Flex
                flexDir={"column"}
                p={{ base: "25px", md: "40px", lg: "40px" }}
              >
                <Image
                
                  alt="Trackgenesis blockchain"
                  className="floatinganime"
                  mx={"auto"}
                  width={{ base: "130px", md: "130px", lg: "200px" }}
                  src={Blk}
                ></Image>
                <Text
                 textAlign={"center"}
                  mt={"20px"}
                  mx={"auto"}
                  fontSize={{ base: "13px", md: "15px", lg: "17px" }}
                  className="content_font"
                  color={"white"}
                >
                  Blockchain Development
                </Text>
                <Text
                  style={{
                    opacity: 1 === openIndex ? 1 : 0,
                    transition: "opacity 0.1s linear",
                  }}
                  mt={"20px"}
                  // maxW={"160px"}
                  fontSize={{ base: "11px", md: "11px", lg: "15px" }}
                  className="content_font"
                  color={"white"}
                >
                  Blockchain builds a transparent and trust-worthy business and
                  prove your sustainability claims of your consumer good
                  products. By adopting promising blockchain technology, your
                  business can stand out among the competitors and win new
                  customers as well as investors.
                </Text>
                <HashLink smooth to={"/services/blockchain#blockchain"}>
                  <Flex
                    ml={"auto"}
                    mt={"20px"}
                    borderRadius={"3px"}
                    padding={"2px"}
                    width={"fit-content"}
                    height={"fit-content"}
                    border="1px solid #01B0D7"
                  >
                    <Button
                      width={{ base: "68px", md: "80px", lg: "90px" }}
                      height={{ base: "16px", md: "20px", lg: "28px" }}
                      className="content_font"
                      borderRadius={"2px"}
                      backgroundColor={"#01B0D7"}
                      _hover={{ bg: "#8DE6F9" }}
                      fontSize={{
                        base: "12px",
                        md: "13px",
                        lg: "14px",
                      }}
                      type="submit"
                    >
                      Read more
                    </Button>
                  </Flex>
                </HashLink>
              </Flex>
            </Box>

            {/* ARVR */}
            <Box
              _hover={{
                backgroundColor: "rgba(0, 0, 0, 0.3)",
              }}
              cursor={"pointer"}
              onClick={() => handleBoxClick(2)}
              style={{
                height: 2 === openIndex ? boxHeightopen : boxHeightclose,
                transition: "height 0.4s linear",
              }}
              overflow={"hidden"}
              backgroundColor={"rgba(0, 0, 0, 0.15)"}
              borderRadius={"5px"}
              backdropFilter="auto"
              backdropBlur="2px"
              boxShadow={"0px 0px 32.5px -12px rgba(1, 177, 216, 0.45) inset"}
              // width={{ base: "135px", md: "170px", lg: "230px" }}
            >
              <Flex
                flexDir={"column"}
                p={{ base: "25px", md: "40px", lg: "40px" }}
              >
                <Image
                  alt="TrackGenesis arvr"
                  className="floatinganime"
                  mx={"auto"}
                  width={{ base: "130px", md: "130px", lg: "200px" }}
                  src={ARVR}
                ></Image>
                <Text
                  mt={"20px"}
                  mx={"auto"}
                  fontSize={{ base: "13px", md: "15px", lg: "17px" }}
                  className="content_font"
                  color={"white"}
                >
                  Extended Reality
                </Text>
                <Text
                  style={{
                    opacity: 2 === openIndex ? 1 : 0,
                    transition: "opacity 0.1s linear",
                  }}
                  mt={"20px"}
                  // maxW={"160px"}
                  fontSize={{ base: "11px", md: "11px", lg: "15px" }}
                  className="content_font"
                  color={"white"}
                >
                  Redefine the future of business by placing the products and
                  services in digital realm.Immersive experience offered by
                  augmented reality and virtual reality, not only adds value to
                  the consumers but also revenue generating.
                </Text>
                <HashLink smooth to={"/services/ar-vr#arvr"}>
                  <Flex
                    ml={"auto"}
                    mt={"20px"}
                    borderRadius={"3px"}
                    padding={"2px"}
                    width={"fit-content"}
                    height={"fit-content"}
                    border="1px solid #01B0D7"
                  >
                    <Button
                    borderRadius={"2px"}
                      width={{ base: "68px", md: "80px", lg: "90px" }}
                      height={{ base: "16px", md: "20px", lg: "28px" }}
                      className="content_font"
                      backgroundColor={"#01B0D7"}
                      _hover={{ bg: "#8DE6F9" }}
                      fontSize={{
                        base: "12px",
                        md: "13px",
                        lg: "14px",
                      }}
                      type="submit"
                    >
                      Read more
                    </Button>
                  </Flex>
                </HashLink>
              </Flex>
            </Box>

            {/* NFT */}
            {/* <Box
              _hover={{
                backgroundColor: "rgba(0, 0, 0, 0.3)",
              }}
              cursor={"pointer"}
              overflow={"hidden"}
              backgroundColor={"rgba(0, 0, 0, 0.15)"}
              borderRadius={"5px"}
              backdropFilter="auto"
              backdropBlur="2px"
              boxShadow={"0px 0px 32.5px -12px rgba(1, 177, 216, 0.45) inset"}
              onClick={() => handleBoxClick(3)}
              style={{
                height: 3 === openIndex ? boxHeightopen : boxHeightclose,
                transition: "height 0.4s linear",
              }}
              // width={{ base: "135px", md: "170px", lg: "230px" }}
            >
              <Flex
                flexDir={"column"}
                p={{ base: "25px", md: "40px", lg: "40px" }}
              >
                <Image
                  alt="TrackGenesis nft"
                  className="floatinganime"
                  mx={"auto"}
                  width={{ base: "130px", md: "130px", lg: "200px" }}
                  src={NFT}
                ></Image>
                <Text
                  mt={"20px"}
                  mx={"auto"}
                  fontSize={{ base: "13px", md: "15px", lg: "16px" }}
                  className="content_font"
                  color={"white"}
                >
                  NFT
                </Text>
                <Text
                  style={{
                    opacity: 3 === openIndex ? 1 : 0,
                    transition: "opacity 0.1s linear",
                  }}
                  mt={"20px"}
                  // maxW={"160px"}
                  fontSize={{ base: "10px", md: "11px", lg: "12px" }}
                  className="content_font"
                  color={"white"}
                >
                  From assigning ownership of digital assets to, possibilities
                  of NFTs in business are endless. Non-fungible tokens create
                  exclusive, unique experience for customers and elevate your
                  brand to new heights.
                </Text>
                <HashLink smooth to={"/services/nft-platform#nftsection"}>
                  <Flex
                    ml={"auto"}
                    mt={"20px"}
                    borderRadius={"6px"}
                    padding={"2px"}
                    width={"fit-content"}
                    height={"fit-content"}
                    border="1px solid #01B0D7"
                  >
                    <Button
                      width={{ base: "68px", md: "80px", lg: "90px" }}
                      height={{ base: "16px", md: "20px", lg: "28px" }}
                      className="heading"
                      backgroundColor={"#01B0D7"}
                      _hover={{ bg: "#8DE6F9" }}
                      fontSize={{
                        base: "12px",
                        md: "13px",
                        lg: "14px",
                      }}
                      type="submit"
                    >
                      Read more
                    </Button>
                  </Flex>
                </HashLink>
              </Flex>
            </Box> */}

            {/* Software Solns */}
            <Box
              _hover={{
                backgroundColor: "rgba(0, 0, 0, 0.3)",
              }}
              cursor={"pointer"}
              overflow={"hidden"}
              backgroundColor={"rgba(0, 0, 0, 0.15)"}
              borderRadius={"5px"}
              backdropFilter="auto"
              backdropBlur="2px"
              boxShadow={"0px 0px 32.5px -12px rgba(1, 177, 216, 0.45) inset"}
              onClick={() => handleBoxClick(4)}
              style={{
                height: 4 === openIndex ? boxHeightopen : boxHeightclose,
                transition: "height 0.4s linear",
              }}
              // width={{ base: "135px", md: "170px", lg: "230px" }}
            >
              <Flex
                flexDir={"column"}
                p={{ base: "25px", md: "40px", lg: "40px" }}
              >
                <Image
                  alt="TrackGeneis Software Solutions"
                  className="floatinganime"
                  mx={"auto"}
                  width={{ base: "130px", md: "130px", lg: "200px" }}
                  src={Soft}
                ></Image>
                <Text
                  textAlign={"center"}
                  mt={"20px"}
                  mx={"auto"}
                  fontSize={{ base: "13px", md: "15px", lg: "17px" }}
                  className="content_font"
                  color={"white"}
                >
                 Custom Software Development
                </Text>
                <Text
                  style={{
                    opacity: 4 === openIndex ? 1 : 0,
                    transition: "opacity 0.1s linear",
                  }}
                  mt={"20px"}
                  // maxW={"160px"}
                  fontSize={{ base: "11px", md: "11px", lg: "15px" }}
                  className="content_font"
                  color={"white"}
                >
                  Accelerate your business using bespoke software applications.
                  We design, develop and deliver web and mobile applications
                  tailored for your company’s growing needs for greater
                  efficiency in minimal time.
                </Text>
                <HashLink
                  smooth
                  to={"/services/software-development#softsection"}
                >
                  <Flex
                    ml={"auto"}
                    mt={"20px"}
                    borderRadius={"3px"}
                    padding={"2px"}
                    width={"fit-content"}
                    height={"fit-content"}
                    border="1px solid #01B0D7"
                  >
                    <Button
                    borderRadius={"2px"}
                      width={{ base: "68px", md: "80px", lg: "90px" }}
                      height={{ base: "16px", md: "20px", lg: "28px" }}
                      className="content_font"
                      backgroundColor={"#01B0D7"}
                      _hover={{ bg: "#8DE6F9" }}
                      fontSize={{
                        base: "12px",
                        md: "13px",
                        lg: "14px",
                      }}
                      type="submit"
                    >
                      Read more
                    </Button>
                  </Flex>
                </HashLink>
              </Flex>
            </Box>
          </SimpleGrid>

          <Image
         
            alt="our service robo of blockchain"
            zIndex={-1}
            mt={{ base: "320px", md: "170px", lg: "200px" }}
            ml={{ base: "20%", md: "54%", lg: "54%" }}
            width={{ base: "240px", md: "300px", lg: "400px" }}
            position={"absolute"}
            src={Servicerobo}
          ></Image>
        </Flex>
      </Flex>
    </Flex>
    //
  );
}

export default HomeServiceSection;
