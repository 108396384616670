import { Box, Button, Flex, Heading, Image, Text } from "@chakra-ui/react";
import React from "react";
import { HashLink } from "react-router-hash-link";

import CommonButton from "../../../components/CommonButton.js";
import Underline from "../../../assets/images/HomePage/underline.webp";
import Projectimage4 from "../../../assets/images/CasestudyPage/projectimage4.webp";
function ProjectFour() {
  return (
    <Flex my={{ base: "30px", md: "40px", lg: "100px" }} flexDir={"column"}>
      <Flex
        flexDir={"column"}
        mt={{ base: "10px", md: "20px%", lg: "20px" }}
        ml={{ base: "6%", md: "6%", lg: "6%" }}
      >
        <Text
         pr="30px"
          className="heading"
          color={"#94DAF8"}
          fontSize={{ base: "24px", md: "38px", lg: "45px" }}
        >
          Blockchain Implementation through Blockchain as a Service Platform
        </Text>
        <Image   alt="underline" mt={"-10px"} ml={"-10px"} width={"70px"} src={Underline}  ></Image>
      </Flex>

      {/* Content */}
      <Flex mt={{ base: "50px", md: "70px", lg: "150px" }} width={"100%"}>
        <Flex
          flexDir={{ base: "column", md: "row", lg: "row" }}
          width={{ base: "90%", md: "95%", lg: "70%" }}
          mx={"auto"}
          height={{ base: "fit-content", md: "fit-content", lg: "400px" }}
          backgroundColor={"#0B091E"}
          borderRadius={"5px"}
          boxShadow={"0px 0px 6.7px 0px #01B1D8"}
        >
          <Flex width={{ base: "100%", md: "45%", lg: "45%" }}>
            <Image
              ml={{ base: "auto", md: "5%", lg: "5%" }}
              mr={{ base: "auto", md: "0", lg: "0" }}
              mt={{ base: "-60px", md: "-70px", lg: "-100px" }}
              mb={{ base: "0px", md: "-20px", lg: "-20px" }}
              alt="success stories of TrackGeneis clients"
              objectFit={"contain"}
              width={{ base: "270px", md: "300px", lg: "470px" }}
              src={Projectimage4}
            ></Image>
          </Flex>
          <Flex width={{ base: "100%", md: "55%", lg: "55%" }}>
            <Flex
              px={{ base: "30px", md: "30px", lg: "50px" }}
              py={{base:"10px",md:"10px",lg:"0"}}
              //  mr={{base:"auto",md:"8%",lg:"13%"}}
              my={{ base: "30px", md: "auto", lg: "auto" }}
              mx={"auto"}
              flexDir={"column"}
            >
              <Text
                 fontSize={{ base: "14px", md: "14px", lg: "16px" }}
                color={"white"}
                className="content_font"
                fontWeight={"light"}
                // width={{ base: "270px", md: "270px", lg: "430px" }}
              >
                Brewtoon's brewery in Peterhead manufactures fast-selling beers.
                They asked us to address the lack of traceability in the beer
                supply chain. We onboarded Brewtoon to our blockchain as a
                service platform. The origin of components used at each stage of
                the brewing process was documented in blockchain, an immutable
                record. To access their blockchain network, we issued a unique
                API key.Anyone may trace the origin of beer by scanning a single
                QR code labelled on the can. Brewtoon released these limited
                edition bottles entitled 'Blockchain Reaction', which were in
                considerable demand. The product received much acclaim and was
                showcased at the ONE Tech Hub annual event in 2022.
              </Text>
              <HashLink smooth to={"/contact-us#contact"}>
                <CommonButton width="100px" btn="Book a Demo" mt="20px" />
              </HashLink>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default ProjectFour;
