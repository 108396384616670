import { Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import { HashLink } from "react-router-hash-link";
import BlockImageSmall from "../../../assets/images/Ar&VrPage/arimage2.webp";
import BlockImage from "../../../assets/images/Ar&VrPage/arimage2.webp";
import CommonButton from "../../../components/CommonButton.js";
import Underline from "../../../assets/images/HomePage/underline.webp";

function BlockchainTechnology() {
  return (
    // new code
    <Flex mt={{ base: "20px", md: "80px", lg: "200px" }} width={"100%"}>
      {/* title */}
      <Flex mx={"auto"} flexDir={{ base: "column", md: "row", lg: "row" }}>
        <Flex p={"20px"} width={{ base: "100%", md: "60%", lg: "50%" }}>
          <Image
            objectFit={"contain"}
            srcSet={`${BlockImageSmall} 1000w, ${BlockImage} 2000w`}
            width={"100%"}
            alt="AR in Blockchain Technology"
            pointerEvents={"none"}
          />
        </Flex>

        <Flex
          width={{ base: "100%", md: "60%", lg: "50%" }}
          flexDir={"column"}
          ml={{ base: "6%", md: "6%", lg: "10%" }}
        >
          <Text
            className="heading"
            color={"#94DAF8"}
            fontSize={{ base: "24px", md: "38px", lg: "45px" }}
            pr={"30px"}
            width={{ base: "330px", md: "330px", lg: "400px" }}
          >
            Marketing Your Product/Service
          </Text>
          <Image
            alt="underline"
            mt={"-10px"}
            ml={"-10px"}
            width={"70px"}
            src={Underline}  
          ></Image>

          <Text
            mt={{ base: "30px", md: "40px", lg: "80px" }}
            maxW={{ base: "300px", md: "380px", lg: "650px" }}
            fontSize={{ base: "12px", md: "12px", lg: "15px" }}
            color={"white"}
            className="content_font"
            fontWeight={"light"}
            pr={"20px"}
          >
            Businesses can attract and retain customers with AR, including
            AR-enhanced print materials, interactive product packaging, and
            location-based AR experiences. We develop engaging AR filters that
            improve your brand's visibility on social media.
          </Text>
          <HashLink smooth to={"/contact-us#contact"}>
            <CommonButton
              btn="Book a Demo"
              mt={{ base: "30px", md: "60px", lg: "30px" }}
            />
          </HashLink>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default BlockchainTechnology;
