import { Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import { HashLink } from "react-router-hash-link";
import PresentationImageSmall from "../../../assets/images/Ar&VrPage/arimage6.webp";

import CommonButton from "../../../components/CommonButton.js";
import Underline from "../../../assets/images/HomePage/underline.webp";

function Presentation() {
  return (
    // new code

    <Flex mt={{ base: "20px", md: "80px", lg: "200px" }} width={"100%"}>
      {/* title */}
      <Flex
        mx={"auto"}
        flexDir={{ base: "column-reverse", md: "row", lg: "row" }}
      >
        <Flex
          width={{ base: "100%", md: "60%", lg: "50%" }}
          flexDir={"column"}
          ml={{ base: "6%", md: "6%", lg: "10%" }}
        >
          <Text
            className="heading"
            color={"#94DAF8"}
            fontSize={{ base: "24px", md: "38px", lg: "45px" }}
            pr={"30px"}
            width={{ base: "330px", md: "330px", lg: "400px" }}
          >
            Applications & Websites Enhanced with AR
          </Text>
          <Image
            alt="underline"
            mt={"-10px"}
            ml={"-10px"}
            width={"70px"}
            src={Underline}  
          ></Image>

          <Text
            mt={{ base: "30px", md: "40px", lg: "80px" }}
            maxW={{ base: "300px", md: "380px", lg: "650px" }}
            fontSize={{ base: "14px", md: "14px", lg: "16px" }}
            color={"white"}
            className="content_font"
            fontWeight={"light"}
            pr={"20px"}
          >
            AR makes websites more interactive, which in turn increases traffic
            and generates more leads for your business. AR is integrated into
            applications to present data in a visually appealing and engaging
            manner. AR-enabled navigation apps and logistics systems provide
            real-time information to users pointing to the directional overlays
            on maps.
          </Text>
          <HashLink smooth to={"/contact-us#contact"}>
            <CommonButton
            width="100px"
              btn="Book a Demo"
              mt={{ base: "30px", md: "60px", lg: "30px" }}
            />
          </HashLink>
        </Flex>
        <Flex p={"20px"} width={{ base: "100%", md: "60%", lg: "50%" }}>
          <Image
            objectFit={"contain"}
            srcSet={`${PresentationImageSmall} 1000w, ${PresentationImageSmall} 2000w`}
            width={"100%"}
            alt="AR Enhanced Websites"
            pointerEvents={"none"}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Presentation;
