import { Flex, Image, Text} from "@chakra-ui/react";
import React from "react";
import Navbar from "../../components/sections/Navbar";
import Mobilenavbar from "../../components/sections/Mobilenavbar";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Footer from "../../components/sections/Footer";
import "./style.css";

import { Helmet } from "react-helmet";

import Caseheader from "../../assets/images/CasestudyPage/CaseHeader.webp";

import ProjectOne from "../../components/sections/CaseStudy/ProjectOne";
import ProjectTwo from "../../components/sections/CaseStudy/ProjectTwo";
import ProjectThree from "../../components/sections/CaseStudy/ProjectThree";
import ParticleBackground from "../../components/sections/home/ParticleBackground";
import ParticleTrail from "../../components/sections/home/ParticleTrail";
import ProjectFour from "../../components/sections/CaseStudy/ProjectFour";
import FireProject from "../../components/sections/CaseStudy/FireProject";

function CaseStudy(props) {
  return (
    <>
      {/* helmet */}
      <Helmet>
        <title>Case Study | TrackGenesis® | Scotland </title>
        <meta name="title" content="Case Study | TrackGenesis®️ | Scotland UK " />
        <meta
          name="description"
          content="Read the success stories of our clients. We developed wide range of products in blockchain, AR /VR, NFT and custom software that easily integrated with their system."
        />
        <link
          href="https://trackgenesis.com/case-study"
          rel="canonical"
          content="max-age: 1209600, no-cache, no-store, must-revalidate"
        />

        {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-197747980-1"
        ></script>
      </Helmet>
      {/* helmet */}

      <Flex
        overflow={"hidden"}
        backgroundColor={"black"}
        flexDirection={"column"}
        id="casestudy"
      >
        <Flex>
          <Image
            ml={{ base: "-50px", md: "-40px", lg: "0px" }}
            width={{ base: "270px", md: "400px", lg: "500px" }}
            src={Caseheader}
            alt="success stories of TrackGeneis clients"
          ></Image>
          <Text
            mt={"auto"}
            className="heading"
            mb={{ base: "15px", md: "15px", lg: "30px" }}
            color={"#94DAF8"}
            fontSize={{ base: "30px", md: "45px", lg: "50px" }}
            ml={{ base: "-29%", md: "-19%", lg: "-10%" }}
          >
            Client Stories
          </Text>
        </Flex>
        {/* header section */}

        <Navbar />
        <Mobilenavbar />
        {/* Heading */}

        {/* new code */}

        <ParticleTrail />
        <Flex
          flexDir={"column"}
          position={"relative"}
          className="mainborder"
          mx={{ base: "10px", md: "15px", lg: "30px" }}
          backgroundColor={"rgba(27, 21, 78, 0.38)"}
          backdropFilter="auto"
          backdropBlur="4px"
          pb={"100px"}
        >
          {/* casestudy intro */}
          <Flex width={"100%"} flexDir={"column"}>
            <Flex
              my={{ base: "50px", md: "50px", lg: "90px" }}
              mx={"auto"}
              flexDir={"column"}
            >
              <Text
                textAlign={"center"}
                className="heading"
                color={"#94DAF8"}
                fontSize={{ base: "24px", md: "38px", lg: "45px" }}
              >
                Project Portfolio
              </Text>
              <Text
                mt={"10px"}
                textAlign={"center"}
                fontSize={{ base: "14px", md: "14px", lg: "17px" }}
                color={"white"}
                className="content_font"
                fontWeight={"light"}
                width={{ base: "300px", md: "350px", lg: "550px" }}
              >
                Discover our innovation and experience the difference. Explore
                real-world success stories on our clients, and see how our
                solutions are transforming businesses and exceeding
                expectations. We're happy to have happy clients.
              </Text>
            </Flex>
          </Flex>

          {/* Projects one */}
          <ProjectOne />

          {/* Project two */}
          <ProjectTwo />

          {/* Projects three */}
          <ProjectThree />

           {/* Fire and Safety */}
           <FireProject/>

          {/* Projects four */}
          <ProjectFour />
        </Flex>
        <ParticleBackground />
        <Footer />
      </Flex>
    </>
  );
}

export default CaseStudy;
