import {  Flex, Image, Link, Text, Button } from "@chakra-ui/react";
import React from "react";
import { SimpleGrid } from "@chakra-ui/react";

import profiledata from "../../../database/profile";
import { AiFillLinkedin } from "react-icons/ai";
import TGRobo from "../../../../src/assets/images/HomePage/tgprofile.webp";
import Gif from "../../../../src/assets/images/HomePage/profilegif.gif";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import { HashLink } from "react-router-hash-link";

function Carousal(props) {
  return (
    <Flex
      mx={{ base: "10px", lg: "10px" }}
      my={"50px"}
      width={"100%"}
      justifyContent="center"
    >
      <SimpleGrid width={{base:"95%",md:"95%", lg: "90%" }} columns={[2, null, 3]} spacing={{base:"30px",md:"30px",lg:"30px"}}>
        {profiledata.map((data,index) => (
         

          <Flex
          key={index}
          height={{base:"",md:"",lg:"170px"}}
            backgroundColor={"rgba(0, 0, 0, 0.15)"}
            borderRadius={"5px"}
            backdropFilter="auto"
            backdropBlur="2px"
            boxShadow={"0px 0px 32.5px -12px rgba(1, 177, 216, 0.45) inset"}
            className="profile-card"
            width={"100%"}
            flexDir={{base:"column",md:"column",lg:"row"}}
          >
            <Flex
             flexDir={"column"}
      
              width={{base:"100%",md:"100%",lg:"50%"}}
           
             
              height={{ base: "100px", md: "130px", lg: "150px" }}
            >
           
              <Image
              alt="Our Team profile Trackgenesis"
               mt={"-20px"}
              objectFit={"contain"}
                className="profile-img"
              
                mr="auto"
                ml={"auto"}
                src={data.imagePath}
                pointerEvents={"none"}
              ></Image>
          
            </Flex>
            <Flex
              mt={"10px"}
              height={"100%"}
              // p={"10px"}
              width={{base:"100%",md:"100%",lg:"50%"}}
              flexDirection="column"
              justifyContent="center"
            >
              <Text
              mt={"15px"}
              mr="auto"
              ml={{base:"auto",md:"auto",lg:"0px"}}
              mb={"10px"}
                fontSize={{ base: "15px", md: "15px", lg: "17px" }}
                textAlign={"center"}
                fontWeight="normal"
                color={"white"}
                className="content_font"
              >
                {data.name}
              </Text>
              <Text
                ml={{base:"auto",md:"auto",lg:"0px"}}
                mr="auto"
                color={"white"}
                className="content_font"
                fontWeight={"light"}
                fontSize={{ base: "10px", md: "12px", lg: "12px" }}
              >
                {data.post}
              </Text>
              <Text
              ml={{base:"auto",md:"auto",lg:"0px"}}
                 mr="auto"
                 mt={{base:"1px",md:"1px",lg:"5px"}}
                fontSize={{ base: "10px", md: "12px", lg: "12px" }}
                color={"white"}
                className="content_font"
                fontWeight={"light"}
              >
                {data.certificate}
              </Text>
              <Flex
          mt={"auto"}
              ml={"auto"}
      mb={"15px"}
                fontSize={"20px"}
              
              >
                <Link href={data.linkedinlink} isExternal>
                  {" "}
                  <Flex
              
                    borderBottomLeftRadius={"3px"}
                    borderTopLeftRadius={"3px"}
                    height={{base:"30px",md:"30px",lg:"36px"}}
                    width={{base:"50px",md:"70px",lg:"100px"}}
                    border="1px solid #01B0D7"
                    py={1}
                    pl={1}
                  >
                    <Button
                     _hover={{ bg: "#8DE6F9"}}
                      borderBottomLeftRadius={"3px"}
                      borderTopLeftRadius={"3px"}
                      borderBottomRightRadius={"0px"}
                      borderTopRightRadius={"0px"}
                      height={{base:"20px",md:"20px",lg:"26"}}
                      width={"100px"}
                      backgroundColor={"#01B0D7"}
                    >
                      <AiFillLinkedin />
                    </Button>{" "}
                  </Flex>
                </Link>
              </Flex>
            </Flex>
          </Flex>
        ))}
        <Flex
          height={{base:"",md:"",lg:"170px"}}
            backgroundColor={"rgba(0, 0, 0, 0.15)"}
            borderRadius={"5px"}
            backdropFilter="auto"
            backdropBlur="2px"
            boxShadow={"0px 0px 32.5px -12px rgba(1, 177, 216, 0.45) inset"}
            className="profile-card"
            width={"100%"}
            flexDir={{base:"column",md:"column",lg:"row"}}
          >
            <Flex
             flexDir={"column"}
      
              width={{base:"100%",md:"100%",lg:"100%"}}
           
    
              height={{ base: "100px", md: "130px", lg: "100%" }}
            >
           
              <Image
            
               mt={"-20px"}
              objectFit={"contain"}
                className="profile-img"
                alt="TrackGenesis Robot profile"
                mr={{base:"auto",md:"auto",lg:"0px"}}
                ml={"auto"}
                src={TGRobo}
                pointerEvents={"none"}
              ></Image>
          
            </Flex>
            <Flex  width="100%"
            height="100%"
           >

            <HashLink   width={"fit-content"} height={"fit-content"} smooth to={"/careers#career"}>
            <Flex
            mb={{base:"0px",md:"30px",lg:"0px"}}
                 mr={{base:"auto",md:"auto",lg:"0px"}}
                 ml={{base:"13px",md:"32px",lg:"-30px"}} 
          
           mt={{base:"-10px",md:"-14px",lg:"30px"}}
            backgroundColor={"#0B091E"}
            borderRadius={"5px"}
            boxShadow={"0px 0px 6.7px 0px #01B1D8"}
           
              // mt={"10px"}
              height={"fit-content"}
              px={{base:"5px",md:"0px",lg:"15px"}}
              width={{base:"100%",md:"100%",lg:"fit-content"}}
              flexDirection="column"
              justifyContent="center"
            >
              <Text
              mr="auto"
              ml={{base:"auto",md:"auto",lg:"0px"}}
              my={"10px"}
                fontSize={{ base: "13px", md: "15px", lg: "16px" }}
                textAlign={"center"}
                fontWeight="normal"
                color={"white"}
                className="content_font"
                maxW={{base:"100px",md:"100px",lg:"200px"}}
              >
             You may be the next...
              </Text>
              <Image mx="auto" width={{base:"50px",md:"50px",lg:"80px"}} src={Gif}></Image>
            
             </Flex>
             </HashLink>
             </Flex> 
           
           
          </Flex>

      
      </SimpleGrid>

    </Flex>
  );
}

export default Carousal;
