import { Box, Button, Flex, Heading, Image, Text } from "@chakra-ui/react";
import React from "react";
import { HashLink } from "react-router-hash-link";
import UIImage from "../../../assets/images/SoftwarePage/software1.webp";
import UIImageSmall from "../../../assets/images/SoftwarePage/software1.webp";

import CommonButton from "../../../components/CommonButton.js";
import Underline from "../../../assets/images/HomePage/underline.webp";
function UiuxDesign() {
  return (
    // new code

    <Flex mt={{ base: "20px", md: "80px", lg: "200px" }} width={"100%"}>
      {/* title */}
      <Flex
        mx={"auto"}
        flexDir={{ base: "column-reverse", md: "row", lg: "row" }}
      >
        <Flex
          width={{ base: "100%", md: "60%", lg: "50%" }}
          flexDir={"column"}
          ml={{ base: "6%", md: "6%", lg: "10%" }}
        >
          <Text
            className="heading"
            color={"#94DAF8"}
            fontSize={{ base: "24px", md: "38px", lg: "45px" }}
            pr={"30px"}
            width={{ base: "330px", md: "330px", lg: "400px" }}
          >
            UI/UX Design
          </Text>
          <Image
            mt={"-10px"}
            ml={"-10px"}
            width={"70px"}
            src={Underline}  alt="underline"
          ></Image>

          <Text
            mt={{ base: "30px", md: "40px", lg: "80px" }}
            maxW={{ base: "300px", md: "380px", lg: "650px" }}
            fontSize={{ base: "14px", md: "14px", lg: "16px" }}
            color={"white"}
            className="content_font"
            fontWeight={"light"}
            pr={"20px"}
          >
            We design visually appealing, functional designs with a
            customer-centric approach. Our UI/UX design drives user engagement
            and earns more conversions for your business by delivering seamless
            digital interactions.
          </Text>
          <HashLink smooth to={"/contact-us#contact"}>
          <CommonButton
          width='100px'
            btn="Book a Demo"
            mt={{ base: "30px", md: "60px", lg: "30px" }}
          /></HashLink>
        </Flex>
        <Flex p={"20px"} width={{ base: "100%", md: "60%", lg: "50%" }}>
          <Image
          
            objectFit={"contain"}
            srcSet={`${UIImageSmall} 1000w, ${UIImage} 2000w`}
            width={"100%"}
            alt="UIUX Development TrackGenesis"
            pointerEvents={"none"}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default UiuxDesign;
