import {  Flex, Image, Text } from "@chakra-ui/react";
import React from "react";

import Carousel from "react-elastic-carousel";

import eventdatas from "../../../database/eventsdata";

function Events(props) {
  const carouselRef = React.useRef(null); // declare at state level
  let resetTimeout;
 

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 1, itemsToScroll: 2 },
    { width: 768, itemsToShow: 1 },
    { width: 1200, itemsToShow: 1 },
  ];

  return (
    <Flex
      width={{ base: "100%", md: "90%", lg: "80%" }}
      mx={"auto"}
      mt={{ base: "4%", md: "4%", lg: "5%" }}
    >
      <div className="carousel-wrapper">
        <Carousel
        pagination={false}
          enableMouseSwipe={true}
          enableAutoPlay={true}
          autoPlaySpeed={5000}
          breakPoints={breakPoints}
          onNextEnd={({ index }) => {
            // console.log("index",index,eventdatas.length)
            if(index===eventdatas.length-1){
              clearTimeout(resetTimeout);
              resetTimeout = setTimeout(() => {
                carouselRef?.current?.goTo(0);
              }, 5000); 
            } 
          }}
          ref={carouselRef}
        >
          {eventdatas.map((eventdata, index) => (
            <Flex
            key={index}
              px={{ base: "0px", md: "30px", lg: "60px" }}
            >
              <Flex
                width={"100%"}
                flexDir={{ base: "column", md: "row", lg: "row" }}
                
                
                p={"10px"}
                backgroundColor={"rgba(0, 0, 0, 0.15)"}
                borderRadius={"5px"}
                backdropFilter="auto"
                backdropBlur="2px"
                boxShadow={"0px 0px 22.5px -12px rgba(1, 177, 216, 0.45) inset"}
              >
                <Flex w={{ base: "100%", md: "50%", lg: "50%" }}>
                  <Image
                    ml={{ base: "auto", md: "-40px", lg: "-60px" }}
                    
                    mr={{ base: "auto", md: "0", lg: "0" }}
                    objectFit={"contain"}
                    width={{ base: "500px", md: "600px", lg: "750px" }}
                    srcSet={`${eventdata.imagesmallPath} 1000w, ${eventdata.imageslargePath} 2000w`}
                    alt={"TrackGenesis events"}
                    pointerEvents={"none"}
                  ></Image>
                </Flex>
                <Flex
                  w={{ base: "100%", md: "50%", lg: "50%" }}
                  p={"20px"}
                  my={"auto"}
                  flexDir={"column"}
                >
                  <Text
                    className="heading"
                    color={"#94DAF8"}
                    fontSize={{ base: "24px", md: "33px", lg: "35px" }}
                  >
                    {eventdata.heading}
                  </Text>
                  <Text
                    mt={"30px"}
                    fontSize={{ base: "14px", md: "14px", lg: "16px" }}
                    color={"white"}
                    className="content_font"
                    fontWeight={"light"}
                  >
                    {eventdata.description}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          ))}
        </Carousel>
      </div>
    </Flex>

  );
}

export default Events;
