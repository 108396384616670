import { Flex,  Image, Text } from "@chakra-ui/react";
import React from "react";
import { HashLink } from "react-router-hash-link";
import LearingImageSmall from "../../../assets/images/Ar&VrPage/arimage8.webp";
import LearningImage from "../../../assets/images/Ar&VrPage/arimage8.webp";

import CommonButton from "../../../components/CommonButton.js";
import Underline from "../../../assets/images/HomePage/underline.webp";

function VRLearning() {
  return (
    // new code
    <Flex mt={{ base: "20px", md: "80px", lg: "200px" }} width={"100%"}>
      {/* title */}
      <Flex mx={"auto"} flexDir={{ base: "column", md: "row", lg: "row" }}>
        <Flex p={"20px"} width={{ base: "100%", md: "60%", lg: "50%" }}>
          <Image
         
            objectFit={"contain"}
            srcSet={`${LearingImageSmall} 1000w, ${LearningImage} 2000w`}
            width={"100%"}
            alt="VR Virtual Reality Learning"
            pointerEvents={"none"}
          />
        </Flex>

        <Flex
          width={{ base: "100%", md: "60%", lg: "50%" }}
          flexDir={"column"}
          ml={{ base: "6%", md: "6%", lg: "10%" }}
        >
          <Text
            className="heading"
            color={"#94DAF8"}
            width={{base:"330px",md:"330px",lg:"400px"}}
            fontSize={{ base: "24px", md: "38px", lg: "45px" }}
          >
            Immersive Learning with VR
          </Text>
          <Image
            alt="underline"
            mt={"-10px"}
            ml={"-10px"}
            width={"70px"}
            src={Underline} 
          ></Image>

          <Text
            mt={{ base: "30px", md: "40px", lg: "80px" }}
            maxW={{ base: "300px", md: "380px", lg: "650px" }}
            fontSize={{ base: "14px", md: "14px", lg: "16px" }}
            color={"white"}
            className="content_font"
            fontWeight={"light"}
            pr={"20px"}
          >
            VR creates immersive learning environments. Virtual reality takes
            students through a virtual tour to explore historical sites, medical
            professionals to practice procedures in a risk-free environment, or
            even participants in virtual field trips.
          </Text>
          <HashLink smooth to={"/contact-us#contact"}>
          <CommonButton
          width="100px"
            btn="Book a Demo"
            mt={{ base: "30px", md: "60px", lg: "30px" }}
          /></HashLink>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default VRLearning;
