import {
    Flex,
  
    Text,
    Image,
    Box,
    SimpleGrid,
   
  } from "@chakra-ui/react";
  import React from "react";
  import "../section.css";

 import  newclientdata from "../../../database/newclientdata.js";
  import Underline from "../../../assets/images/HomePage/underline.webp";

  
  function HomeClientSection(props) {
    
    return (
     
      <Flex
     
        p={{ base: "20px" }}
        height={{ base: "fit-content", md: "fit-content", lg: "fit-content" }}
        width={"100%"}
      >
        <Flex flexDir={"column"} mb={{base:"50px",md:"150px",lg:"250px"}}  width={"100%"}>
          {/* Heading */}
          <Flex my={{base:"30px",md:"10px",lg:"10px"}} mx={"auto"}>
            <Image
              alt="underline"
              mt={{ base: "6px", lg: "15px" }}
              height={"40px"}
              width={"70px"}
              src={Underline} 
            ></Image>
            <Text
              className="heading"
              color={"#94DAF8"}
              fontSize={{ base: "24px", md: "38px", lg: "45px" }}
            >
              We Associate With
            </Text>
            <Image
              alt="underline"
              mt={{ base: "6px", lg: "15px" }}
              height={"40px"}
              width={"70px"}
              src={Underline} 
            ></Image>
          </Flex>
  
        
  
          {/* Content */}
          <Flex mx={"auto"} mt={"12%"}>
            <SimpleGrid  columns={[2, null, 4]} spacing="20px">
            {newclientdata.map((client,index) => (
              <Box
       key={index}
                overflow={"hidden"}
                backgroundColor={"#0B091E"}
                borderRadius={"5px"}
               
                boxShadow={"0px 0px 6.7px 0px #01B1D8"}
                
                width={{ base: "130px", md: "170px", lg: "230px" }}
              ><Flex width={"100%"} height={"100%"} p={"15px"}>
               
                  <Image
                  alt="trackGenesis Clients"
                    mx={"auto"}
                    my={"auto"}
                    width={{ base: "100px", md: "120px", lg: "150px" }}
                    src={client.imagePath}
                  ></Image>
                  </Flex>
                 
                
              </Box>
           ) )}
            </SimpleGrid>
            
          </Flex>
        </Flex>
      </Flex>
      //
    );
  }
  
  export default HomeClientSection;
  