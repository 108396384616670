import { Flex, Image, Text} from "@chakra-ui/react";
import React from "react";
import Navbar from "../../components/sections/Navbar";
import Mobilenavbar from "../../components/sections/Mobilenavbar";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Footer from "../../components/sections/Footer";
import "./style.css";
import Newsslides from "../../components/sections/mediaNewsRoom/Newsslides";
import Events from "../../components/sections/mediaNewsRoom/Events";
import { Helmet } from "react-helmet";

import Newsheader from "../../assets/images/Media&newsroomPage/newsheader.webp";

import Underline from "../../assets/images/HomePage/underline.webp";
import ParticleBackground from "../../components/sections/home/ParticleBackground";
import ParticleTrail from "../../components/sections/home/ParticleTrail";


function Newsroom(props) {
  return (
    <>
      {/* helmet */}
      <Helmet>
        <title>News & Press Release | TrackGenesis® | Scotland </title>
        <meta
          name="title"
          content="Media, News & Press Release | TrackGenesis®️ | Scotland UK "
        />
        <meta
          name="description"
          content="Get to know more about our milestones and latest happenings. Read our breaking stories and impactful press releases on our achievements and technology innovations."
        />
        <link
          href="https://trackgenesis.com/media-newsroom"
          rel="canonical"
          content="max-age: 1209600, no-cache, no-store, must-revalidate"
        />
     
              {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=UA-197747980-1"
    ></script>
      </Helmet>
      {/* helmet */}

      <Flex
        overflow={"hidden"}
        backgroundColor={"black"}
        flexDirection={"column"}
        id="media"
      >
        <Flex>
          <Image
              ml={{base:"-50px",md:"-40px",lg:"0px"}}
            width={{ base: "270px", md: "400px", lg: "500px" }}
            src={Newsheader}
            alt="Media, News & Press Release"
          ></Image>
          <Text
            mt={"auto"}
            className="heading"
            
            mb={{base:"15px",md:"15px",lg:"30px"}}
            color={"#94DAF8"}
            fontSize={{ base: "30px", md: "45px", lg: "50px" }}
            ml={{ base: "-29%", md: "-19%", lg: "-10%" }}
          >
            Media
          </Text>
        </Flex>
       
        <Navbar />
        <Mobilenavbar />
        {/* Heading */}
       
        {/* new code */}
  
      <ParticleTrail/>
        <Flex
          flexDir={"column"}
          position={"relative"}
          className="mainborder"
        
          mx={{ base: "10px", md: "15px", lg: "30px" }}
          backgroundColor={"rgba(27, 21, 78, 0.38)"}
          backdropFilter="auto"
          backdropBlur="4px"
          pb={"100px"}
        >
          {/* media intro */}
          <Flex width={"100%"} flexDir={"column"}>
            <Flex
              my={{ base: "50px", md: "50px", lg: "90px" }}
              mx={"auto"}
              flexDir={"column"}
            >
              <Text
                textAlign={"center"}
                className="heading"
                color={"#94DAF8"}
                fontSize={{ base: "24px", md: "38px", lg: "45px" }}
              >
                We Offer
              </Text>
              <Text
                mt={"10px"}
                textAlign={"center"}
                fontSize={{ base: "14px", md: "14px", lg: "17px" }}
                color={"white"}
                className="content_font"
                fontWeight={"light"}
                width={{ base: "300px", md: "350px", lg: "550px" }}
              >
                Organizing events in favor of our employees and the community is
                inevitable as we admire them. We are focused on giving back to
                the community. Our accomplishments are more than just rewards.{" "}
              </Text>
            </Flex>
          </Flex>

          {/* Latest Happenings */}
          {/* title */}
          <Flex
          id="latesthappenings"
            flexDir={"column"}
            mt={{ base: "10px", md: "20px%", lg: "20px" }}
            ml={{ base: "6%", md: "6%", lg: "6%" }}
          >
            <Text
              className="heading"
              color={"#94DAF8"}
              fontSize={{ base: "24px", md: "38px", lg: "45px" }}
            >
              Latest Happenings
            </Text>
            <Image
              mt={"-10px"}
              ml={"-10px"}
              width={"70px"}
              src={Underline}  alt="underline"
            ></Image>
          </Flex>

          {/* content */}
          <Events />

          {/* News */}
          <Flex id="pressRelease" flexDir={"column"} width={"100%"} mt={{base:"50px",md:"50px",lg:"100px"}}>
          {/* Heading */}
          <Flex my={{base:"30px",md:"10px",lg:"10px"}} mx={"auto"}>
          <Image
            mt={{ base: "6px", lg: "15px" }}
            height={"40px"}
            width={"70px"}
            src={Underline}  alt="underline"
          ></Image>
          <Text
            className="heading"
            color={"#94DAF8"}
            fontSize={{ base: "24px", md: "38px", lg: "45px" }}
          >
            Press Releases
          </Text>
          <Image
            mt={{ base: "6px", lg: "15px" }}
            height={"40px"}
            width={"70px"}
            src={Underline}  alt="underline"
          ></Image>
        </Flex>


          {/* content */}
          <Newsslides/>
          </Flex>
        </Flex>
        <ParticleBackground />
        <Footer />
      </Flex>
    </>
  );
}

export default Newsroom;
