import { Button ,Image,Text, Flex} from "@chakra-ui/react";
import React from "react";
import { FaYoutube } from "react-icons/fa";
function VideoButton(props) {
  const {
    isLoading,
    bg = "#fff",
    hoverBg = "#AA5E5E",
    color = "#9A0000",
    type,
    alignSelf,
    border,
    width = "95px",
    height = "32px",
    p,
    fontSize = "13px",
    btn,
    icon,
    focusBg,
    activeBg,
    borderRadius = "4px",
    boxShadow = " 0px 0px 9.3px 0px #AA5E5E",
    onClick,
    mt,
    ml,mb,
    ...rest
  } = props;
  return (
    <Flex  bg={bg} borderRadius={"4px"} ml={ml} mb={mb} mt={mt} padding={"2px"} width={"fit-content"} height={"fit-content"}  border="1px solid #9A0000">
   <Button
   className="content_font"
      p={p}
      height={height}
      isLoading={isLoading}
      bg={bg}
      _hover={{ bg: "#FFCCCC", textDecoration: "none"}}
      color={color}
      type={type}
      alignSelf={alignSelf}
     border="2px solid #9A0000"
      width={width}
      fontSize={fontSize}
      _focus={{ bg: focusBg }}
      _active={{ bg: activeBg }}
      borderRadius={borderRadius}
      boxShadow={boxShadow}
      onClick={onClick}
    
      {...rest}
    ><Text><FaYoutube size={"17px"} color={"#9A0000"}/> </Text>
 <Text as={"b"} fontSize={"12px"} ml={"5px"}  _hover={{ textDecoration: "none" }}   textDecoration={"none"}
   
 >   Watch Video</Text>  
    
    </Button></Flex>
  );
}

export default VideoButton;
