import { Flex, Text, Image } from "@chakra-ui/react";
import React from "react";
import Carousal from "./Carousal";
import Underline from "../../../assets/images/HomePage/underline.webp";

function OurTeam() {
  return (
  
    // new our team
    <Flex flexDir={"column"}>
      {/* heading */}
      <Flex my={{ base: "30px", md: "10px", lg: "10px" }} mx={"auto"}>
        <Image
        alt="underline"
          mt={{ base: "6px", lg: "15px" }}
          height={"40px"}
          width={"70px"}
          src={Underline} 
        ></Image>
        <Text
          className="heading"
          color={"#94DAF8"}
          fontSize={{ base: "24px", md: "38px", lg: "45px" }}
        >
          Meet Our Team
        </Text>
        <Image
          alt="underline"
          mt={{ base: "6px", lg: "15px" }}
          height={"40px"}
          width={"70px"}
          src={Underline} 
        ></Image>
      </Flex>
      {/* content */}

      {/* Our Team members profile */}
      <Flex flexDirection={"column"} justifyContent={"center"} width={"100%"}>
        <Flex p={1}>
          <Carousal />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default OurTeam;
