import { Box, Button, Flex, Heading, Image, Text } from "@chakra-ui/react";
import React from "react";
import { HashLink } from "react-router-hash-link";
import GamingImage from "../../../assets/images/NFTPage/nftimage5.webp";
import GamingImageSmall from "../../../assets/images/NFTPage/nftimage5.webp";


import CommonButton from "../../../components/CommonButton.js";
import Underline from "../../../assets/images/HomePage/underline.webp";
function NFTGaming() {
  return (
   

    <Flex mt={{ base: "20px", md: "80px", lg: "200px" }} width={"100%"}>
      {/* title */}
      <Flex mx={"auto"} flexDir={{ base: "column", md: "row", lg: "row" }}>
        <Flex p={"20px"} width={{ base: "100%", md: "60%", lg: "50%" }}>
          <Image
          
            objectFit={"contain"}
            srcSet={`${GamingImageSmall} 1000w, ${GamingImage} 2000w`}
            width={"100%"}
            alt="NFT TrackGenesis Gaming"
            pointerEvents={"none"}
          />
        </Flex>

        <Flex
          width={{ base: "100%", md: "60%", lg: "50%" }}
          flexDir={"column"}
          ml={{ base: "6%", md: "6%", lg: "10%" }}
        >
          <Text
            className="heading"
            color={"#94DAF8"}
            fontSize={{ base: "24px", md: "38px", lg: "45px" }}
            width={{ base: "330px", md: "330px", lg: "400px" }}
          >
            NFT in Gaming applications
          </Text>
          <Image
            mt={"-10px"}
            ml={"-10px"}
            width={"70px"}
            src={Underline}  alt="underline"
          ></Image>

          <Text
            mt={{ base: "30px", md: "40px", lg: "80px" }}
            maxW={{ base: "300px", md: "380px", lg: "650px" }}
            fontSize={{ base: "14px", md: "14px", lg: "16px" }}
            color={"white"}
            className="content_font"
            fontWeight={"light"}
            pr={"20px"}
          >
            NFT is a game changer in the gaming industry. People can purchase
            and claim exclusive traits for the game's characters. The
            characteristics can then be sold to generate revenue. When
            characters are stylized with a personal touch, people become more
            invested in games, and more people may be enticed to the game.
          </Text>
          <HashLink smooth to={"/contact-us#contact"}>
          <CommonButton
          width="100px"
            btn="Book a Demo"
            mt={{ base: "30px", md: "60px", lg: "30px" }}
          /></HashLink>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default NFTGaming;
