import { Box, Button, Flex, Heading, Image, Text } from "@chakra-ui/react";
import React from "react";
import { HashLink } from "react-router-hash-link";
import BlockImageSmall from "../../../assets/images/Ar&VrPage/arimage2.webp";
import BlockImage from "../../../assets/images/Ar&VrPage/arimage2.webp";
import CommonButton from "../../../components/CommonButton.js";
import Underline from "../../../assets/images/HomePage/underline.webp";
import Projectimage1 from "../../../assets/images/CasestudyPage/projectimage3.webp";
function ProjectThree() {
  return (
    <Flex my={{ base: "30px", md: "40px", lg: "100px" }} flexDir={"column"}>
      <Flex
        flexDir={"column"}
        mt={{ base: "10px", md: "20px%", lg: "20px" }}
        ml={{ base: "6%", md: "6%", lg: "6%" }}
      >
        <Text
          className="heading"
          color={"#94DAF8"}
          pr="30px"
          fontSize={{ base: "24px", md: "38px", lg: "45px" }}
        >
          Integrating Blockchain and IoT for Food & Drink Traceability
        </Text>
        <Image   alt="underline" mt={"-10px"} ml={"-10px"} width={"70px"} src={Underline}  ></Image>
      </Flex>

      {/* Content */}
      <Flex mt={{ base: "50px", md: "70px", lg: "150px" }} width={"100%"}>
        <Flex
          flexDir={{ base: "column", md: "row", lg: "row" }}
          width={{ base: "90%", md: "95%", lg: "70%" }}
          mx={"auto"}
          height={{ base: "fit-content", md: "fit-content", lg: "400px" }}
          backgroundColor={"#0B091E"}
          borderRadius={"5px"}
          boxShadow={"0px 0px 6.7px 0px #01B1D8"}
        >
          <Flex width={{ base: "100%", md: "45%", lg: "45%" }}>
            <Image
              ml={{ base: "auto", md: "5%", lg: "5%" }}
              mr={{ base: "auto", md: "0", lg: "0" }}
              mt={{ base: "-30px", md: "-50px", lg: "-100px" }}
              mb={{ base: "0px", md: "-20px", lg: "-20px" }}
              alt="success stories of TrackGeneis clients"
              objectFit={"contain"}
              width={{ base: "270px", md: "300px", lg: "470px" }}
              src={Projectimage1}
            ></Image>
          </Flex>
          <Flex width={{ base: "100%", md: "55%", lg: "55%" }}>
            <Flex
              px={{ base: "30px", md: "30px", lg: "50px" }}
              py={{base:"10px",md:"10px",lg:"0"}}
              //  mr={{base:"auto",md:"8%",lg:"13%"}}
              my={{ base: "30px", md: "auto", lg: "auto" }}
              mx={"auto"}
              flexDir={"column"}
            >
              <Text
                fontSize={{ base: "14px", md: "14px", lg: "16px" }}
                color={"white"}
                className="content_font"
                fontWeight={"light"}
                // width={{ base: "270px", md: "270px", lg: "430px" }}
              >
                Roehill Springs is an award-winning gin produced on a
                third-generation family farm near Keith in Scotland. They
                envisioned to implement transparency in their business by
                sharing the tale of gin with customers. Consumers should never
                suspect the authenticity of the goods. They needed to prove to
                their customers that the key ingredient in Gin is spring water.
                We offered a solution that brings together blockchain technology
                and IoT devices. The amount of water consumed at various stages
                of the manufacturing process is measured using CENSIS-developed
                IoT devices and a flowmeter connected at each stage. Bluetooth
                technology is used to fetch data, which is then stored in the
                blockchain. Our customer demonstrated the idea at the Scotland
                Future Food and Drink Summit 2023 and received financing from
                CENSIS.
              </Text>
              <HashLink smooth to={"/contact-us#contact"}>
                <CommonButton width='100px' btn="Book a Demo" mt="20px" />
              </HashLink>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default ProjectThree;
