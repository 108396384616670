import {  Flex, Heading, Image, Text } from "@chakra-ui/react";
import React from "react";
import Mobilenavbar from "../../../components/sections/Mobilenavbar";
import Navbar from "../../../components/sections/Navbar";
import Footer from "../../../components/sections/Footer";

import sherinblog from "../../../assets/images/BlogsPage/sherinprofile.webp";
import Edublog from "../../../assets/images/BlogsPage/edublog.webp";
import blogimage from "../../../assets/images/BlogsPage/cert.webp";
import { Helmet } from "react-helmet";
import { HashLink } from "react-router-hash-link";
function BlogCertificate(props) {
  return (
    <>
      {/* helmet */}
      <Helmet>
        <title>
          Blogs | Blockchain | TrackGenesis&reg; | Aberdeen Scotland
        </title>
        <meta
          name="title"
          content="Blogs | Blockchain | TrackGenesis | Aberdeen Scotland"
        />
        <meta
          name="description"
          content="Blockchain Technology Help Education and Training Institutions"
        />
        <link
          href="https://trackgenesis.com/blogs"
          rel="canonical"
          content="max-age: 1209600, no-cache, no-store, must-revalidate"
        />
      </Helmet>
      {/* helmet */}
      <Flex
        backgroundColor={"black"}
        flexDirection={"column"}
        id="Education-Trackgenesis"
      >
        {/* heading Section */}
        <Flex
          backgroundColor={"black"}
          width="100%"
          flexDirection={"column"}
          alignItems="center"
          justifyContent={"center"}
          height={"400px"}
        >
          <Heading
            className="heading"
            color={"#94DAF8"}
            fontSize={{ md: "33px", base: "25px", lg: "37px" }}
            fontWeight="bold"
            textShadow="1px 1px #000000"
            mt={{ base: "36px", md: "35px", lg: "10px" }}
            textAlign="center"
            maxWidth={{ base: "80%", md: "65%", lg: "60%" }}
          >
            How Blockchain Technology Help Education and Training Institutions
            to Fight Fake Certificates?
          </Heading>
        </Flex>

        <Navbar />
        <Mobilenavbar />

        <Flex
          backgroundColor={"black"}
          alignItems={"center"}
          justifyContent="center"
          width="100%"
        >
          <Image
            mt={{ base: "-90px", md: "-90px", lg: "-110px" }}
            ml={{ base: "-5%", lg: "-5%" }}
            width={{ base: "91%", md: "80%", lg: "70%" }}
            src={Edublog}
          />
        </Flex>

        <Flex
          flexDir={"column"}
          position={"relative"}
          className="mainborder"
          my={{ base: "2%", md: "2%", lg: "2%" }}
          mx={{ base: "10px", md: "15px", lg: "30px" }}
          backgroundColor={"rgba(27, 21, 78, 0.38)"}
          backdropFilter="auto"
          backdropBlur="8px"
          pb={"100px"}
        >
          <Flex flexDir={"column"}>
            <Flex
              color={"white"}
              className="content_font"
              width={"100%"}
              px={{ base: "10%", md: "15%", lg: "20%" }}
              fontSize={{ base: "15px", md: "16px", lg: "17px" }}
              flexDirection="column"
            >
              <Text mt={"30px"} className="content_font">
                AI is the latest breakthrough in the technology world. This
                transformative revolution presents both opportunities and
                challenges. Advancements in technology exposes vulnerabilities
                in digital security, allowing fraudsters to exploit them more
                effectively.
              </Text>

              <Text mt={"30px"} className="content_font">
                Concerns of counterfeits and frauds are rising in the digital
                age. This includes challenges in verifying the legitimacy of
                digital certificates for education and employment.
              </Text>

              <Text mt={"30px"} className="content_font">
                Standing out in today's job market demands verifiable
                certificates. However, the rise of sophisticated forgeries has
                created a challenge: <Text as={"b"}> certificate fraud.</Text>
              </Text>
              <Text
                mt={"30px"}
                className="content_font"
                fontWeight={"bold"}
                // color="rgba(42, 50, 73, 1)"
                fontSize={"20px"}
              >
                What is Certificate Fraud?
              </Text>
              <Text mt={"30px"} className="content_font">
                Certificate fraud involves the creation or manipulation of
                academic or training certificates to cheat employers or gain
                unlawful access to professions. This can be done through various
                means, including:
              </Text>

              <Text
                mt={"30px"}
                className="content_font"
                fontWeight={"bold"}
                // color="rgba(42, 50, 73, 1)"
                fontSize={"16px"}
              >
                Purchasing pre-made fake certificates online
              </Text>

              <Text
                mt={"30px"}
                className="content_font"
                fontWeight={"bold"}
                // color="rgba(42, 50, 73, 1)"
                fontSize={"16px"}
              >
                Altering details on existing certificates
              </Text>
              <Text
                mt={"30px"}
                className="content_font"
                fontWeight={"bold"}
                // color="rgba(42, 50, 73, 1)"
                fontSize={"16px"}
              >
                Using stolen or lost certificates
              </Text>

              <Text
                mt={"30px"}
                className="content_font"
                fontWeight={"bold"}
                // color="rgba(42, 50, 73, 1)"
                fontSize={"20px"}
              >
                Risks and Costs of Certificate Fraud
              </Text>

              <Text mt={"30px"} className="content_font">
                Certificate fraud poses a serious threat to both organizations
                and individuals:
              </Text>
              <Text mt={"30px"} className="content_font">
                <Text as={"b"}> For Organizations:</Text> Hiring someone with
                falsified credentials can lead to safety hazards (especially in
                healthcare and manufacturing), reputational damage, and legal
                consequences. In healthcare, fake medical licenses can endanger
                patient safety. Similarly, in manufacturing, unqualified workers
                can compromise product quality and safety standards.
              </Text>
              <Text mt={"30px"} className="content_font">
                <Text as={"b"}> For Individuals:</Text> Fake certificates can
                lead to job loss, wasted time and money on fraudulent training,
                and difficulty securing future employment.
              </Text>

              <Text
                mt={"30px"}
                className="content_font"
                fontWeight={"bold"}
                // color="rgba(42, 50, 73, 1)"
                fontSize={"20px"}
              >
                Enter Blockchain to Prevent Certificate Fraud
              </Text>
              <Text mt={"30px"} className="content_font">
                Blockchain technology offers an innovative solution to tackle
                certificate fraud. Here's how:
              </Text>
              <Text mt={"30px"} className="content_font">
                <Text as="b">Immutable Ledger:</Text> Blockchain creates a
                tamper-proof record of issued certificates, making alterations
                impossible.
              </Text>

              <Text mt={"30px"} className="content_font">
                <Text as="b">Secure Verification: </Text> Employers and
                institutions can instantly verify the authenticity of
                certificates through a secure online portal like  
                <HashLink  smooth to={"/products#tgcerticheck"}>
               
                <Text as="span" ml={"5px"} color={"blue.200"} textDecor={"underline"}>TG-Certicheck.</Text>
              </HashLink>
              </Text>
              <Text mt={"30px"} className="content_font">
                <Text as="b">Enhanced Transparency: </Text> The entire issuance
                and verification process becomes transparent and quick,
                fostering trust within the education and employment landscape.
              </Text>

              <Text
                mt={"30px"}
                className="content_font"
                fontWeight={"bold"}
                // color="rgba(42, 50, 73, 1)"
                fontSize={"20px"}
              >
                TG-Certicheck: The Solution for Secure Credentials
              </Text>

              <Flex justifyContent={"center"}>
                <Image
                  mt="30px"
                  borderRadius={"5px"}
                  width={{ base: "70%", md: "60%", lg: "60%" }}
                  src={blogimage}
                ></Image>
              </Flex>
              <Text mt={"30px"} className="content_font">
                TG-Certicheck is a blockchain powered application to create and
                verify certificates with ease. Adoption of blockchain technology
                in certificate generation generate tamper-proof and verifiable
                digital certificates.
              </Text>
              <Text mt={"30px"} className="content_font">
                By implementing blockchain-based credential systems in
                educational organizations and training institutions can take a
                strong stance against certificate fraud. TG-Certicheck not only
                protects their reputation but also ensures the safety and
                well-being of individuals across various sectors.
              </Text>
            </Flex>
            <Flex my={"20px"} width={"100%"}>
              <Flex ml={{ base: "40%", md: "65%", lg: "65%" }}>
                <Text
                  color={"white"}
                  className="content_font"
                  fontSize={{ md: "23px", base: "15px", lg: "25px" }}
                >
                  - by Sherin Thomas
                </Text>
                <Image
                  objectFit={"contain"}
                  ml={{ base: "10px", md: "10px", lg: "30px" }}
                  mt={"-10px"}
                  width={{ base: "30px", md: "40px", lg: "55px" }}
                  src={sherinblog}
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Footer />
      </Flex>
    </>
  );
}

export default BlogCertificate;
