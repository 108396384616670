import React from 'react';
import Particles from 'react-tsparticles';
import { loadFull } from "tsparticles";
import { useCallback } from 'react';

function ParticleBackground() {
    const particlesInit = useCallback(async (engine) => {
        await loadFull(engine);
      }, []);
      const particlesLoaded = useCallback(async (container) => {
       await console.log();
    }, []);
    
  return (
    <div >
    <Particles
          id="tsparticles"
          init={particlesInit}
          loaded={particlesLoaded}
          options={{
           
            fpsLimit: 120,
           
              interactivity: {
                detect_on: "window",
                events: {
                  onhover: {
                    enable: true,
                    mode: "repulse"
                  },
                  onclick: {
                    enable: true,
                    mode: "repulse"
                  },
                  resize: true
                },
                modes: {
                   
                  
                      repulse: {
                        distance: 100,
                        duration: 0.4
                      },
                      push: {
                        particles_nb: 4
                      },
                      remove: {
                        particles_nb: 2
                      }
                },
            },
              particles: {
                number: {
                    value: 5,
                    density: {
                      enable: true,
                      value_area: 959.616153538585
                    }
                  },
                  color: {
                    value: "#7F59B9"
                  },
                  shape: {
                    type: "circle",
                    stroke: {
                      width: 0,
                      color: "#000000"
                    },
                    polygon: {
                      nb_sides: 6
                    },
                    collisions: {
                        enable: true,
                    },
                  
                  },
                  opacity: {
                    value: 0.5,
                    random: true,
                    anim: {
                      enable: true,
                      speed: 1,
                      opacity_min: 0,
                      sync: false
                    }
                  },
                  size: {
                    value: 2,
                    random: true,
                    anim: {
                      enable: false,
                      speed: 4,
                      size_min: 0.2,
                      sync: false
                    }
                  },
                //   line_linked: {
                //     enable: false,
                //     distance: 150,
                //     color: "#ffffff",
                //     opacity: 0.4,
                //     width: 1
                //   },
                  move: {
                    enable: true,
                    speed: 1,
                    direction: "none",
                    random: true,
                    straight: false,
                    out_mode: "out",
                    bounce: false,
                    attract: {
                      enable: false,
                      rotateX: 600,
                      rotateY: 600
                    }
                  }
              },
              detectRetina: true,
          }}
      />
    
  </div>
  );
}

export default ParticleBackground;
