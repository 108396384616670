/** @format */

import {  Image, Flex, Text} from "@chakra-ui/react";
import React from "react";
import "./style.css";
import Navbar from "../../components/sections/Navbar";
import Footer from "../../components/sections/Footer";
import Mobilenavbar from "../../components/sections/Mobilenavbar";
import DigitalAsset from "../../components/sections/NFT/DigitalAsset";



import { Helmet } from "react-helmet";

import Loyalty from "../../components/sections/NFT/Loyalty";
import NFTMarketplace from "../../components/sections/NFT/NFTMarketplace";
import NFTGaming from "../../components/sections/NFT/NFTGaming";
import DigitalCollectibles from "../../components/sections/NFT/DigitalCollectibles";
import NFTheader from "../../assets/images/NFTPage/nftheader.webp";
import Underline from "../../assets/images/HomePage/underline.webp";
import Nftnew from "../../../src/assets/images/NFTPage/nftnew.gif";
import BlkRing from "../../assets/images/IndustrysolutionsPage/ring.webp";
import ParticleBackground from "../../components/sections/home/ParticleBackground";
import ParticleTrail from "../../components/sections/home/ParticleTrail";
function Nft(props) {
  return (
    <>
      {/* helmet */}
      <Helmet>
        <title>
          Best NFT App developers | No Code NFT Platform | TrackGenesis® | Scotland UK 
        </title>
        <meta
          name="title"
          content="Best NFT App Development Company | TrackGenesis®️ | Scotland UK "
        />
        <meta
          name="description"
          content="We design NFTs (non-fungible tokens) for assigning ownership of digital assets, loyalty programs, and digital collectibles tailored for your business needs."
        />
        <link
          href="https://trackgenesis.com/services/nft"
          rel="canonical"
          content="max-age: 1209600, no-cache, no-store, must-revalidate"
        />
       
              {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=UA-197747980-1"
    ></script>
      </Helmet>
      {/* helmet */}

      <Flex
        id="nftsection"
        flexDirection={"column"}
        backgroundColor={"black"}
        overflowX={"hidden"}
      >
     
        
       
        {/* new heading Section */}
        <Flex>
          <Image
           ml={{base:"-50px",md:"-40px",lg:"0px"}}
            width={{ base: "270px", md: "400px", lg: "500px" }}
            src={NFTheader}
            alt="NFT TrackGenesis"
          ></Image>
          <Text
            mt={"auto"}
            className="heading"
         
            mb={{base:"15px",md:"15px",lg:"30px"}}
            color={"#94DAF8"}
            fontSize={{ base: "30px", md: "45px", lg: "50px" }}
            ml={{ base: "-19%", md: "-19%", lg: "-10%" }}
          >
            NFT
          </Text>
        </Flex>

        <Navbar />
        <Mobilenavbar />

      

        {/* new content */}
     
      <ParticleTrail/>
        <Flex
          flexDir={"column"}
          position={"relative"}
          className="mainborder"
         
          mx={{ base: "10px", md: "15px", lg: "30px" }}
          backgroundColor={"rgba(27, 21, 78, 0.38)"}
          backdropFilter="auto"
          backdropBlur="4px"
          pb={"100px"}
        >
          {/* nft ontro */}
          <Flex
            my={{ base: "30px", md: "30px", lg: "90px" }}
            // p={"60px"}
            mx={"auto"}
            width={{ base: "80%", md: "80%", lg: "900px" }}
            flexDir={{
              base: "column-reverse",
              md: "column-reverse",
              lg: "row",
            }}
          >
            <Flex
              flexDir={"column"}
              mt={{ base: "-60px", md: "-50px", lg: "0px" }}
              justifyContent={"center"}
              alignItems={"center"}
              backgroundColor={"rgba(0, 0, 0, 0.15)"}
              borderRadius={"5px"}
              backdropFilter="auto"
              backdropBlur="2px"
              boxShadow={"0px 0px 32.5px -12px rgba(1, 177, 216, 0.45) inset"}
            >
              <Flex
                flexDir={"column"}
                mr={"auto"}
                ml={{ base: "20px", md: "20px", lg: "45px" }}
                mt={{ base: "100px", md: "100px", lg: "30px" }}
              >
                <Text
                  className="heading"
                  color={"#94DAF8"}
                  fontSize={{ base: "22px", md: "22px", lg: "25px" }}
                >
                  Why NFTs for your business?
                </Text>
                <Image
                  mt={"-10px"}
                  ml={"-10px"}
                  width={"70px"}
                  src={Underline}  alt="underline"
                ></Image>
              </Flex>
              <Text
                pr={{ base: "20px", md: "30px", lg: "150px" }}
                pl={{ base: "20px", md: "30px", lg: "50px" }}
                py={{ base: "30px", md: "30px", lg: "30px" }}
                fontSize={{ base: "14px", md: "14px", lg: "17px" }}
                color={"white"}
                className="content_font"
                fontWeight={"light"}
                maxW={{ base: "600px", md: "600px", lg: "800px" }}
              >
                Non-Fungible Token (NFT) is a blockchain-based digital asset
                primarily used to track ownership of physical or digital goods.
                The distinctive advantage of NFTs lies in establishing a unique
                identity for a product, and its ownership is transferable. Most
                global brands are embracing NFT because they believe it has the
                potential to differentiate their businesses in the market.
                <br />
                <br />
                TrackGenesis develops user-friendly NFT platforms. Our NFT
                platform enables clients to create NFTs for their goods without
                having to navigate the complexities of wallets, blockchain, and
                smart contracts.
              </Text>
            </Flex>
           

            <Image
              mr={{ base: "auto", md: "auto", lg: "0px" }}
              alt="NFT TrackGenesis"
              
              ml={{ base: "auto", md: "auto", lg: "-150px" }}
              zIndex={2}
              width={{ base: "180px", md: "180px", lg: "280px" }}
              objectFit={"contain"}
              src={Nftnew}
            ></Image>
            <Image 
            alt="NFT TrackGenesis"
            width={{ base: "210px", md: "210px", lg: "330px" }} 
            objectFit={"contain"} 
            zIndex={1}
            mr={{ base: "auto", md: "auto", lg: "0px" }}
            mb={{base:"-180px",md:"-180px",lg:"0px"}}
            ml={{base:"auto",md:"auto",lg:"-300px"}}
            src={BlkRing}></Image>
          </Flex>

          {/* Digital Asset */}
          <DigitalAsset />

          {/* Loyalty */}
          <Loyalty />

          {/* Collectibles */}
          <DigitalCollectibles />

            {/* Gaming */}
          <NFTGaming />

            {/* marketplace */}
        <NFTMarketplace />
        </Flex>
      
        <ParticleBackground />
        <Footer />
      </Flex>
    </>
  );
}

export default Nft;
