import {
  Flex,
  Stack,
  Text,
  HStack,

} from "@chakra-ui/react";
import React, { useState } from "react";
import { MenuItem, Menu, MenuButton } from "@chakra-ui/react";
import { NavLink, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { Popover, PopoverTrigger, PopoverContent } from "@chakra-ui/react";
import "./section.css";

function Navbar(props) {
  const navigate = useNavigate();
  const currentRoute = window.location.pathname.toLowerCase();
  const [openIndex, setOpenIndex] = useState(null);

  const handleBoxClick = (index) => {
    setOpenIndex(index === openIndex ? null : index);
  };
  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
   

    // new navbar
    <Flex
      width={"100%"}
      mt={"35px"}
      justifyContent={"center"}
      alignItems={"center"}
      className=" animate__animated animate__slideInDown animate__delay"
      zIndex={4}
      position={"absolute"}
      color={"white"}
      display={{ base: "none", md: "none", lg: "block" }}
    >
      <Flex width={"fit-content"} mr={"10px"} ml={"auto"}>
        <HStack
          spacing="2px"
          className="content-font"
          fontSize={{ base: "12px", md: "14px", lg: "12px" }}
        >
          {/* Home */}
          <NavLink
            className={currentRoute.includes("home") ? "NavActive" : ""}
            onClick={() => handleNavigation("/home")}
            //  className={currentRoute.includes("home") ? "NavActive" : ""}
           
            to="/home"
            _hover={{
              textDecoration: "none",
            }}
          >
            <Text px={4}>Home</Text>
          </NavLink>
          {/* About Us */}

          <Menu>
            <Popover trigger={"hover"} placement={"bottom-start"}>
              <PopoverTrigger>
                <MenuButton
                  className={
                    currentRoute.includes("about-us") ? "NavActive" : ""
                  }
                  onClick={() => handleNavigation("/about-us")}
                >
                  <Text px={4}>About Us</Text>
                </MenuButton>
              </PopoverTrigger>
              <PopoverContent className="popup" maxW="200px" border={"none"}>
                <Stack>
                  <NavLink
                    _hover={{
                      textDecoration: "none",
                    }}
                    to="/about-us"
                  >
                    <MenuItem p={2} _hover={{ bg: "rgb(1,177,216,0.3)" }}>
                      Our Mission & Vision
                    </MenuItem>
                  </NavLink>
                  <HashLink
                    smooth
                    _hover={{
                      textDecoration: "none",
                    }}
                    to={"/about-us#whatWeDo"}
                  >
                    <MenuItem p={2} _hover={{ bg: "rgb(1,177,216,0.3)" }}>
                      What We Do
                    </MenuItem>
                  </HashLink>
                  <HashLink
                    smooth
                    _hover={{
                      textDecoration: "none",
                    }}
                    to="/about-us#ourTeam"
                  >
                    <MenuItem p={2} _hover={{ bg: "rgb(1,177,216,0.3)" }}>
                      Our Team
                    </MenuItem>
                  </HashLink>
                </Stack>
              </PopoverContent>
            </Popover>
          </Menu>

          {/* Services */}
          <Menu>
            <Popover trigger={"hover"} placement={"bottom-start"}>
              <PopoverTrigger>
                <MenuButton
                  className={
                    currentRoute.includes("services") ? "NavActive" : ""
                  }
                  //  onClick={() => handleNavigation('/services/blockchain')}
                >
                  <Text px={4}>Services</Text>
                </MenuButton>
              </PopoverTrigger>
              <PopoverContent className="popup" maxW="200px" border={"none"}>
                <Stack >
                  {/* Blockchain */}
                  <NavLink
                    _hover={{
                      textDecoration: "none",
                    }}
                    to="/services/blockchain"
                  >
                    <MenuItem p={2} _hover={{ bg: "rgb(1,177,216,0.3)" }}>
                      Blockchain
                    </MenuItem>
                  </NavLink>

                  {/* Extended Reality */}
                
                  {/* <HashLink
                    smooth
                    _hover={{
                      textDecoration: "none",
                    }}
                    to="/services/ar-vr"
                  > */}
                    <MenuItem     onClick={() => handleBoxClick(1)} p={2} _hover={{ bg: "rgb(1,177,216,0.3)" }}>
                      Extended Reality
                    </MenuItem>
                  {/* </HashLink> */}
                  <Stack   style={{
                display: 1 === openIndex ? "block" : "none",
                transition: "display 0.1s linear",
              }}>
                  {/* AR */}
                  <HashLink
                    smooth
                    _hover={{
                      textDecoration: "none",
                    }}
                    to="/services/ar-vr"
                  >
                    <MenuItem
                    p={1}
                    ml={"20px"}
                      fontSize={"12px"}
                  
                      _hover={{ color: "#01B1D8" }}
                    >
                      Augmented Reality
                    </MenuItem>
                  </HashLink>

                  {/* VR */}
                  <HashLink
                    smooth
                    _hover={{
                      textDecoration: "none",
                    }}
                    to="/services/ar-vr#virtualReality"
                  >
                    <MenuItem
                       p={1}
                      fontSize={"12px"}
                      _hover={{ color: "#01B1D8" }}
                      ml={"20px"}
                     
                    >
                      Virtual Reality
                    </MenuItem>
                  </HashLink>

                  {/* Mixed Reality */}
                  <HashLink
                    smooth
                    _hover={{
                      textDecoration: "none",
                    }}
                    to="/services/ar-vr#mixedreality"
                  >
                    <MenuItem
                      ml={"20px"}
                      fontSize={"12px"}
                      p={1}
                      _hover={{ color: "#01B1D8" }}
                    >
                      Mixed Reality
                    </MenuItem>
                  </HashLink>
                  </Stack>
                  {/* nft */}
                  <HashLink
                    smooth
                    _hover={{
                      textDecoration: "none",
                    }}
                    to="/services/nft-platform"
                  >
                    <MenuItem p={2} _hover={{ bg: "rgb(1,177,216,0.3)" }}>
                      NFT
                    </MenuItem>
                  </HashLink>

                  {/* Software solns */}
                  <HashLink
                    smooth
                    _hover={{
                      textDecoration: "none",
                    }}
                    to="/services/software-development"
                  >
                    <MenuItem p={2} _hover={{ bg: "rgb(1,177,216,0.3)" }}>
                      Software Solutions
                    </MenuItem>
                  </HashLink>
                </Stack>
              </PopoverContent>
            </Popover>
          </Menu>

          {/* Products */}

          <Menu>
            <Popover trigger={"hover"} placement={"bottom-start"}>
              <PopoverTrigger>
                <MenuButton
                  className={
                    currentRoute.includes("products") ? "NavActive" : ""
                  }
                  onClick={() => handleNavigation("/products")}
                >
                  <Text px={4}>Products</Text>
                </MenuButton>
              </PopoverTrigger>
              <PopoverContent className="popup" maxW="200px" border={"none"}>

              <MenuItem     onClick={() => handleBoxClick(1)} p={2} _hover={{ bg: "rgb(1,177,216,0.3)" }}>
              Web3SandPit
                    </MenuItem>
                  {/* </HashLink> */}
                  <Stack   style={{
                display: 1 === openIndex ? "block" : "none",
                transition: "display 0.1s linear",
              }}>
                  {/* Managed Blockchain as a Service Platform */}
                  <HashLink
                    smooth
                    _hover={{
                      textDecoration: "none",
                    }}
                    to="/products#baas"
                  >
                    <MenuItem
                    p={1}
                    my={"5px"}
                    ml={"20px"}
                      fontSize={"12px"}
                  maxW={"150px"}
                      _hover={{ color: "#01B1D8" }}
                    >
                       Managed Blockchain as a Service Platform
                    </MenuItem>
                  </HashLink>

                  {/*  NFT Plug & Play System */}
                  <HashLink
                    smooth
                    _hover={{
                      textDecoration: "none",
                    }}
                    to="/products#nftplatform"
                  >
                    <MenuItem
                     maxW={"150px"}
                    my="5px"
                       p={1}
                      fontSize={"12px"}
                      _hover={{ color: "#01B1D8" }}
                      ml={"20px"}
                     
                    >
                       NFT Plug & Play System
                    </MenuItem>
                  </HashLink>

                  {/* TG Data Sharing Platform */}
                  <HashLink
                    smooth
                    _hover={{
                      textDecoration: "none",
                    }}
                    to="/products#datasharingplatform"
                  >
                    <MenuItem
                     maxW={"150px"}
                    my={"5px"}
                      ml={"20px"}
                      fontSize={"12px"}
                      p={1}
                      _hover={{ color: "#01B1D8" }}
                    >
                      TG Data Sharing Platform
                    </MenuItem>
                  </HashLink>

                    {/* TG-CertiCheck */}
                  <HashLink
                    smooth
                    _hover={{
                      textDecoration: "none",
                    }}
                    to="/products#tgcerticheck"
                  >
                    <MenuItem
                     maxW={"150px"}
                    my={"5px"}
                      ml={"20px"}
                      fontSize={"12px"}
                      p={1}
                      _hover={{ color: "#01B1D8" }}
                    >
                      TG-CertiCheck
                    </MenuItem>
                  </HashLink>
                    {/* Asset Tracking Platform */}
                  <HashLink
                    smooth
                    _hover={{
                      textDecoration: "none",
                    }}
                    to="/products#productsection"
                  >
                    <MenuItem
                     maxW={"150px"}
                      ml={"20px"}
                      my={"5px"}
                      fontSize={"12px"}
                      p={1}
                      _hover={{ color: "#01B1D8" }}
                    >
                      Asset Tracking Platform
                    </MenuItem>
                  </HashLink>

                    {/* Token Distribution Platform */}
                  <HashLink
                    smooth
                    _hover={{
                      textDecoration: "none",
                    }}
                    to="/products#productsection"
                  >
                    <MenuItem
                     maxW={"150px"}
                    my={"5px"}
                      ml={"20px"}
                      fontSize={"12px"}
                      p={1}
                      _hover={{ color: "#01B1D8" }}
                    >
                      Token Distribution Platform
                    </MenuItem>
                  </HashLink>
                    {/* Document Verification Platform */}
                  <HashLink
                    smooth
                    _hover={{
                      textDecoration: "none",
                    }}
                    to="/products#productsection"
                  >
                    <MenuItem
                     maxW={"150px"}
                    my={"5px"}
                      ml={"20px"}
                      fontSize={"12px"}
                      p={1}
                      _hover={{ color: "#01B1D8" }}
                    >
                      Document Verification Platform
                    </MenuItem>
                  </HashLink>
                  </Stack>
              
              </PopoverContent>
            </Popover>
          </Menu>

          {/* Media */}

          <Menu>
            <Popover trigger={"hover"} placement={"bottom-start"}>
              <PopoverTrigger>
                <MenuButton
                  className={
                    currentRoute.includes("media-newsroom") ? "NavActive" : ""
                  }
                  onClick={() => handleNavigation("/media-newsroom")}
                >
                  <Text px={4}>Media</Text>
                </MenuButton>
              </PopoverTrigger>
              <PopoverContent className="popup" maxW="200px" border={"none"}>
                <Stack>
                  <HashLink
                    smooth
                    to="/media-newsroom#latesthappenings"
                    _hover={{
                      textDecoration: "none",
                    }}
                    className="NavHov"
                  >
                    <MenuItem p={2} _hover={{ bg: "rgb(1,177,216,0.3)" }}>
                      Latest Happenings
                    </MenuItem>
                  </HashLink>

                  <HashLink
                    smooth
                    to="/media-newsroom#pressRelease"
                    _hover={{
                      textDecoration: "none",
                    }}
                    className="NavHov"
                  >
                    <MenuItem p={2} _hover={{ bg: "rgb(1,177,216,0.3)" }}>
                      Press Releases
                    </MenuItem>
                  </HashLink>
                </Stack>
              </PopoverContent>
            </Popover>
          </Menu>
          {/* Contact us */}
          <NavLink
            className={currentRoute.includes("contact-us") ? "NavActive" : ""}
            onClick={() => handleNavigation("/contact-us")}
            to="/contact-us"
            _hover={{
              textDecoration: "none",
            }}
          >
            <Text px={4}>Contact Us</Text>
          </NavLink>

          {/* Blogs */}

          <Menu>
            <Popover trigger={"hover"} placement={"bottom-start"}>
              <PopoverTrigger>
                <MenuButton
                  className={currentRoute.includes("blogs") ? "NavActive" : ""}
                  onClick={() => handleNavigation("/blogs")}
                >
                  <Text px={4}>Blogs</Text>
                </MenuButton>
              </PopoverTrigger>
              <PopoverContent className="popup" maxW="200px" border={"none"}>
                <Stack>
                  <HashLink
                    smooth
                    to="/blogs#blogbox"
                    _hover={{
                      textDecoration: "none",
                    }}
                    className="NavHov"
                  >
                    <MenuItem p={2} _hover={{ bg: "rgb(1,177,216,0.3)" }}>
                      Blogs
                    </MenuItem>
                  </HashLink>

                  <HashLink
                    smooth
                    to="/blogs#faqs"
                    _hover={{
                      textDecoration: "none",
                    }}
                    className="NavHov"
                  >
                    <MenuItem p={2} _hover={{ bg: "rgb(1,177,216,0.3)" }}>
                      FAQs
                    </MenuItem>
                  </HashLink>
                </Stack>
              </PopoverContent>
            </Popover>
          </Menu>

          {/* CaseStudy */}
          <NavLink
            to="/case-study"
            _hover={{
              textDecoration: "none",
            }}
            className={currentRoute.includes("case-study") ? "NavActive" : ""}
            onClick={() => handleNavigation("/case-study")}
          >
            <Text px={4}>Client Stories</Text>
          </NavLink>
        </HStack>
      </Flex>
    </Flex>
    // ...........
  );
}

export default Navbar;
