import { Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import { HashLink } from "react-router-hash-link";

import BlockchainInFashion from "../../../assets/images/IndustrysolutionsPage/blockchian-in-spaceMonitor.webp";
import CommonButton from "../../../components/CommonButton.js";
import Underline from "../../../assets/images/HomePage/underline.webp";
function Space() {
  return (
    // new space section
    <Flex mt={{ base: "20px", md: "80px", lg: "200px" }} width={"100%"}>
      {/* title */}
      <Flex
        mx={"auto"}
        flexDir={{ base: "column-reverse", md: "row", lg: "row" }}
      >
        <Flex
          width={{ base: "100%", md: "60%", lg: "50%" }}
          flexDir={"column"}
          ml={{ base: "6%", md: "6%", lg: "10%" }}
        >
          <Text
            className="heading"
            color={"#94DAF8"}
            fontSize={{ base: "24px", md: "38px", lg: "45px" }}
          >
            Blockchain's Journey into Space
          </Text>
          <Image
            mt={"-10px"}
            ml={"-10px"}
            width={"70px"}
            src={Underline}  alt="underline"
          ></Image>

          <Text
            pr={{ base: "40px", md: "30px", lg: "30px" }}
            maxW={{ base: "", md: "380px", lg: "650px" }}
            mt={{ base: "30px", md: "40px", lg: "80px" }}
            fontSize={{ base: "14px", md: "14px", lg: "16px" }}
            color={"white"}
            className="content_font"
            fontWeight={"light"}
          >
            In recent years, the integration of blockchain, satellite data, and
            artificial intelligence (AI) has emerged as a transformative force.
            TrackGenesis is pioneering a new platform that empowers farmers by
            providing real-time information about their farms, enabling them to
            make informed decisions for optimising crop yields in accordance
            with the current climate conditions.
          </Text>
          <HashLink smooth to={"/contact-us#contact"}>
            <CommonButton
            width="100px"
              btn="Book a Demo"
              mt={{ base: "30px", md: "60px", lg: "30px" }}
            />
          </HashLink>
        </Flex>
        <Flex p={"20px"} width={{ base: "100%", md: "60%", lg: "50%" }}>
          <Image
       
            my={{ base: "30px", md: "0px", lg: "0px" }}
            objectFit={"contain"}
            src={BlockchainInFashion}
            // srcSet={`${BlockchainInFashionSmall} 1000w, ${BlockchainInFashion} 2000w`}
            width={"100%"}
            alt="Future of space technology blockchain"
            pointerEvents={"none"}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Space;
