import {
  Box,
 
  Flex,
 
  Image,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import React from "react";
import blogdatas from "../../../database/blogdata";
import ReactPaginate from "react-paginate";
import { useState, useEffect } from "react";
import { HashLink } from "react-router-hash-link";
import "./blogstyle.css";
function Blogbox() {
  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 6;
  const pagesVisited = pageNumber * usersPerPage;
  const displayUsers = blogdatas
    .slice(pagesVisited, pagesVisited + usersPerPage)
    .map((blogdata,index) => {
      return (
        <HashLink key={index}  to={`${blogdata.bloglink}`}>
          <Box
           className="animate__animated animate__slow animate__flipInY "
            _hover={{ bg: "black", color: " white" }}
            mx={{ md: "10px", lg: "10px" }}
            // width={"90%"}
            height={{ base: "330px", md: "330px", lg: "330px" }}
            overflow="hidden"
            backgroundColor={"#0B091E"}
            borderRadius={"5px"}
            boxShadow={"0px 0px 6.7px 0px #01B1D8"}
            mt={"20px"}
          >
            <Flex p={3}>
              <Image
                borderRadius={"5px"}
                objectFit="cover"
                src={blogdata.TopicimagePath}
                width="100%"
                height={"165px"}
                borderBottom="2px solid #01B1D8"
                // borderRadius={"5px 5px 0px 0px"}
              />
            </Flex>

            <Image
            objectFit={"contain"}
              border={"2px solid #01B1D8"}
              zIndex={1}
              borderRadius={"50%"}
              ml={"auto" }
              mr={"30px"}
              mt={"-30px"}
              width={{ base: "35px", md: "40px", lg: "40px" }}
              src={blogdata.blogerimagePath}
            />
            <Text
              fontSize={{ base: "13px", md: "13px", lg: "13px" }}
              p={"10px"}
              color={"white"}
              className="content_font"
              fontWeight={"light"}
            >
              {blogdata.Date}
            </Text>
            <Text
              pl={"10px"}
              fontSize={{ base: "13px", md: "14px", lg: "14px" }}
              className="heading"
              color={"#94DAF8"}
            >
              {blogdata.BlogTitle}
            </Text>
            <Box
              overflow={"clip"}
              height={{ base: "50px", md: "46px", lg: "50px" }}
            >
              <Text
                fontSize={{ base: "11px", md: "11px", lg: "11px" }}
                p={"10px"}
                color={"white"}
                className="content_font"
                fontWeight={"light"}
               
              >
                {blogdata.description}{" "}
              </Text>
            </Box>
          </Box>


        </HashLink>
      );
    });
  const pageCount = Math.ceil(blogdatas.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  return (
    <Flex alignItems={"center"} justifyContent="center" width={"100%"}>
      <Flex
        flexDirection={"column"}
        mt={{ base: "50px", md: "70px", lg: "70px" }}
        width={{ base: "90%", md: "90%", lg: "75%" }}
        borderRadius="20px"
        alignItems={"center"}
        justifyContent="center"
        zIndex={2}
        boxShadow="inner"
      >
        <SimpleGrid
          alignItems={"center"}
          justifyContent="center"
          p={"25px"}
          columns={[1, null, 3]}
        >
          {displayUsers}
        </SimpleGrid>
        <Flex
          pt={"25px"}
          alignItems={"center"}
          justifyContent="center"
          boxShadow={"lg"}
          borderRadius="5px"
          m="20px"
        >
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={"paginationBttns"}
            previousLinkClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Blogbox;
