import { Flex, Image, Text, Link } from "@chakra-ui/react";
import React from "react";
import { HashLink } from "react-router-hash-link";
import Baasgif from "../../../assets/images/ProductsPage/Gifs/Blockchain as a service.gif";
import CommonButton from "../../../components/CommonButton.js";

import Underline from "../../../assets/images/HomePage/underline.webp";
import VideoButton from "./VideoButton.js";

function Baas() {
  return (
    // new code
    <Flex
      id="baas"
      mt={{ base: "40px", md: "80px", lg: "200px" }}
      flexDir={"column"}
      width={"100%"}
    >
      {/* title */}
      <Flex flexDir={"column"} ml={{ base: "6%", md: "6%", lg: "6%" }}>
        <Text
          className="heading"
          color={"#94DAF8"}
          fontSize={{ base: "24px", md: "38px", lg: "45px" }}
        >
          Managed Blockchain as a Service Platform
        </Text>
        <Image
          mt={"-10px"}
          ml={"-10px"}
          width={"70px"}
          src={Underline}
          alt="underline"
        ></Image>
      </Flex>
      {/* content */}
      <Flex
        flexDir={{ base: "column", md: "column", lg: "row" }}
        mx={"auto"}
        width={{ base: "100%", md: "90%", lg: "80%" }}
        mt={{ base: "10px", md: "40px", lg: "70px" }}
      >
        <Flex
          px={{ base: "20px", md: "30px", lg: "20px" }}
          mt={{ base: "40px", md: "40px", lg: "0px" }}
          width={{ base: "100%", md: "100%", lg: "50%" }}
        >
          <Flex
            mx={"auto"}
            my={"auto"}
            borderRadius={"10px"}
            width={{ base: "400px", md: "500px", lg: "650px" }}
          >
            <Flex
              boxShadow="0px 0px 12.7px 0px #FAFC9E"
              mt={{ base: "-10px", md: "-10px", lg: "-10px" }}
              borderRadius={{ base: "40px", md: "50px", lg: "70px" }}
              zIndex={-1}
              border={"1px solid #FAFC9E"}
            >
              <Flex>
                <Image src={Baasgif}></Image>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          ml={{ base: "0px", md: "0px", lg: "20px" }}
          p={{ base: "10px", md: "10px", lg: "30px" }}
          mt={{ base: "40px", md: "40px", lg: "0px" }}
          width={{ base: "100%", md: "100%", lg: "50%" }}
        >
          <Flex
            width={"100%"}
            px={"40px"}
            height={"fit-content"}
            mx={"auto"}
            my={"auto"}
            flexDir={"column"}
          >
            {" "}
            <Text
              width={{ base: "100%", md: "100%", lg: "100%" }}
              fontSize={{ base: "14px", md: "14px", lg: "16px" }}
              color={"white"}
              className="content_font"
              fontWeight={"light"}
            >
              TrackGenesis developed an enterprise-level managed blockchain
              platform that is simple to integrate with any software programme
              utilising APIs. Most of the Blockchain as a Service (BaaS)
              providers allow users to access their blockchain network, but
              businesses need to hire blockchain specialists to build smart
              contracts and integrate specialised SDKs into their applications
              in order to connect to the network. We offer a plug-and-play
              blockchain platform, that can easily connect to our managed
              blockchain platform utilising APIs without having any blockchain
              expertise.
            </Text>
            <Flex>
              <HashLink smooth to={"/contact-us#contact"}>
                <CommonButton
                  width="100px"
                  btn="Book a Demo"
                  mt={{ base: "30px", md: "40px", lg: "30px" }}
                />
              </HashLink>
              <Link
                _hover={{ textDecoration: "none" }}
                isExternal
                href="https://www.youtube.com/watch?v=QTo1QaS89TI"
              >
                <VideoButton
                  width={{ base: "110px", md: "130px", lg: "150px" }}
                  ml="10px"
                  mt={{ base: "30px", md: "40px", lg: "30px" }}
                />
              </Link>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Baas;
