import { Flex, Heading, Image, Text } from "@chakra-ui/react";
import React from "react";
import Mobilenavbar from "../../../components/sections/Mobilenavbar";
import Navbar from "../../../components/sections/Navbar";
import Footer from "../../../components/sections/Footer";
import { ListItem, UnorderedList, Link } from "@chakra-ui/react";
import sherinprofile from "../../../assets/images/BlogsPage/sherinprofile.webp";
import blogthree from "../../../assets/images/BlogsPage/blogthree1.webp";

import { Helmet } from "react-helmet";

function Blogpagethree(props) {
  return (
    <>
      {/* helmet */}
      <Helmet>
        <title>
          Blogs | Cryptocurrency | TrackGenesis&reg; | Aberdeen Scotland
        </title>
        <meta
          name="title"
          content="Blogs | Cryptocurrency | TrackGenesis | Aberdeen Scotland"
        />
        <meta name="description" content="Blockchain Beyond Cryptocurrency" />
        <link
          href="https://trackgenesis.com/blogs"
          rel="canonical"
          content="max-age: 1209600, no-cache, no-store, must-revalidate"
        />
      
      </Helmet>
      {/* helmet */}
      <Flex flexDirection={"column"}  backgroundColor={"black"} id="blog-cryptocurrency">
        {/* heading Section */}
        <Flex
         backgroundColor={"black"}
         width="100%"
         flexDirection={"column"}
         alignItems="center"
         justifyContent={"center"}
         height={"400px"}
        >
          <Heading
            className="heading"
            color={"#94DAF8"}
            fontSize={{ md: "33px", base: "25px", lg: "37px" }}
            fontWeight="bold"
            textShadow="1px 1px #000000"
            mt={{ base: "36px", md: "35px", lg: "10px" }}
            textAlign="center"
            maxWidth={{ base: "80%", md: "65%", lg: "60%" }}
          >
            Blockchain Beyond Cryptocurrency
          </Heading>
        </Flex>

        <Navbar />
        <Mobilenavbar />

        <Flex 
        backgroundColor={"black"}
        alignItems={"center"}
        justifyContent="center"
        width="100%"
        >
          <Image
            mt={{ base: "-90px", md: "-90px", lg: "-110px" }}
            ml={{ base: "-5%", lg: "-5%" }}
            width={{ base: "91%", md: "80%", lg: "70%" }}
            // height={{ base: "300px", md: "300px", lg: "400px" }}
            src={blogthree}
          />
          {/* <Box
            position={"absolute"}
            ml={{ base: "-79%", md: "-74%", lg: "-68%" }}
            mt={{ base: "-50%", md: "-42%", lg: "-37%" }}
            backgroundColor={"rgba(255, 246, 236, 1)"}
            borderRadius="15px"
            boxShadow="lg"
            className="content_font"
          >
            <Text
              p={"15px"}
              fontWeight="bold"
              fontSize={{ base: "12px", md: "13px", lg: "14px" }}
            >
              09 <br /> Nov
              <br /> 2022{" "}
            </Text>
          </Box> */}
        </Flex>


        <Flex
          flexDir={"column"}
          position={"relative"}
          className="mainborder"
          my={{ base: "2%", md: "2%", lg: "2%" }}
          mx={{ base: "10px", md: "15px", lg: "30px" }}
          backgroundColor={"rgba(27, 21, 78, 0.38)"}
          backdropFilter="auto"
          backdropBlur="8px"
          pb={"100px"}
        >
          <Flex flexDir={"column"}>
        <Flex
         color={"white"}
         className="content_font"
         width={"100%"}
         px={{ base: "10%", md: "15%", lg: "20%" }}
         fontSize={{ base: "15px", md: "16px", lg: "17px" }}
         flexDirection="column"
        >
          <Text mt={"30px"} className="content_font">
            'Bitcoin and Blockchain' - these two terms are often heard together.
            Is blockchain same as bitcoin? There is a wide misconception that
            blockchain and bitcoin are the same.  
          </Text>
          <Text
            mt={"30px"}
            className="content_font"
            fontWeight={"bold"}
            // color="rgba(42, 50, 73, 1)"
            fontSize={"20px"}
          >
            What exactly is a Bitcoin?
          </Text>
          <Text mt={"30px"} className="content_font">
            <Text fontWeight={"bold"} as="i">
              Bitcoin is a cryptocurrency.
            </Text>{" "}
            Cryptocurrency is any form of currency that exists digitally or
            virtually and uses cryptography to secure transactions.
          </Text>

          <Text mt={"30px"} className="content_font">
            <Text fontWeight={"bold"} as="i">
              Cryptocurrencies don't have a central regulatory authority.{" "}
            </Text>
            It is a decentralized system to record transactions.
          </Text>
          <Text
            mt={"30px"}
            className="content_font"
            fontWeight={"bold"}
            // color="rgba(42, 50, 73, 1)"
            fontSize={"20px"}
          >
            What is Blockchain?
          </Text>

          <Text mt={"30px"} className="content_font">
            Blockchain is a decentralised technology that allows all network
            members to view and share data in real time. Even attempting to edit
            or erase data in the blockchain network is impossible since each
            piece of data is saved on numerous computers.
          </Text>
          <Text mt={"30px"} className="content_font">
            Blockchain is an immutable ledger that can securely store data in a
            decentralized manner. Once data is recorded in blockchain, it is
            unchangeable. A decentralized blockchain network provides real-time
            data access to everyone in the network, making it impossible for a
            single person to manipulate the data. Blockchain technology is
            revolutionizing several industries from banking to space. Real world
            blockchain use cases emerge every single day and, more and more
            businesses are adopting the technology.
          </Text>
          <Text mt={"30px"} className="content_font">
            According to research, blockchain market is expected to grow at a
            CAGR of 85.9% from 2022 to 2030.
          </Text>
          <Text mt={"30px"} className="content_font">
            Blockchain is the foundation of web 3.0, that transforms centralized
            data storage in the web's backend to decentralized data storage.
          </Text>
          <Text mt={"30px"} className="content_font">
            Let's look into some blockchain use cases in different industries
            that ensure better security and transparency:
          </Text>

          <Text mt={"30px"} className="content_font">
            <UnorderedList ml={"50px"}>
              <ListItem>Banking </ListItem>
              <ListItem>Insurance</ListItem>
              <ListItem>Oil and Gas</ListItem>
              <ListItem>Government & Public sectors</ListItem>
              <ListItem>Healthcare </ListItem>
              <ListItem>Crowdfunding  </ListItem>
              <ListItem>Events & Entertainment </ListItem>
              <ListItem> Food Industry</ListItem>
              <ListItem>Space Industry</ListItem>
            </UnorderedList>
          </Text>
          <Text
            mt={"30px"}
            className="content_font"
            fontWeight={"bold"}
            // color="rgba(42, 50, 73, 1)"
            fontSize={"20px"}
          >
            Banking
          </Text>
          <Text mt={"30px"} className="content_font">
            Blockchain is crucial in digitally transforming the banking sector.
            Nowadays, most consumers prefer cashless transactions. It is true
            that some people are still doubtful about the security of online
            transactions.
          </Text>
          <Text mt={"30px"} className="content_font">
            <Text as={"i"} fontWeight="bold">
              {" "}
              Banks may use blockchain to gain from extremely secure money
              transfer.
            </Text>{" "}
            Blockchain keeps an immutable record of every transaction and allows
            users to make rapid and secure transactions using their bank
            accounts.
          </Text>
          <Text mt={"30px"} className="content_font">
            <Text as={"i"} fontWeight="bold">
              {" "}
              Another issue solved by blockchain technology is the slowness of
              cross-border payments.{" "}
            </Text>{" "}
            International payments via banking channels are currently a
            multistep procedure involving several middlemen. The procedure takes
            time and has a large transaction cost.
          </Text>
          <Text mt={"30px"} className="content_font">
            <Text as={"i"} fontWeight="bold">
              Transactions can be observed in real time using a distributed
              blockchain technology.
            </Text>{" "}
            When a transaction is registered in the blockchain network, it gets
            validated and payment is immediately initiated to the recipient.
          </Text>
          <Text
            mt={"30px"}
            className="content_font"
            fontWeight={"bold"}
            // color="rgba(42, 50, 73, 1)"
            fontSize={"20px"}
          >
            Insurance
          </Text>
          <Text mt={"30px"} className="content_font">
            Imagine you have health insurance. Can you get the claim amount on
            time? Many times, you will have to go through lengthy procedures to
            claim your insurance and will not receive the money when you need
            it.
          </Text>
          <Text mt={"30px"} className="content_font">
            Blockchain technology has the potential to solve the problem.{" "}
            <Text as={"i"} fontWeight="bold">
              Data verification and claim reconciliation can be completed fast
              using smart contracts.
            </Text>
          </Text>
          <Text mt={"30px"} className="content_font" as={"i"} fontWeight="bold">
            Insurance fraud may also be reduced with the use of technology.
          </Text>
          <Text mt={"30px"} className="content_font">
            When an insurance claim is transferred to a blockchain-based ledger
            shared by the insurer and the claimant, everything becomes visible,
            and there is no longer any possibility of fraud or disagreement.
          </Text>
          <Text
            mt={"30px"}
            className="content_font"
            fontWeight={"bold"}
            // color="rgba(42, 50, 73, 1)"
            fontSize={"20px"}
          >
            Oil & Gas
          </Text>
          <Text mt={"30px"} className="content_font">
            In the oil and gas industry, several stakeholders collaborate.
            Keeping track of all transactions between the parties can be time
            consuming and complicated. All transactions are recorded in an
            immutable ledger to which multiple parties have access.
            <Text as={"i"} fontWeight="bold">
              {" "}
              This eliminates fraud, increase accountability and security, that
              in turn reduce the manufacturing cost.
            </Text>
          </Text>
          <Text mt={"30px"} className="content_font" as={"i"} fontWeight="bold">
            Another advantage of blockchain in the oil and gas business is that
            it provides transparency in the supply chain.
          </Text>
          <Text mt={"30px"} className="content_font">
            Every stakeholder in the supply chain may monitor each stage of the
            product, ensuring that quality and pricing are not controlled by a
            single individual.
          </Text>
          <Text mt={"30px"} className="content_font">
            Non-renewable resources like oil and gas are constantly in great
            demand. The social and environmental consequences of its use go
            unrecognised. Blockchain tracks the carbon impact of the final
            product along the supply chain, allowing businesses to implement
            sustainable efforts.
          </Text>
          <Text mt={"30px"} className="content_font">
            <Text as={"i"} fontWeight="bold">
              {" "}
              Another blockchain use in the oil and gas business is asset
              tracking. During the production process, different parties
              frequently share or rent heavy equipment.
            </Text>{" "}
            In the sector, asset tracking systems are quite important.
            Traditional asset tracking systems are accused of being opaque,
            which may be remedied with blockchain technology.
          </Text>
          <Text
            mt={"30px"}
            className="content_font"
            fontWeight={"bold"}
           
            fontSize={"20px"}
          >
            Government and Public Sectors
          </Text>
          <Text mt={"30px"} className="content_font">
            To begin with, getting things done in a government office takes too
            long.{" "}
            <Text as={"i"} fontWeight="bold">
              {" "}
              Blockchain based government models allow businesses, government
              and individuals to securely share resources within the distributed
              ledger.
            </Text>{" "}
            Furthermore, smart contracts may automate numerous laborious tasks,
            which can streamline the process and save time and money.
          </Text>
          <Text mt={"30px"} className="content_font">
            Second, complaints are frequently filed against corrupt authorities.
            Blockchain technology provides a solution to the problem.
            <Text as={"i"} fontWeight="bold">
              Blockchain's decentralised structure makes government processes
              more transparent and user-friendly, leaving no room for
              corruption.
            </Text>
          </Text>
          <Text mt={"30px"} className="content_font">
            It is not the case that using blockchain technology benefits simply
            the general population. Decentralized and immutable records of
            blockchain technology can offer transparent motor vehicle tracking
            system, proper and easy tax transactions, secured digital voting
            system and much more.
          </Text>
          <Text
            mt={"30px"}
            className="content_font"
            fontWeight={"bold"}
           
            fontSize={"20px"}
          >
            Crowdfunding
          </Text>
          <Text mt={"30px"} className="content_font">
            Crowdfunding platforms are becoming more popular. People who are
            really interested in investing may be put off by their doubts about
            the legitimacy of the crowdfunding cause.
          </Text>
          <Text mt={"30px"} className="content_font">
            Crowd fundraising platforms are claimed to be beset by a variety of
            issues ranging from investor abuse to security concerns and illicit
            activities.
          </Text>
          <Text mt={"30px"} className="content_font" as={"i"} fontWeight="bold">
            Blockchain ensures safe transactions and records them in a
            tamper-proof ledger.
          </Text>
          <Text mt={"30px"} className="content_font">
            Profit and non-profit groups who receive crowdfunding share the
            necessary documentation in the decentralised blockchain system to
            substantiate the reason. All network investors may verify the
            records and then make the right decision.
          </Text>
          <Text mt={"30px"} className="content_font">
            <Text as={"i"} fontWeight="bold">
              Initial Coin Offerings (ICOs) are yet another blockchain benefit
              in crowdfunding industry.
            </Text>{" "}
            ICO can be a viable alternative to a public offering. Without equity
            exchange, digital tokens are issued that function as the company's
            shares. Unlike equity, the trade of ICOs is rather straightforward
            and increase the participation in crowdfunding.
          </Text>
          <Text
            mt={"30px"}
            className="content_font"
            fontWeight={"bold"}
           
            fontSize={"20px"}
          >
            Event and Entertainment
          </Text>
          <Text mt={"30px"} className="content_font">
            Events and entertainment industry are seeking new ways to grow
            revenue. Today online ticket selling platforms dominate the market.
          </Text>
          <Text mt={"30px"} className="content_font">
            <Text as={"i"} fontWeight="bold">
              Blockchain addresses many challenges in the industry such as fake
              tickets and security by issuing tickets as digital tokens.{" "}
            </Text>
            A decentralised blockchain ticketing system communicates transaction
            history with buyers and sellers while maintaining a tamper-proof
            record. Tickets are offered as non-fungible tokens(NFTs) that can
            never be duplicated.
          </Text>
          <Text
            mt={"30px"}
            className="content_font"
            fontWeight={"bold"}
          
            fontSize={"20px"}
          >
            Healthcare
          </Text>
          <Text mt={"30px"} className="content_font">
            Blockchain has the potential to transform the healthcare business in
            a variety of ways, from patient data management to medicine
            traceability.
          </Text>
          <Text mt={"30px"} className="content_font">
            <Text as={"i"} fontWeight="bold">
              Blockchain technology can safely manage patient data.
            </Text>{" "}
            Because blockchain is a decentralised system, data from various
            departments may be accessed by a physician from any department in
            the hospital. As a result, the physician may quickly learn about his
            patient. The immutability of blockchain prevents healthcare data
            from being misused.
          </Text>
          <Text mt={"30px"} className="content_font">
            Other issues in the healthcare business are drug counterfeiting,
            expiry date manipulation and over pricing.
            <Text as={"i"} fontWeight="bold">
              {" "}
              Blockchain creates transparency in the medicinal supply chain,
              allowing patients to have trust in the medications they use.
            </Text>
          </Text>
          <Text
            mt={"30px"}
            className="content_font"
            fontWeight={"bold"}
       
            fontSize={"20px"}
          >
            Food Industry
          </Text>
          <Text mt={"30px"} className="content_font">
            <Text as={"i"} fontWeight="bold">
              Food traceability and a{" "}
              <Link href="https://trackgenesis.com/blogs/blog-blockchain#blog-food&drink">
                lack of transparency in the food supply chain
              </Link>{" "}
              frequently result in major health problems.
            </Text>{" "}
            Blockchain monitors a food product's path from its origin to the
            customer, allowing them to learn about the farmer who farms their
            food, the intermediates in the food supply chain, and the
            environment in which the food is processed.
          </Text>
          <Text mt={"30px"} className="content_font">
            In the seafood sector, counterfeits are on the rise, and mislabelled
            fish is a common occurrence. The majority of seafood is sold on the
            global market, and the supply chain is extremely complicated.
            Blockchain technology, with its transparent system and immutable
            data storage, has the potential to eliminate fraud in the worldwide
            fish supply chain.
          </Text>
          <Text
            mt={"30px"}
            className="content_font"
            fontWeight={"bold"}
           
            fontSize={"20px"}
          >
            Space Industry
          </Text>
          <Text mt={"30px"} className="content_font">
            Blockchain have use case even in the space industry. From
            identifying the suppliers in the aerospace supply chain to
            tokenizing space resources, blockchain have many use cases in the
            space industry.
          </Text>
          <Text mt={"30px"} className="content_font">
            During the manufacturing of a spacecraft, many modules are
            outsourced. To ensure the quality and regulate the manufacturing
            cost, we need to map the suppliers in each tier of the supply chain
            network. To do the supply chain mapping in the complex aerospace
            industry is a difficult and time-consuming task.
            <Text as={"i"} fontWeight="bold">
              {" "}
              Decentralized blockchain system increase visibility and help
              identify the supply chain actors in less time.
            </Text>
          </Text>
          <Text mt={"30px"} className="content_font">
            Blockchain store data in tamper-proof ledger which is secured using
            cryptographic algorithm. This technology can help create secured
            digital tokens and allocate it to space resources.{" "}
            <Text as={"i"} fontWeight="bold">
              {" "}
              In a similar way, data gathered during a space mission can be
              tokenized and can be sold to other space agencies to raise the
              fund for next mission.
            </Text>
          </Text>
        </Flex>
        <Flex my={"20px"} width={"100%"}>
          <Flex ml={{ base: "40%", md: "65%", lg: "65%" }}>
            <Text
              color={"white"}
              className="content_font"
              fontSize={{ md: "23px", base: "15px", lg: "25px" }}
            >
              - by Sherin Thomas
            </Text>
            <Image
            objectFit={"contain"}
              ml={{ base: "10px", md: "10px", lg: "30px" }}
              mt={"-10px"}
              width={{ base: "30px", md: "40px", lg: "55px" }}
              src={sherinprofile}
            />
          </Flex>
        </Flex>
</Flex></Flex>
        <Footer />
      </Flex>
    </>
  );
}

export default Blogpagethree;
