import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";

import Mobilenavbar from "../../components/sections/Mobilenavbar";
import Navbar from "../../components/sections/Navbar";
import Footer from "../../components/sections/Footer";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useForm as useFormspree } from "@formspree/react";
import { Helmet } from "react-helmet";

import Learning from "../../assets/images/ContactUsAndCareer/learn.webp";
import Gadget from "../../assets/images/ContactUsAndCareer/gadget.webp";
import Careerheader from "../../assets/images/ContactUsAndCareer/CareersHeader.webp";
import Work from "../../assets/images/ContactUsAndCareer/workk.webp";
import Success from "../../assets/images/ContactUsAndCareer/successs.webp";
function Career(props) {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [qualification, setQualification] = useState();
  const [skills, setSkills] = useState();

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // formspree
  const [serverState, sendToFormspree] = useFormspree("xayvkeoo");

  // on succesfull submission of form in formspree
  if (serverState.succeeded) {
    navigate("/home");
  }

  return (
    <>
      {/* helmet */}
      <Helmet>
        <title>
          Careers | Apply Now | TrackGenesis&reg; | Aberdeen Scotland
        </title>
        <meta
          name="title"
          content="Careers | Apply Now | TrackGenesis | Aberdeen Scotland"
        />
        <meta
          name="description"
          content="Discover new job opportunities - Join the team of software developers, blockchain experts and digital marketing executives. Careers in TrackGenesis - Apply now"
        />
        <link
          href="https://trackgenesis.com/careers"
          rel="canonical"
          content="max-age: 1209600, no-cache, no-store, must-revalidate"
        />

        {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-197747980-1"
        ></script>
      </Helmet>
      {/* helmet */}
      <Flex flexDirection={"column"} id="career" backgroundColor={"black"}>
        {/* new heading section */}
        <Flex id="about">
          <Image
            ml={{ base: "-50px", md: "-40px", lg: "0px" }}
            width={{ base: "270px", md: "400px", lg: "500px" }}
            src={Careerheader}
          ></Image>
          <Text
            mt={"auto"}
            className="heading"
            mb={{ base: "15px", md: "15px", lg: "30px" }}
            color={"#94DAF8"}
            fontSize={{ base: "30px", md: "45px", lg: "50px" }}
            ml={{ base: "-29%", md: "-19%", lg: "-10%" }}
          >
            Careers
          </Text>
        </Flex>

        <Navbar />
        <Mobilenavbar />

        <Flex
          flexDir={"column"}
          position={"relative"}
          className="mainborder"
          mx={{ base: "10px", md: "15px", lg: "30px" }}
          backgroundColor={"rgba(27, 21, 78, 0.38)"}
          backdropFilter="auto"
          backdropBlur="8px"
        >
          {/* why work with us */}
          <Flex
            mt={{ base: "", md: "", lg: "50px" }}
            width={{ base: "80%", md: "80%", lg: "60%" }}
            flexDir={"column"}
            mx={"auto"}
          >
            <Text
              className="heading"
              color={"#94DAF8"}
              fontSize={{ base: "23px", md: "25px", lg: "25px" }}
              mt={{ base: "8vh", md: "20px", lg: "20px" }}
            >
              Why work with us?
            </Text>

            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              mt={{ base: "50px", md: "50px", lg: "50px" }}
            >
              <Flex
                px={{ base: "5px", md: "20px", lg: "40px" }}
                justifyContent={"center"}
                alignItems={"center"}
                flexDir={"column"}
              >
                <Image
                  objectFit={"contain"}
                  width={{ base: "50px", md: "70px", lg: "70px" }}
                  src={Learning}
                ></Image>
                <Text
                  mt={"20px"}
                  fontSize={{ base: "8px", md: "12px", lg: "15px" }}
                  color={"white"}
                  className="content_font"
                  fontWeight={"light"}
                >
                  Culture of learning
                </Text>
              </Flex>

              <Flex
                px={{ base: "5px", md: "20px", lg: "40px" }}
                justifyContent={"center"}
                alignItems={"center"}
                flexDir={"column"}
              >
                <Image
                  objectFit={"contain"}
                  width={{ base: "50px", md: "70px", lg: "70px" }}
                  src={Success}
                ></Image>
                <Text
                  mt={"20px"}
                  fontSize={{ base: "8px", md: "12px", lg: "15px" }}
                  color={"white"}
                  className="content_font"
                  fontWeight={"light"}
                >
                  Shared Success
                </Text>
              </Flex>

              <Flex
                px={{ base: "5px", md: "20px", lg: "40px" }}
                justifyContent={"center"}
                alignItems={"center"}
                flexDir={"column"}
              >
                <Image
                  objectFit={"contain"}
                  width={{ base: "50px", md: "70px", lg: "70px" }}
                  src={Work}
                ></Image>
                <Text
                  mt={"20px"}
                  fontSize={{ base: "8px", md: "12px", lg: "15px" }}
                  color={"white"}
                  className="content_font"
                  fontWeight={"light"}
                >
                  Conductive work
                </Text>
              </Flex>

              <Flex
                px={{ base: "5px", md: "20px", lg: "40px" }}
                justifyContent={"center"}
                alignItems={"center"}
                flexDir={"column"}
              >
                <Image
                  objectFit={"contain"}
                  width={{ base: "50px", md: "70px", lg: "70px" }}
                  src={Gadget}
                ></Image>
                <Text
                  mt={"20px"}
                  fontSize={{ base: "8px", md: "12px", lg: "15px" }}
                  color={"white"}
                  className="content_font"
                  fontWeight={"light"}
                >
                  Best gadgets
                </Text>
              </Flex>
            </Flex>
          </Flex>

          {/* new Form content */}
          <Flex
            data-aos="fade-up"
            data-aos-easing="ease-in-quad"
            data-aos-duration="1200"
            my={{ base: "50px", md: "60px", lg: "60px" }}
            mx={"auto"}
            width={{ base: "80%", md: "80%", lg: "60%" }}
            backgroundColor={"rgba(0, 0, 0, 0.25)"}
            borderRadius={"5px"}
            backdropFilter="auto"
            backdropBlur="2px"
            boxShadow={"0px 0px 32.5px -12px rgba(1, 177, 216, 0.45) inset"}
          >
            <Flex
              m={{ base: "10px", md: "20px", lg: "20px" }}
              px={{ base: "20px", md: "100px", lg: "100px" }}
              py={{ base: "10px", md: "50px", lg: "50px" }}
              // data-aos="fade-up"
              // data-aos-easing="ease-in-quad"
              // data-aos-duration="1400"

              width={"100%"}
              height={"fit-content"}
              justifyContent="center"
              flexDirection={"column"}
            >
              <Text
                className="heading"
                color={"#94DAF8"}
                fontSize={{ base: "23px", md: "25px", lg: "25px" }}
                mt={{ base: "20px", md: "0", lg: "0px" }}
              >
                Create Future with us?
              </Text>
              <br />
              <Text
                fontSize={{ base: "12px", md: "12px", lg: "15px" }}
                color={"white"}
                className="content_font"
                fontWeight={"light"}
              >
                Fill your details and we will get back to you soon
              </Text>

              <form onSubmit={handleSubmit(sendToFormspree)}>
                <VStack
                  width={{ base: "100%", md: "100%", lg: "100%" }}
                  pt={"40px"}
                >
                  <FormControl>
                    <FormLabel
                      fontSize={{ base: "12px", md: "12px", lg: "15px" }}
                      color={"white"}
                      className="content_font"
                      fontWeight={"light"}
                    >
                      Name
                    </FormLabel>
                    <Input
                      border={"1px solid "}
                      borderColor={"#94DAF8"}
                      color={"white"}
                      className="content_font"
                      fontWeight={"light"}
                      boxShadow={"inner"}
                      bgColor={"#0B091E"}
                      id="name"
                      type="text"
                      value={name}
                      name="name"
                      {...register("name", {
                        required: "Name field is required",
                      })}
                    />
                    <Text className="error">{errors?.name?.message}</Text>
                  </FormControl>

                  <FormControl>
                    <FormLabel
                      fontSize={{ base: "12px", md: "12px", lg: "15px" }}
                      color={"white"}
                      className="content_font"
                      fontWeight={"light"}
                    >
                      Email
                    </FormLabel>
                    <Input
                      border={"1px solid "}
                      borderColor={"#94DAF8"}
                      color={"white"}
                      className="content_font"
                      fontWeight={"light"}
                      boxShadow={"inner"}
                      bgColor={"#0B091E"}
                      id="email"
                      value={email}
                      name="email"
                      {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i,
                          message: "Invalid email format",
                        },
                      })}
                    />
                    <Text className="error">{errors?.email?.message}</Text>
                  </FormControl>

                  <FormControl>
                    <FormLabel
                      fontSize={{ base: "12px", md: "12px", lg: "15px" }}
                      color={"white"}
                      className="content_font"
                      fontWeight={"light"}
                    >
                      Highest Qualification
                    </FormLabel>
                    <Input
                      border={"1px solid "}
                      borderColor={"#94DAF8"}
                      color={"white"}
                      className="content_font"
                      fontWeight={"light"}
                      boxShadow={"inner"}
                      bgColor={"#0B091E"}
                      id="qualification"
                      type="text"
                      value={qualification}
                      name="qualification"
                      {...register("qualification", {
                        required: "Highest qualification field is required",
                      })}
                    />
                    <Text className="error">
                      {errors?.qualification?.message}
                    </Text>
                  </FormControl>
                  <FormControl>
                    <FormLabel
                      fontSize={{ base: "12px", md: "12px", lg: "15px" }}
                      color={"white"}
                      className="content_font"
                      fontWeight={"light"}
                    >
                      Skill Sets
                    </FormLabel>
                    <Input
                      border={"1px solid "}
                      borderColor={"#94DAF8"}
                      color={"white"}
                      className="content_font"
                      fontWeight={"light"}
                      boxShadow={"inner"}
                      bgColor={"#0B091E"}
                      id="skills"
                      type="text"
                      value={skills}
                      name="skills"
                      {...register("skills", {
                        required: "Skill sets field is required",
                      })}
                    />
                    <Text className="error">{errors?.skills?.message}</Text>
                  </FormControl>
                  <Flex width={"100%"} justifyContent="end" pt={6}>
                    <Flex
                      borderRadius={"6px"}
                      padding={"2px"}
                      width={"fit-content"}
                      height={"fit-content"}
                      border="1px solid #01B0D7"
                    >
                      <Button
                        width={{ base: "68px", md: "80px", lg: "90px" }}
                        height={{ base: "16px", md: "20px", lg: "30px" }}
                        className="heading"
                        backgroundColor={"#01B0D7"}
                        _hover={{ bg: "#8DE6F9" }}
                        fontSize={{
                          base: "",
                          md: "",
                          lg: "",
                          xl: "md",
                          "2xl": "md",
                        }}
                        type="submit"
                        disabled={serverState.submitting}
                      >
                        Submit
                      </Button>
                    </Flex>
                  </Flex>
                </VStack>
                <Text
                  my={"10px"}
                  fontSize={{ base: "12px", md: "12px", lg: "15px" }}
                  color={"white"}
                  className="content_font"
                  fontWeight={"light"}
                >
                  Send your updated resume to info@trackgenesis.com.
                </Text>
                {serverState.errors &&
                  serverState.errors.length > 0 &&
                  serverState.errors.map((err, index) => (
                    <Text key={index}>{err.message}</Text>
                  ))}
              </form>
            </Flex>
          </Flex>
        </Flex>

        <Footer />
      </Flex>
    </>
  );
}

export default Career;
