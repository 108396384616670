import { Flex,  Image, Text } from "@chakra-ui/react";
import React from "react";
import { HashLink } from "react-router-hash-link";
import EducationImageSmall from "../../../assets/images/Ar&VrPage/arimage4.webp";
import EducationImage from "../../../assets/images/Ar&VrPage/arimage4.webp";
import CommonButton from "../../../components/CommonButton.js";
import Underline from "../../../assets/images/HomePage/underline.webp";
function Education() {
  return (
    // new code
    <Flex mt={{ base: "20px", md: "80px", lg: "200px" }} width={"100%"}>
      {/* title */}
      <Flex
        mx={"auto"}
        flexDir={{ base: "column-reverse", md: "row", lg: "row" }}
      >
        <Flex
          width={{ base: "100%", md: "60%", lg: "50%" }}
          flexDir={"column"}
          ml={{ base: "6%", md: "6%", lg: "10%" }}
        >
          <Text
            className="heading"
            color={"#94DAF8"}
            fontSize={{ base: "24px", md: "38px", lg: "45px" }}
            width={{ base: "330px", md: "330px", lg: "400px" }}
          >
            AR in Education
          </Text>
          <Image
            alt="underline"
            mt={"-10px"}
            ml={"-10px"}
            width={"70px"}
            src={Underline}  
          ></Image>

          <Text
            mt={{ base: "30px", md: "40px", lg: "80px" }}
            maxW={{ base: "300px", md: "380px", lg: "650px" }}
            fontSize={{ base: "14px", md: "14px", lg: "16px" }}
            color={"white"}
            className="content_font"
            fontWeight={"light"}
            pr={"20px"}
          >
            AR enhances the classroom experience by bringing concepts and
            objects to classrooms. Learning becomes more engaging with the help
            of AR. Visual and interactive learning create a better understanding
            of concepts and usher in a new era of education.
          </Text>
          <HashLink smooth to={"/contact-us#contact"}>
            <CommonButton
            width="100px"
              btn="Book a Demo"
              mt={{ base: "30px", md: "60px", lg: "30px" }}
            />
          </HashLink>
        </Flex>
        <Flex p={"20px"} width={{ base: "100%", md: "60%", lg: "50%" }}>
          <Image
            objectFit={"contain"}
            srcSet={`${EducationImageSmall} 1000w, ${EducationImage} 2000w`}
            width={"100%"}
            alt="AR Education"
            pointerEvents={"none"}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Education;
