import Ieee from "../../src/assets/images/Media&newsroomPage/ieee.webp";
import Censis from "../../src/assets/images/Media&newsroomPage/censis.webp";
import BHL22 from "../../src/assets/images/Media&newsroomPage/BHL22.webp";
import BHL23 from "../../src/assets/images/Media&newsroomPage/BHL23.webp";
import BHL23new from "../../src/assets/images/Media&newsroomPage/blk23new.webp";
import Dubai from "../../src/assets/images/Media&newsroomPage/Dubai.webp";
import Philly from "../../src/assets/images/Media&newsroomPage/Pilly.webp";
import Expo from "../../src/assets/images/Media&newsroomPage/expo.webp";
import Roehill from "../../src/assets/images/Media&newsroomPage/roehill.webp";
import Rcet from "../../src/assets/images/Media&newsroomPage/rcet.webp";
import Event1 from "../../src/assets/images/Media&newsroomPage/event1.webp";
import Event2 from "../../src/assets/images/Media&newsroomPage/event2.webp";
import SIB from "../../src/assets/images/Media&newsroomPage/sib.webp";
import FY from "../../src/assets/images/Media&newsroomPage/fy.webp";
import Nexus from "../../src/assets/images/Media&newsroomPage/nexus.webp";
const eventdata = [

  {
    imagesmallPath: Nexus,
    imageslargePath:Nexus,
    heading:"Nexus 2050 Luxembourg",
    description:"Introduced TrackGenesis's Web3sandpit platform at Nexus 2050 Luxembourg. Connected with many like-minded individuals in Blockchain, AI and other cutting edge technology."
  },
  {
    imagesmallPath: FY,
    imageslargePath:FY,
    heading:"Mobile World Congress Barcelona 2024",
    description:"Four days to explore, exhibit, and interact. It was an excellent opportunity to network with industry experts to showcase our innovations. We felt overwhelmed by the wonderful response from the technology enthusiasts who stopped by our stand."
  },

  {
    imagesmallPath: Censis,
    imageslargePath:Censis,
    heading:"Censis Technology Summit",
    description:"Presented on ‘Food & Drink Traceability and Provenance through the Integration of IoT and Blockchain’ at Censis Technology Summit 2023."
  },

  {
    imagesmallPath: BHL22,
    imageslargePath:BHL22,
    heading:"Blockchain Hash Live 2022",
    description:" Our Blockchain Architect Justin J Daniel was a distinguished panel member, briefing our Blockchain Adoptions and Entrepreneurial journey at BlockHash Live 2022. "
  },
  {
    imagesmallPath: BHL23,
    imageslargePath:BHL23,
    heading:"Web3 Solution Showcase",
    description:"We opened our booth at BlockHash Live 2023, to demonstrate our innovative blockchain projects and deliver immersive experiences using virtual reality. Our CEO, Rajesh Kumar Plamthottathil was one of the distinguished speakers at the tech event."
  },

  {
    imagesmallPath: BHL23new,
    imageslargePath:BHL23new,
    heading:"BlockHash Live 2023",
    description:"Rajesh was a recognized panel member at BlockHash Live 2023. He discussed how blockchain technology could address the traceability issue in today's world. He responded to several fascinating questions posed by the audience."
  },
  {
    imagesmallPath:Ieee ,
    imageslargePath:Ieee,
    heading:"CryptoEx 2023",
    description:"Presented our Circulogy project poster explaining blockchain technology in e-waste management, on behalf of our team at the prestigious IEEE CryptoEx 2023 program in Dubai."
  },
  {
    imagesmallPath: Philly,
    imageslargePath:Philly,
    heading:"Philly Trade Mission",
    description:"Our CEO, Rajesh Kumar Plamthottathil went on a trade mission to Philadelphia. He had an incredible experience connecting with so many outstanding individuals and exploring more opportunities."
  },
  {
    imagesmallPath: Dubai,
    imageslargePath:Dubai,
    heading:"Innovation Startups Exhibition & Summit",
    description:"Participated in the Innovation Startups & Exhibition took place on 3rd and 4th May 2023 at DIFC Innovation Hub in DubaiIt was an outstanding event with attendees from a wide range of businesses, including Metaverse, Blockchain, Web3, DeFi, Cryptocurrency, NFT, and Gaming."
  },
  {
    imagesmallPath: Expo,
    imageslargePath:Expo,
    heading:"Blockchain Expo London",
    description:"Our CEO, Rajesh Kumar Plamthottathil introduced TrackGenesis’s new plug and play managed blockchain platform at London Blockchain Expo 2023."
  },
  {
    imagesmallPath: Roehill,
    imageslargePath:Roehill,
    heading:"Scotland Future of Food and Drink Summit 2023",
    description:"At the event hosted by The Times and Sunday Times in partnership with Opportunity North East Limited, we and our client Roehill Springs Distillery proudly presented their award-winning Gin, featuring cutting-edge blockchain integration with IoT devices developed by TrackGenesis and CENSIS.  "
  },
  {
    imagesmallPath: Rcet,
    imageslargePath:Rcet,
    heading:"Rajagiri Kids Fest 2022",
    description:"TrackGenesis team exhibited augmented reality and virtual reality projects at Kids Fest 2022 hosted by Rajagiri Public School in Kochi, Kerala. Our 'AR Book of Wonders' and 'VR Marine World' mesmerised kids and parents. "
  },
  {
    imagesmallPath: Event1,
    imageslargePath:Event1,
    heading:"Scottish Blockchain Meetup ",
    description:"Excellent talk by our CEO, Rajesh Kumar Plamthottathil at the Scottish Blockchain Meetup in Edinburg. He led a session on 'Exploring Blockchain and its Applications' and discussed some of our projects."
  },
  {
    imagesmallPath: Event2,
    imageslargePath:Event2,
    heading:"Circular Chem Event 2023",
    description:"Presented at Newcastle University’s Circular Chem Summer School 2023 event in partnership with UKRI CircularChem, to discuss the groundbreaking potential of blockchaintechnology to support circular chemistry. "
  },
  {
    imagesmallPath: SIB,
    imageslargePath:SIB,
    heading:"Carribean Investment Forum 2023",
    description:" Our CEO, Rajesh Kumar Plamthottathil attended the Caribbean Investment Forum 2023 at the magnificent Atlantis Hotel in the Bahamas. He had the privilege to meet the esteemed Honourable Prime Minister of The Bahamas, Philip Davis, and Deputy Prime Minister, Hon. Chester Cooper, and connect with numerous investors and entrepreneurs."
  },
 
  
];

export default eventdata;
