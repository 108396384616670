


const faqdata = [
  

    
    {
      question:"What is blockchain in simple words?",
      description:"Blockchain is a decentralized digital ledger that records transactions across multiple computers, enabling secure and transparent data management without the need for a central authority, ensuring trust and immutability."
    },

    {
        question:"Can blockchain be hacked?",
        description:"Blockchain is hard to hack. Its decentralized, immutable nature and cryptographic security measures make it exceedingly challenging, deterring attacks and ensuring a robust digital trust ecosystem.You can create NFT using our NFT plug-and-play platform, even without any coding skills. Through a simple user interface, our software will enable users to upload their digital assets and mint NFT for them."
      },

      {
        question:"How blockchain works step by step?",
        description:"1.	Transaction is initiated by any of the network participant. 2.	Each transaction is encrypted using a hash algorithm. 3. It is added to the blockchain as blocks after validation and verifying the hash value of previous block."
      },
      {
        question:"Where blockchain is used?",
        description:"Blockchain is used in various fields, including supply chain management, carbon trading, asset management and healthcare, ensuring secure, transparent, and immutable data storage, enabling decentralized transactions, and fostering trust among participants."
      },
      {
        question:"How certificates are verified using blockchain? ",
        description:"Certificates are stored as encrypted data on the blockchain. Verification involves cross-referencing the certificate's unique code with the distributed ledger, ensuring authenticity, and preventing tampering."
      },
      {
        question:"AR and VR difference with examples. ",
        description:"Augmented Reality (AR) overlays digital content onto the real world, enhancing it (e.g., Pokémon Go). Virtual Reality (VR) immerses users in a simulated environment (e.g., Oculus Rift), disconnecting them from the physical world."
      },
      {
        question:"What is AR and VR learning? ",
        description:"AR (Augmented Reality) and VR (Virtual Reality) learning bring books into life, providing education through interactive, engaging, and experiential learning experiences."
      },
     
      {
        question:"What can NFTs be used for?",
        description:"NFTs can be used for authenticating digital art, tokenizing real-world assets, and creating unique collectibles of products, revolutionizing ownership, provenance, and monetization in various industries like art, gaming, and real estate."
      },
      {
        question:"Can someone create NFTs without coding skills?",
        description:"Anyone can create NFTs without coding skills using plug and play NFT system, enabling the tokenization of unique digital assets, from artwork to collectibles."
      },
      {
        question:"Do you develop decentralized digital identity platform?",
        description:"We’re experts in blockchain and can develop a decentralized identity platform that protects privacy and secures documents."
      },
      {
        question:"How does blockchain help farmers?",
        description:"Blockchain benefits farmers by maintaining transparent and immutable records of agricultural data such as crop yields, quality, and supply chain information. This improves traceability, eliminates fraud, and ensures fair payments. Smart contracts on blockchain also automate and secure transactions, increasing trust and efficiency in agricultural processes."
      },
      {
        question:"Can NFTs be used for creating unique digital collectibles of limited edition products?",
        description:"Yes, NFTs (Non-Fungible Tokens) are commonly used for creating unique digital collectibles of limited-edition products. Brands, artists, and creators can tokenize their limited-edition products as NFTs, enabling collectors to buy, sell, and trade these exclusive digital collectibles on blockchain platforms."
      },
     
    
     
  ];
  
  export default faqdata;