import { Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import { HashLink } from "react-router-hash-link";
import Assetgif from "../../../assets/images/ProductsPage/Gifs/Asset tracking.gif";
import CommonButton from "../../../components/CommonButton.js";

import Underline from "../../../assets/images/HomePage/underline.webp";
function AssetTracking() {
  return (
    // new code
    <Flex
      id="assettracking"
      mt={{ base: "40px", md: "80px", lg: "200px" }}
      flexDir={"column"}
      width={"100%"}
    >
      {/* title */}
      <Flex flexDir={"column"} ml={{ base: "6%", md: "6%", lg: "6%" }}>
        <Text
          className="heading"
          color={"#94DAF8"}
          fontSize={{ base: "24px", md: "38px", lg: "45px" }}
        >
          Asset Tracking Platform
        </Text>
        <Image
          mt={"-10px"}
          ml={"-10px"}
          width={"70px"}
          src={Underline}
          alt="underline"
        ></Image>
      </Flex>
      {/* content */}
      <Flex
        flexDir={{ base: "column", md: "column", lg: "row" }}
        mx={"auto"}
        width={{ base: "100%", md: "90%", lg: "80%" }}
        mt={{ base: "10px", md: "40px", lg: "70px" }}
      >
        <Flex
          px={{ base: "20px", md: "30px", lg: "20px" }}
          mt={{ base: "40px", md: "40px", lg: "0px" }}
          width={{ base: "100%", md: "100%", lg: "50%" }}
        >
          <Flex
            mx={"auto"}
            my={"auto"}
            borderRadius={"10px"}
            width={{ base: "400px", md: "500px", lg: "650px" }}
          >
            <Flex
              boxShadow="0px 0px 12.7px 0px #C39FE2"
              mt={{ base: "-10px", md: "-10px", lg: "-10px" }}
              borderRadius={{ base: "40px", md: "50px", lg: "70px" }}
              zIndex={-1}
              border={"1px solid #C39FE2"}
            >
              <Flex>
                <Image src={Assetgif}></Image>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          ml={{ base: "0px", md: "0px", lg: "20px" }}
          p={{ base: "10px", md: "10px", lg: "30px" }}
          mt={{ base: "40px", md: "40px", lg: "0px" }}
          width={{ base: "100%", md: "100%", lg: "50%" }}
        >
          <Flex
            width={"100%"}
            px={"40px"}
            height={"fit-content"}
            mx={"auto"}
            my={"auto"}
            flexDir={"column"}
          >
            {" "}
            <Text
              width={{ base: "100%", md: "100%", lg: "100%" }}
              fontSize={{ base: "14px", md: "14px", lg: "16px" }}
              color={"white"}
              className="content_font"
              fontWeight={"light"}
            >
              This platform enables companies to track their assets across
              different businesses or within the company itself; blockchain
              technology mitigates conflicts and guarantees trust among
              parties.Seamless asset management with our platform, creating
              immutable records at each stage of the asset journey with precise
              timestamps. Say goodbye to conflicts among stakeholders. Perfect
              for companies renting out equipment or transferring assets between
              departments.
            </Text>
            <HashLink smooth to={"/contact-us#contact"}>
              <CommonButton
                width="100px"
                btn="Book a Demo"
                mt={{ base: "30px", md: "40px", lg: "30px" }}
              />
            </HashLink>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default AssetTracking;
