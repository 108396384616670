/** @format */

import { Image, Flex, Text } from "@chakra-ui/react";
import React from "react";
import "./style.css";
import Navbar from "../../components/sections/Navbar";
import Footer from "../../components/sections/Footer";
import Mobilenavbar from "../../components/sections/Mobilenavbar";

import FashionIndustry from "../../components/sections/industrialSolutions/FashionIndustry";
import FoodAndBeveragesIndustry from "../../components/sections/industrialSolutions/FoodAndBeveragesIndustry";
import CarbonCredits from "../../components/sections/industrialSolutions/CarbonCredits";

import Finance from "../../components/sections/industrialSolutions/Finance";
import OilAndGas from "../../components/sections/industrialSolutions/OilAndGas";

import { Helmet } from "react-helmet";

import Aboutusheader from "../../assets/images/IndustrysolutionsPage/blkheader.webp";
import Space from "../../components/sections/industrialSolutions/Space";

import Blknew from "../../assets/images/IndustrysolutionsPage/blknew.gif";
import BlkRing from "../../assets/images/IndustrysolutionsPage/ring.webp";
import ParticleBackground from "../../components/sections/home/ParticleBackground";
import ParticleTrail from "../../components/sections/home/ParticleTrail";
function Productservices(props) {
  return (
    <>
      {/* helmet */}
      <Helmet>
        <title>Blockchain Industry Solutions | TrackGenesis&reg;</title>
        <meta
          name="title"
          content="Top Blockchain Development Company | TrackGenesis®️ | Scotland UK  "
        />
        <meta
          name="description"
          content="We offer blockchain solutions for industries like food & drink, fashion, and oil & gas that transform business from supply chain management to product provenance."
        />
        <link
          href="https://trackgenesis.com/services/blockchain"
          rel="canonical"
          content="max-age: 1209600, no-cache, no-store, must-revalidate"
        />

        {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-197747980-1"
        ></script>
      </Helmet>
      {/* helmet */}

      <Flex
        id="blockchain"
        backgroundColor={"black"}
        flexDirection={"column"}
        overflowX={"hidden"}
      >
        {/* new heading */}
        <Flex>
          <Image
            alt="Blockchain solutions Trackgenesis"
            ml={{ base: "-50px", md: "-40px", lg: "0px" }}
            width={{ base: "270px", md: "400px", lg: "500px" }}
            src={Aboutusheader}
          ></Image>
          <Text
            mt={"auto"}
            className="heading"
            mb={{ base: "15px", md: "15px", lg: "30px" }}
            color={"#94DAF8"}
            fontSize={{ base: "30px", md: "45px", lg: "50px" }}
            ml={{ base: "-26%", md: "-19%", lg: "-10%" }}
          >
            Blockchain
          </Text>
        </Flex>

        <Navbar />
        <Mobilenavbar />

        {/* new content */}

        <ParticleTrail />
        <Flex
          flexDir={"column"}
          position={"relative"}
          className="mainborder"
          mx={{ base: "10px", md: "15px", lg: "30px" }}
          backgroundColor={"rgba(27, 21, 78, 0.38)"}
          backdropFilter="auto"
          backdropBlur="4px"
          pb={"100px"}
        >
          {/* Blockchain intro */}

          <Flex
            my={{ base: "30px", md: "30px", lg: "90px" }}
            // p={"60px"}
            mx={"auto"}
            width={{ base: "80%", md: "80%", lg: "900px" }}
            flexDir={{
              base: "column-reverse",
              md: "column-reverse",
              lg: "row",
            }}
          >
            <Flex
              mt={{ base: "-60px", md: "-50px", lg: "0px" }}
              justifyContent={"center"}
              alignItems={"center"}
              backgroundColor={"rgba(0, 0, 0, 0.15)"}
              borderRadius={"5px"}
              backdropFilter="auto"
              backdropBlur="2px"
              boxShadow={"0px 0px 32.5px -12px rgba(1, 177, 216, 0.45) inset"}
            >
              <Text
                pr={{ base: "20px", md: "30px", lg: "150px" }}
                pl={{ base: "20px", md: "30px", lg: "50px" }}
                pb={{ base: "40px", md: "30px", lg: "30px" }}
                pt={{ base: "90px", md: "90px", lg: "30px" }}
                fontSize={{ base: "14px", md: "14px", lg: "17px" }}
                color={"white"}
                className="content_font"
                fontWeight={"light"}
                maxW={{ base: "600px", md: "600px", lg: "800px" }}
              >
                No matter which industry you belong to, blockchain transforms
                your business retail, from supply chain and inventory management
                to product provenance. Transparent and tamper-proof records
                created by blockchain enable businesses to share and access the
                records of each supply chain participant in real-time, and
                customers can trust their choice of products.
              </Text>
            </Flex>

            <Image
              mr={{ base: "auto", md: "auto", lg: "0px" }}
              alt="Blockchain Blocks"
              ml={{ base: "auto", md: "auto", lg: "-150px" }}
              zIndex={2}
              width={{ base: "180px", md: "180px", lg: "280px" }}
              objectFit={"contain"}
              src={Blknew}
            ></Image>
            <Image
              alt="Blockchain Trackgenesis"
              width={{ base: "210px", md: "210px", lg: "330px" }}
              objectFit={"contain"}
              zIndex={1}
              mr={{ base: "auto", md: "auto", lg: "0px" }}
              mb={{ base: "-180px", md: "-180px", lg: "0px" }}
              ml={{ base: "auto", md: "auto", lg: "-300px" }}
              src={BlkRing}
            ></Image>
          </Flex>

          {/* Blockchain in space */}

          <Space />

          {/* blk in fashion */}
          <FashionIndustry />

          {/* Blockchain in food and drink */}

          <FoodAndBeveragesIndustry />

          {/* carbon Trading */}
          <CarbonCredits />

          {/* oil and gas */}

          <OilAndGas />
          {/* finance */}
          <Finance />
        </Flex>

        <ParticleBackground />
        <Footer />
      </Flex>
    </>
  );
}

export default Productservices;
