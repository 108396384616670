import { Box, Button, Flex, Heading, Image, Text } from "@chakra-ui/react";
import React from "react";
import { HashLink } from "react-router-hash-link";
import CustomImage from "../../../assets/images/SoftwarePage/software4.webp";
import CustomImageSmall from "../../../assets/images/SoftwarePage/software4.webp";

import CommonButton from "../../../components/CommonButton.js";
import Underline from "../../../assets/images/HomePage/underline.webp";
function CustomWebsite() {
  return (
    // new code
    <Flex mt={{ base: "20px", md: "80px", lg: "200px" }} width={"100%"}>
      {/* title */}
      <Flex mx={"auto"} flexDir={{ base: "column", md: "row", lg: "row" }}>
        <Flex p={"20px"} width={{ base: "100%", md: "60%", lg: "50%" }}>
          <Image
     
            objectFit={"contain"}
            srcSet={`${CustomImageSmall} 1000w, ${CustomImage} 2000w`}
            width={"100%"}
            alt="Software Development Custom Website"
            pointerEvents={"none"}
          />
        </Flex>

        <Flex
          width={{ base: "100%", md: "60%", lg: "50%" }}
          flexDir={"column"}
          ml={{ base: "6%", md: "6%", lg: "10%" }}
        >
          <Text
            className="heading"
            color={"#94DAF8"}
            fontSize={{ base: "24px", md: "38px", lg: "45px" }}
            width={{ base: "330px", md: "330px", lg: "400px" }}
          >
            Custom Software Development
          </Text>
          <Image
            mt={"-10px"}
            ml={"-10px"}
            width={"70px"}
            src={Underline}  alt="underline"
          ></Image>

          <Text
            mt={{ base: "30px", md: "40px", lg: "80px" }}
            maxW={{ base: "300px", md: "380px", lg: "650px" }}
            fontSize={{ base: "14px", md: "14px", lg: "16px" }}
            color={"white"}
            className="content_font"
            fontWeight={"light"}
            pr={"20px"}
          >
            We build enterprise software to accelerate your business by
            organizing your tasks and easily integrating different applications
            involved in your business into your system.
          </Text>
          <HashLink smooth to={"/contact-us#contact"}>
          <CommonButton
          width='100px'
            btn="Book a Demo"
            mt={{ base: "30px", md: "60px", lg: "30px" }}
          /></HashLink>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default CustomWebsite;
