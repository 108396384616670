import { DrawerHeader, Flex, Image, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import images from "../../database/images.json";
import { NavLink } from "react-router-dom";
import { MenuItem, Menu } from "@chakra-ui/react";

import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Button,
} from "@chakra-ui/react";
import { HashLink } from "react-router-hash-link";

function Mobilenavbar(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [openIndex, setOpenIndex] = useState(null);

  const handleBoxClick = (index) => {
    setOpenIndex(index === openIndex ? null : index);
  };
  const btnRef = React.useRef();
  return (
    
    // new Navbar
    <Flex
      display={{ base: "block", md: "block", lg: "none" }}
      zIndex={2}
      pt={5}
      position={"absolute"}
      color={"white"}
    >
      {/* hamberger icon */}
      <Button
        ml={{ base: "80vw", md: "90vw", lg: "0vw" }}
        style={{ outline: "none" }}
        ref={btnRef}
        backgroundColor={"rgba(11,9,30,0.7)"}
        onClick={onOpen}
        aria-label="mobilenavbar hamberger"
      >
        <HiOutlineMenuAlt3 />
      </Button>

      {/* opening drawer */}
      <Drawer
        color={"white"}
        isOpen={isOpen}
        placement="right"
        size="full"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent
          backdropFilter="auto"
          backdropBlur="8px"
          bg={"rgba(11,9,30,0.2)"}
        >
          <DrawerCloseButton color={"white"} bg={"rgba(100,100,100,0.5)"} />
          <DrawerHeader
            bg={"rgba(11,9,30,0.2)"}
            borderBottomWidth="4px"
            borderColor={"#01B1D8"}
          >
            <Image ml={"auto"} mr={"auto"} src={images.tglogo} />
          </DrawerHeader>

          <DrawerBody color={"whiteAlpha.900"}>
            <Menu className="heading">
              {/* home */}
              <NavLink
                to="/home"
                _hover={{
                  textDecoration: "none",
                }}
              >
                <MenuItem
                  borderBottomWidth="1px"
                  borderColor={"rgba(250,250,250,0.8)"}
                  justifyContent={"center"}
                  p={4}
                  _hover={{ bg: "#065D7B" }}
                >
                  Home
                </MenuItem>
              </NavLink>
              {/* about us */}
              <NavLink
                to="/about-us"
                _hover={{
                  textDecoration: "none",
                }}
              >
                <MenuItem
                  borderBottomWidth="1px"
                  borderColor={"rgba(250,250,250,0.8)"}
                  justifyContent={"center"}
                  p={4}
                  _hover={{ bg: "#065D7B" }}
                >
                  About Us
                </MenuItem>
              </NavLink>
              {/* Services */}
              {/* <NavLink
                to="/services/blockchain"
                _hover={{
                  textDecoration: "none",
                }}
              > */}
                <MenuItem
                 onClick={() => handleBoxClick(1)}
                  borderBottomWidth="1px"
                  borderColor={"rgba(250,250,250,0.8)"}
                  justifyContent={"center"}
                  p={4}
                  _hover={{ bg: "#065D7B" }}
                >
                  Services
                </MenuItem>
              {/* </NavLink> */}

              {/* Blockchain */}
              <HashLink
               style={{
                display: 1 === openIndex ? "block" : "none",
                transition: "display 0.1s linear",
              }}
                smooth
                _hover={{
                  textDecoration: "none",
                }}
                to="/services/blockchain"
              >
                <MenuItem  fontSize={"12px"} _hover={{ color: "#01B1D8" }}>
                <Text mx={"auto"}> Blockchain</Text>
                </MenuItem>
              </HashLink>

              {/* Extended Reality */}
              <HashLink
               style={{
                display: 1 === openIndex ? "block" : "none",
                transition: "display 0.1s linear",
              }}
                smooth
                _hover={{
                  textDecoration: "none",
                }}
                to="/services/ar-vr"
              >
                <MenuItem fontSize={"12px"} _hover={{ color: "#01B1D8" }}>
                <Text mx={"auto"}>Extended Reality</Text>
                </MenuItem>
              </HashLink>

              {/* NFT */}
              <HashLink
               style={{
                display: 1 === openIndex ? "block" : "none",
                transition: "display 0.1s linear",
              }}
                smooth
                _hover={{
                  textDecoration: "none",
                }}
                to="/services/nft-platform"
              >
                <MenuItem fontSize={"12px"} _hover={{ color: "#01B1D8" }}>
                <Text mx={"auto"}>NFT</Text>
                </MenuItem>
              </HashLink>

              {/* Software Solutions */}
              <HashLink
               style={{
                display: 1 === openIndex ? "block" : "none",
                transition: "display 0.1s linear",
              }}
                smooth
                _hover={{
                  textDecoration: "none",
                }}
                to="/services/software-development"
              >
                <MenuItem borderBottomWidth="1px" fontSize={"12px"} _hover={{ color: "#01B1D8" }}>
                  <Text mx={"auto"}>Software Solutions</Text>
                </MenuItem>
              </HashLink>

              {/* Products */}
              <NavLink
                to="/products"
                _hover={{
                  textDecoration: "none",
                }}
              >
                <MenuItem
                  borderBottomWidth="1px"
                  borderColor={"rgba(250,250,250,0.8)"}
                  justifyContent={"center"}
                  p={4}
                  _hover={{ bg: "#065D7B" }}
                >
                  Products
                </MenuItem>
              </NavLink>
              {/* Media */}
              <NavLink
                to="/media-newsroom"
                _hover={{
                  textDecoration: "none",
                }}
              >
                <MenuItem
                  borderBottomWidth="1px"
                  borderColor={"rgba(250,250,250,0.8)"}
                  justifyContent={"center"}
                  p={4}
                  _hover={{ bg: "#065D7B" }}
                >
                  Media
                </MenuItem>
              </NavLink>
              {/* Contact us */}
              <NavLink
                to="/contact-us"
                _hover={{
                  textDecoration: "none",
                }}
              >
                <MenuItem
                  borderBottomWidth="1px"
                  borderColor={"rgba(250,250,250,0.8)"}
                  justifyContent={"center"}
                  p={4}
                  _hover={{ bg: "#065D7B" }}
                >
                  Contact Us
                </MenuItem>
              </NavLink>
              {/* Blogs */}
              <NavLink
                to="/blogs"
                _hover={{
                  textDecoration: "none",
                }}
              >
                <MenuItem
                  borderBottomWidth="1px"
                  borderColor={"rgba(250,250,250,0.8)"}
                  justifyContent={"center"}
                  p={4}
                  _hover={{ bg: "#065D7B" }}
                >
                  Blogs
                </MenuItem>
              </NavLink>
              {/* Case Study */}
              <NavLink
                to="/case-study"
                _hover={{
                  textDecoration: "none",
                }}
              >
                <MenuItem
                  borderBottomWidth="1px"
                  borderColor={"rgba(250,250,250,0.8)"}
                  justifyContent={"center"}
                  p={4}
                  _hover={{ bg: "#065D7B" }}
                >
                  Client Stories
                </MenuItem>
              </NavLink>
            </Menu>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
    // .........
  );
}

export default Mobilenavbar;
