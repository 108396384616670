import {  Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import { HashLink } from "react-router-hash-link";

import BlockchainInFashion from "../../../assets/images/IndustrysolutionsPage/blockchain-in-fashion.webp";
import CommonButton from "../../../components/CommonButton.js";
import Underline from "../../../assets/images/HomePage/underline.webp";

function FashionIndustry() {
  return (
    // new code

    <Flex mt={{ base: "20px", md: "80px", lg: "200px" }} width={"100%"}>
      {/* title */}
      <Flex mx={"auto"} flexDir={{ base: "column", md: "row", lg: "row" }}>
        <Flex p={"20px"} width={{ base: "100%", md: "60%", lg: "50%" }}>
          <Image
            my={{ base: "30px", md: "0px", lg: "0px" }}
            objectFit={"contain"}
            // srcSet={`${BlockchainInFashionSmall} 1000w, ${BlockchainInFashion} 2000w`}
            src={BlockchainInFashion}
            width={"100%"}
            alt="Future of Fashion technology blockchain"
            pointerEvents={"none"}
          />
        </Flex>

        <Flex
          width={{ base: "100%", md: "60%", lg: "50%" }}
          flexDir={"column"}
          ml={{ base: "6%", md: "6%", lg: "10%" }}
        >
          <Text
            className="heading"
            color={"#94DAF8"}
            fontSize={{ base: "24px", md: "38px", lg: "45px" }}
          >
            Blockchain in Fashion
          </Text>
          <Image
            mt={"-10px"}
            ml={"-10px"}
            width={"70px"}
            src={Underline}  alt="underline"
          ></Image>

          <Text
            mt={{ base: "30px", md: "40px", lg: "80px" }}
            pr={{ base: "40px", md: "30px", lg: "30px" }}
            maxW={{ base: "", md: "380px", lg: "650px" }}
            fontSize={{ base: "14px", md: "14px", lg: "16px" }}
            color={"white"}
            className="content_font"
            fontWeight={"light"}
          >
            Nowadays, every business claims that it's sustainable. Are they
            truly sustainable? Looking into the fashion industry, companies are
            greenwashing to meet the demands of sustainable products. There is
            no way to verify the initiatives of a business. Blockchain can
            tackle greenwashing in retail. In addition to this, blockchain
            accounts for data with greater transparency in the apparel supply
            chain, which is otherwise impossible with a traditional database.
          </Text>
          <HashLink smooth to={"/contact-us#contact"}>
            <CommonButton
            width="100px"
              btn="Book a Demo"
              mt={{ base: "30px", md: "60px", lg: "30px" }}
            />
          </HashLink>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default FashionIndustry;
