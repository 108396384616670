import { Box, Flex, Image, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { HashLink } from "react-router-hash-link";

import FireVideo from "../../../assets/images/CasestudyPage/fire.mp4";
import CommonButton from "../../../components/CommonButton.js";
import Underline from "../../../assets/images/HomePage/underline.webp";
import Ipad from "../../../assets/images/CasestudyPage/ipad.webp";
import { VscMute, VscUnmute } from "react-icons/vsc";
function FireProject() {
  const [mute, setMute] = useState(true);
  return (
    <Flex id="fire" my={{ base: "30px", md: "40px", lg: "100px" }} flexDir={"column"}>
      {/* heading */}
      <Flex my={{ base: "30px", md: "10px", lg: "10px" }} mx={"auto"}>
        <Image
          alt="underline"
          mt={{ base: "6px", lg: "15px" }}
          height={"40px"}
          width={"70px"}
          src={Underline} 
        ></Image>
        <Text
          className="heading"
          color={"#94DAF8"}
          fontSize={{ base: "24px", md: "38px", lg: "45px" }}
        >
          VR Fire & Safety App
        </Text>
        <Image
          alt="underline"
          mt={{ base: "6px", lg: "15px" }}
          height={"40px"}
          width={"70px"}
          src={Underline}  
        ></Image>
      </Flex>

      {/* Content */}
      <Flex mt={{ base: "50px", md: "70px", lg: "150px" }} width={"100%"}>
        <Flex
          flexDir={{ base: "column", md: "row", lg: "row" }}
          width={{ base: "90%", md: "95%", lg: "70%" }}
          mx={"auto"}
          height={{ base: "fit-content", md: "fit-content", lg: "fit-content" }}
          backgroundColor={"#0B091E"}
          borderRadius={"5px"}
          boxShadow={"0px 0px 6.7px 0px #01B1D8"}
        >
          <Flex width={{ base: "100%", md: "55%", lg: "55%" }}>
            <Flex
              px={{ base: "30px", md: "30px", lg: "50px" }}
              py={{ base: "", md: "30px", lg: "50px" }}
              //  mr={{base:"auto",md:"8%",lg:"13%"}}
              my={{ base: "30px", md: "auto", lg: "auto" }}
              mx={"auto"}
              flexDir={"column"}
            >
              <Text
                 fontSize={{ base: "14px", md: "14px", lg: "16px" }}
                color={"white"}
                className="content_font"
                fontWeight={"light"}
                // width={{ base: "270px", md: "270px", lg: "430px" }}
              >
                We built a VR application for fire and safety training in the
                office. The VR application walks us through the safety
                precautions necessary in hazardous environments and how to
                extinguish a fire in an emergency. Controllers of VR headset
                assist us in moving around the workplace space, grabbing the
                appropriate co2 extinguisher, and taking the right course of
                action to put out the fire. Our VR app is compatible with Meta
                Quest 2 and 3. We can upgrade the application to work with the
                latest VR devices. Our app is highly adaptable to any setting,
                allowing employees to train for scenarios that are most relevant
                to their everyday job, resulting in a more targeted and
                successful safety training program.
              </Text>
              <HashLink smooth to={"/contact-us#contact"}>
                <CommonButton width="100px" btn="Book a Demo" mt="20px" />
              </HashLink>
            </Flex>
          </Flex>

          <Flex width={{ base: "100%", md: "45%", lg: "45%" }}>
            <Image
              display={{ base: "none", md: "block", lg: "block" }}
              ml={{ base: "auto", md: "2%", lg: "2%" }}
              mr={{ base: "auto", md: "0", lg: "0" }}
              mt={{ base: "30px", md: "-30px", lg: "-30px" }}
              alt="success stories of TrackGeneis clients"
              position={{ base: "absolute", md: "absolute", lg: "absolute" }}
              objectFit={"contain"}
              width={{ base: "200px", md: "200px", lg: "260px" }}
              src={Ipad}
              zIndex={1}
            ></Image>
            <Flex p={{ base: "10px", md: "10px", lg: "40px" }}>
              <Flex
                mr={{ base: "auto", md: "10px", lg: "10px" }}
                zIndex={1}
                my={"auto"}
                height={"fit-content"}
                borderRadius={"10px"}
                border={"2px solid #AABACC"}
              >
                <Flex
                  my={"auto"}
                  height={"fit-content"}
                  borderRadius={"10px"}
                  border={"15px solid black"}
                >
                  <video
                    playsInline
                    className="videobox"
                    src={FireVideo}
                    style={{
                      borderRadius: "0px",
                      objectFit: "contain",
                      width: "100%",
                    }}
                    autoPlay={true}
                    muted={mute}
                    controls={false}
                    loop
                  />
                </Flex>
              </Flex>
            </Flex>

            <Box
              backgroundColor={"black"}
              position={"absolute"}
              p={2}
              height={"fit-content"}
              width={"fit-content"}
              ml={{ base: "0", md: "0px", lg: "50px" }}
              mt={{ base: "3%", md: "8%", lg: "3%" }}
              borderRadius={"50%"}
              zIndex={1}
            >
              {mute ? (
                <VscMute
                  color="white"
                  cursor={"pointer"}
                  size={"20"}
                  onClick={() => {
                    setMute(!mute);
                  }}
                />
              ) : (
                <VscUnmute
                  color="white"
                  cursor={"pointer"}
                  size="20"
                  onClick={() => {
                    setMute(!mute);
                  }}
                />
              )}
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default FireProject;
