import {  Flex, Heading, Image, Text } from "@chakra-ui/react";
import React from "react";
import Mobilenavbar from "../../../components/sections/Mobilenavbar";
import Navbar from "../../../components/sections/Navbar";
import Footer from "../../../components/sections/Footer";

import sherinblog from "../../../assets/images/BlogsPage/sherinprofile.webp";
import softmain from "../../../assets/images/BlogsPage/softmainblog.webp";

import { Helmet } from "react-helmet";

function BlogSoftware(props) {
  return (
    <>
      {/* helmet */}
      <Helmet>
        <title>
          Blogs | Cryptocurrency | TrackGenesis&reg; | Aberdeen Scotland
        </title>
        <meta
          name="title"
          content="Blogs | PalmOil | TrackGenesis | Aberdeen Scotland"
        />
        <meta
          name="description"
          content="Blockchain Technology for Palm Oil Traceability"
        />
        <link
          href="https://trackgenesis.com/blogs"
          rel="canonical"
          content="max-age: 1209600, no-cache, no-store, must-revalidate"
        />
      </Helmet>
      {/* helmet */}
      <Flex
        backgroundColor={"black"}
        flexDirection={"column"}
        id="Software-Trackgenesis"
      >
        {/* heading Section */}
        <Flex
          backgroundColor={"black"}
          width="100%"
          flexDirection={"column"}
          alignItems="center"
          justifyContent={"center"}
          height={"400px"}
        >
          <Heading
            className="heading"
            color={"#94DAF8"}
            fontSize={{ md: "33px", base: "25px", lg: "37px" }}
            fontWeight="bold"
            textShadow="1px 1px #000000"
            mt={{ base: "36px", md: "35px", lg: "10px" }}
            textAlign="center"
            maxWidth={{ base: "80%", md: "65%", lg: "60%" }}
          >
            Choosing the Right Software Development Company: A Guide for
            Businesses
          </Heading>
        </Flex>

        <Navbar />
        <Mobilenavbar />

        <Flex
          backgroundColor={"black"}
          alignItems={"center"}
          justifyContent="center"
          width="100%"
        >
          <Image
            mt={{ base: "-90px", md: "-90px", lg: "-110px" }}
            ml={{ base: "-5%", lg: "-5%" }}
            width={{ base: "91%", md: "80%", lg: "70%" }}
            src={softmain}
          />
        </Flex>

        <Flex
          flexDir={"column"}
          position={"relative"}
          className="mainborder"
          my={{ base: "2%", md: "2%", lg: "2%" }}
          mx={{ base: "10px", md: "15px", lg: "30px" }}
          backgroundColor={"rgba(27, 21, 78, 0.38)"}
          backdropFilter="auto"
          backdropBlur="8px"
          pb={"100px"}
        >
          <Flex flexDir={"column"}>
            <Flex
              color={"white"}
              className="content_font"
              width={"100%"}
              px={{ base: "10%", md: "15%", lg: "20%" }}
              fontSize={{ base: "15px", md: "16px", lg: "17px" }}
              flexDirection="column"
            >
              <Text
                mt={"30px"}
                className="content_font"
                fontWeight={"bold"}
                fontStyle={"italic"}
                // color="rgba(42, 50, 73, 1)"
                fontSize={"20px"}
              >
                Wrong Software Development Partner = Wasted Time, Money, and
                Damage to Your Company's Reputation
              </Text>
              <Text mt={"30px"} className="content_font">
                Your customers are having a horrible online experience. Your
                employees are frustrated with the poor performance of your
                software. Will your business flourish?
              </Text>

              <Text mt={"30px"} className="content_font">
                Choosing the correct software development partner is critical to
                the seamless operation of your business. Here are the factors to
                consider while finding a software development partner.
              </Text>
              <Text
                mt={"30px"}
                className="content_font"
                fontWeight={"bold"}
                // color="rgba(42, 50, 73, 1)"
                fontSize={"20px"}
              >
                Communication & Transparency
              </Text>
              <Text mt={"30px"} className="content_font">
                Your software partner should be available to listen to your
                requirements, build custom software that fit your needs and
                deliver it on time. They should be transparent and not hesitate
                to give updates and take feedback at proper times.
              </Text>

              <Text
                mt={"30px"}
                className="content_font"
                fontWeight={"bold"}
                // color="rgba(42, 50, 73, 1)"
                fontSize={"20px"}
              >
                Local business
              </Text>
              <Text mt={"30px"} className="content_font">
                Always choose a business partner from your region. This makes it
                easier to approach them in person for in-depth discussions and
                project presentations.
              </Text>
              <Text mt={"30px"} className="content_font">
                Partnering with a local software development company helps
                eliminates uncertainty caused by time zone differences.
              </Text>

              <Text
                mt={"30px"}
                className="content_font"
                fontWeight={"bold"}
                // color="rgba(42, 50, 73, 1)"
                fontSize={"20px"}
              >
                Cost-effective
              </Text>
              <Text mt={"30px"} className="content_font">
                Don't go for the cheapest option, quality matters. Compromising
                quality for low-cost can result in a considerably greater loss
                in the long term. Make sure your software development partner
                delivers high-quality service at reasonable price.
              </Text>

              <Text
                mt={"30px"}
                className="content_font"
                fontWeight={"bold"}
                // color="rgba(42, 50, 73, 1)"
                fontSize={"20px"}
              >
                Technical Support
              </Text>
              <Text mt={"30px"} className="content_font">
                Full-term technical support from your software developer is
                required while developing enterprise software. It is preferable
                to include a related clause in the work contract.
              </Text>

              <Text
                mt={"30px"}
                className="content_font"
                fontWeight={"bold"}
                // color="rgba(42, 50, 73, 1)"
                fontSize={"20px"}
              >
                Expertise and Reviews
              </Text>
              <Text mt={"30px"} className="content_font">
                Choose a partner having enough experience and a decent track
                record. Check the reviews and learn the case studies to discover
                if they've worked on similar projects and have the expertise to
                take on yours.
              </Text>

              <Text
                mt={"30px"}
                className="content_font"
                fontWeight={"bold"}
                // color="rgba(42, 50, 73, 1)"
                fontSize={"20px"}
              >
                Agile Software Development
              </Text>
              <Text mt={"30px"} className="content_font">
                Prefer agile software development, which divides projects into
                smaller components and develops them one at a time to polish the
                final output.
              </Text>

              <Text
                mt={"30px"}
                className="content_font"
                fontWeight={"bold"}
                // color="rgba(42, 50, 73, 1)"
                fontSize={"20px"}
              >
                Testing and Quality Assurance
              </Text>
              <Text mt={"30px"} className="content_font">
                Understand their quality criteria to provide a bug-free,
                reliable software solution.
              </Text>

              <Text
                mt={"30px"}
                className="content_font"
                fontWeight={"bold"}
                // color="rgba(42, 50, 73, 1)"
                fontSize={"20px"}
              >
                Partnership Factor
              </Text>
              <Text mt={"30px"} className="content_font">
                The values and vision of your partner company should be aligned
                with your own. The right partner has long-term goals for your
                success. They provide you with technical ideas and software
                upgrades at every level of your development to achieve optimal
                efficiency.
              </Text>

              <Text
                mt={"30px"}
                className="content_font"
                fontWeight={"bold"}
                // color="rgba(42, 50, 73, 1)"
                fontSize={"20px"}
              >
                Flexible and adaptable
              </Text>
              <Text mt={"30px"} className="content_font">
                Choose a software development partner who never turns away from
                challenges. They should be skilled enough to resolve the issues
                voiced by users.
              </Text>
              <Text mt={"30px"} className="content_font">
                Your technology partner should be up to date on the latest
                technological breakthroughs and capable of making your program
                compatible with every new changes.
              </Text>
              <Text mt={"30px"} className="content_font">
                Partnering with the right software development company is not an
                expense, but rather an investment in a company's long-term
                success. According to Gartner, a staggering 70% of IT projects
                underperform or fall short of expectations. With these
                recommendations and insights, you'll significantly boost your
                chances of finding a partner who will transform your idea into
                exceptional software that empowers your business.
              </Text>
            </Flex>
            <Flex my={"20px"} width={"100%"}>
              <Flex ml={{ base: "40%", md: "65%", lg: "65%" }}>
                <Text
                  color={"white"}
                  className="content_font"
                  fontSize={{ md: "23px", base: "15px", lg: "25px" }}
                >
                  - by Sherin Thomas
                </Text>
                <Image
                  objectFit={"contain"}
                  ml={{ base: "10px", md: "10px", lg: "30px" }}
                  mt={"-10px"}
                  width={{ base: "30px", md: "40px", lg: "55px" }}
                  src={sherinblog}
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Footer />
      </Flex>
    </>
  );
}

export default BlogSoftware;
