/** @format */

import { Image, Flex, Text, Box } from "@chakra-ui/react";
import React from "react";
import "./style.css";
import Navbar from "../../components/sections/Navbar";
import Footer from "../../components/sections/Footer";
import Mobilenavbar from "../../components/sections/Mobilenavbar";
import Supplychain from "../../assets/images/ProductsPage/supplychain.webp";
import Baaas from "../../assets/images/ProductsPage/baas.webp";
import NFT from "../../assets/images/ProductsPage/nft.webp";
import Data from "../../assets/images/ProductsPage/data.webp";
import Cert from "../../assets/images/ProductsPage/certicheck.webp";
import TGBook from "../../assets/images/ProductsPage/arbook.webp";
import Asset from "../../assets/images/ProductsPage/asset.webp";
import Doc from "../../assets/images/ProductsPage/doc.webp";
import { Helmet } from "react-helmet";

import Baas from "../../components/sections/Products/Baas";

import Productsheader from "../../assets/images/ProductsPage/productsheadder.webp";

import NftPlatform from "../../components/sections/Products/NftPlatform";
import DataSharingPlatform from "../../components/sections/Products/DataSharingPlatform";
import TGCerticheck from "../../components/sections/Products/TGCerticheck";
import ParticleBackground from "../../components/sections/home/ParticleBackground";
import ParticleTrail from "../../components/sections/home/ParticleTrail";

import { HashLink } from "react-router-hash-link";
import SupplyChain from "../../components/sections/Products/SupplyChain";
import TokenDistribution from "../../components/sections/Products/TokenDistribution";
import AssetTracking from "../../components/sections/Products/AssetTracking";
import DocumentVerification from "../../components/sections/Products/DocumentVerification";
function Products(props) {
  return (
    <>
      {/* helmet */}
      <Helmet>
        <title>
          Blockchain & NFT Platforms for Business | TrackGenesis®️ | Scotland UK
        </title>
        <meta
          name="title"
          content="Blockchain & NFT Platforms for Business | TrackGenesis®️ | Scotland UK "
        />
        <meta
          name="description"
          content="Discover more about our plug-and-play blockchain and NFT products and platforms designed for enterprises, especially for those without in-house blockchain expertise."
        />
        <link
          href="https://trackgenesis.com/products"
          rel="canonical"
          content="max-age: 1209600, no-cache, no-store, must-revalidate"
        />

        {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-197747980-1"
        ></script>
      </Helmet>
      {/* helmet */}

      <Flex
        id="productsection"
        flexDirection={"column"}
        backgroundColor={"black"}
        overflowX={"hidden"}
      >
        {/* new heading Section */}
        <Flex>
          <Image
            ml={{ base: "-50px", md: "-40px", lg: "0px" }}
            width={{ base: "270px", md: "400px", lg: "500px" }}
            src={Productsheader}
            alt="Products of TrackGenesis"
          ></Image>
          <Text
            mt={"auto"}
            className="heading"
            mb={{ base: "15px", md: "15px", lg: "30px" }}
            color={"#94DAF8"}
            fontSize={{ base: "30px", md: "45px", lg: "50px" }}
            ml={{ base: "-29%", md: "-19%", lg: "-10%" }}
          >
            Products
          </Text>
        </Flex>

        <Navbar />
        <Mobilenavbar />

        {/* new content */}

        <ParticleTrail />
        <Flex
          flexDir={"column"}
          position={"relative"}
          className="mainborder"
          mx={{ base: "10px", md: "15px", lg: "30px" }}
          backgroundColor={"rgba(27, 21, 78, 0.38)"}
          backdropFilter="auto"
          backdropBlur="4px"
          pb={"100px"}
        >
          {/* Products intro */}
          <Flex width={"100%"} flexDir={"column"}>
            <Flex
              my={{ base: "50px", md: "50px", lg: "90px" }}
              mx={"auto"}
              flexDir={"column"}
            >
              <Text
                textAlign={"center"}
                className="heading"
                color={"#94DAF8"}
                fontSize={{ base: "24px", md: "38px", lg: "45px" }}
              >
                Web3SandPit<sup>&reg;</sup>
              </Text>
              <Text
                mt={"10px"}
                textAlign={"center"}
                fontSize={{ base: "14px", md: "14px", lg: "17px" }}
                color={"white"}
                className="content_font"
                fontWeight={"light"}
                width={{ base: "300px", md: "350px", lg: "550px" }}
              >
                {/* We have wide range of products to empower your business in the
                technological race without hiring any experts. Our products can
                be easily integrated with your current system and ensure smooth
                running of business with minimum human intervention.{" "} */}
                An all-inclusive platform offers businesses seamless entry into
                the web3 realm, providing eight user-friendly products tailored
                to meet diverse blockchain needs.
              </Text>
            </Flex>
            <Flex
              className="animate__animated animate__slow animate__zoomIn"
              mt={{ base: "30px", md: "30px", lg: "0px" }}
              flexDir={"column"}
            >
              {/* first row */}
              <Flex
                mx={"auto"}
                width={{ base: "95%", md: "95%", lg: "80%" }}
                justifyContent={"center"}
              >
                {/* Baas */}
                <HashLink smooth to={"/products#baas"}>
                  <Box p={{ base: "10px", md: "10px", lg: "20px" }}>
                    {" "}
                    <Flex
                      width={{ base: "100px", md: "180px", lg: "250px" }}
                      height={{ base: "110px", md: "100px", lg: "130px" }}
                      flexDir={"column"}
                      p={"20px"}
                      backgroundColor={"rgba(0, 0, 0, 0.15)"}
                      borderRadius={"5px"}
                      backdropFilter="auto"
                      backdropBlur="2px"
                      boxShadow={
                        "0px 0px 32.5px -12px rgba(1, 177, 216, 0.45) inset"
                      }
                    >
                      <Image
                        alt="Products of TrackGenesis"
                        mx={"auto"}
                        mt={{ base: "-50px", md: "-90px", lg: "-90px" }}
                        width={{ base: "100px", md: "130px", lg: "150px" }}
                        src={Baaas}
                      ></Image>
                      <Text
                        mt={{ base: "0", md: "-15px", lg: "-15px" }}
                        textAlign={"center"}
                        mx={"auto"}
                        fontSize={{ base: "11px", md: "11px", lg: "15px" }}
                        color={"white"}
                        className="content_font"
                        fontWeight={"light"}
                      >
                        Managed Blockchain as a Service Platform
                      </Text>
                    </Flex>
                  </Box>
                </HashLink>
                {/* NFT Plug & Play System */}
                <HashLink smooth to={"/products#nftplatform"}>
                  <Box p={{ base: "10px", md: "10px", lg: "20px" }}>
                    {" "}
                    <Flex
                      width={{ base: "100px", md: "180px", lg: "250px" }}
                      height={{ base: "110px", md: "100px", lg: "130px" }}
                      flexDir={"column"}
                      p={"20px"}
                      backgroundColor={"rgba(0, 0, 0, 0.15)"}
                      borderRadius={"5px"}
                      backdropFilter="auto"
                      backdropBlur="2px"
                      boxShadow={
                        "0px 0px 32.5px -12px rgba(1, 177, 216, 0.45) inset"
                      }
                    >
                      <Image
                        alt="Products of TrackGenesis"
                        mx={"auto"}
                        mt={{ base: "-50px", md: "-90px", lg: "-90px" }}
                        width={{ base: "100px", md: "130px", lg: "150px" }}
                        src={NFT}
                      ></Image>
                      <Text
                        textAlign="center"
                        mx={"auto"}
                        mt={{ base: "0", md: "-15px", lg: "-15px" }}
                        fontSize={{ base: "11px", md: "11px", lg: "15px" }}
                        color={"white"}
                        className="content_font"
                        fontWeight={"light"}
                      >
                        NFT Plug & Play <br /> System
                      </Text>
                    </Flex>
                  </Box>
                </HashLink>
                {/* TG Data Sharing Platform */}
                <Flex display={{ base: "none", md: "none", lg: "block" }}>
                  <HashLink smooth to={"/products#datasharingplatform"}>
                    <Box p={{ base: "10px", md: "10px", lg: "20px" }}>
                      {" "}
                      <Flex
                        width={{ base: "100px", md: "180px", lg: "250px" }}
                        height={{ base: "110px", md: "100px", lg: "130px" }}
                        flexDir={"column"}
                        p={"20px"}
                        backgroundColor={"rgba(0, 0, 0, 0.15)"}
                        borderRadius={"5px"}
                        backdropFilter="auto"
                        backdropBlur="2px"
                        boxShadow={
                          "0px 0px 32.5px -12px rgba(1, 177, 216, 0.45) inset"
                        }
                      >
                        <Image
                          alt="Products of TrackGenesis"
                          mx={"auto"}
                          mt={{ base: "-50px", md: "-90px", lg: "-90px" }}
                          width={{ base: "100px", md: "130px", lg: "150px" }}
                          src={Data}
                        ></Image>
                        <Text
                          mt={{ base: "0", md: "-15px", lg: "-15px" }}
                          textAlign="center"
                          mx={"auto"}
                          fontSize={{ base: "11px", md: "11px", lg: "15px" }}
                          color={"white"}
                          className="content_font"
                          fontWeight={"light"}
                        >
                          TG Data Sharing <br /> Platform
                        </Text>
                      </Flex>
                    </Box>
                  </HashLink>
                </Flex>

                {/* certicheck */}
                <Flex display={{ base: "none", md: "none", lg: "block" }}>
                  <HashLink smooth to={"/products#tgcerticheck"}>
                    <Box p={{ base: "10px", md: "10px", lg: "20px" }}>
                      {" "}
                      <Flex
                        width={{ base: "100px", md: "180px", lg: "250px" }}
                        height={{ base: "110px", md: "100px", lg: "130px" }}
                        flexDir={"column"}
                        p={"20px"}
                        backgroundColor={"rgba(0, 0, 0, 0.15)"}
                        borderRadius={"5px"}
                        backdropFilter="auto"
                        backdropBlur="2px"
                        boxShadow={
                          "0px 0px 32.5px -12px rgba(1, 177, 216, 0.45) inset"
                        }
                      >
                        <Image
                          alt="Products of TrackGenesis"
                          mx={"auto"}
                          mt={{ base: "-50px", md: "-90px", lg: "-90px" }}
                          width={{ base: "100px", md: "130px", lg: "150px" }}
                          src={Cert}
                        ></Image>
                        <Text
                          mt={{ base: "0", md: "-15px", lg: "-15px" }}
                          textAlign="center"
                          mx={"auto"}
                          fontSize={{ base: "11px", md: "11px", lg: "15px" }}
                          color={"white"}
                          className="content_font"
                          fontWeight={"light"}
                        >
                          TG-Certicheck
                        </Text>
                      </Flex>
                    </Box>
                  </HashLink>
                </Flex>
              </Flex>
              {/* second row */}
              <Flex
                mt={{ base: "20px", md: "20px", lg: "20px" }}
                mx={"auto"}
                width={"80%"}
                justifyContent={"center"}
              >
                {/* Supply chain Tracebility Platform */}
                <HashLink smooth to={"/products#supplychain"}>
                  <Box p={{ base: "10px", md: "10px", lg: "20px" }}>
                    {" "}
                    <Flex
                      width={{ base: "100px", md: "180px", lg: "250px" }}
                      height={{ base: "110px", md: "100px", lg: "130px" }}
                      flexDir={"column"}
                      p={"20px"}
                      backgroundColor={"rgba(0, 0, 0, 0.15)"}
                      borderRadius={"5px"}
                      backdropFilter="auto"
                      backdropBlur="2px"
                      boxShadow={
                        "0px 0px 32.5px -12px rgba(1, 177, 216, 0.45) inset"
                      }
                    >
                      <Image
                        alt="Products of TrackGenesis"
                        mx={"auto"}
                        mt={{ base: "-50px", md: "-90px", lg: "-90px" }}
                        width={{ base: "100px", md: "130px", lg: "135px" }}
                        src={Supplychain}
                      ></Image>
                      <Text
                        mt={{ base: "0", md: "-15px", lg: "-15px" }}
                        textAlign="center"
                        mx={"auto"}
                        fontSize={{ base: "11px", md: "11px", lg: "15px" }}
                        color={"white"}
                        className="content_font"
                        fontWeight={"light"}
                      >
                        Supply Chain Traceability Platform
                      </Text>
                    </Flex>
                  </Box>
                </HashLink>

                {/*•	Token Distribution Platform */}
                <HashLink smooth to={"/products#tokendistribution"}>
                  <Box p={{ base: "10px", md: "10px", lg: "20px" }}>
                    {" "}
                    <Flex
                      width={{ base: "100px", md: "180px", lg: "250px" }}
                      height={{ base: "110px", md: "100px", lg: "130px" }}
                      flexDir={"column"}
                      p={"20px"}
                      backgroundColor={"rgba(0, 0, 0, 0.15)"}
                      borderRadius={"5px"}
                      backdropFilter="auto"
                      backdropBlur="2px"
                      boxShadow={
                        "0px 0px 32.5px -12px rgba(1, 177, 216, 0.45) inset"
                      }
                    >
                      <Image
                        alt="Products of TrackGenesis"
                        mx={"auto"}
                        mt={{ base: "-50px", md: "-80px", lg: "-90px" }}
                        width={{ base: "100px", md: "120px", lg: "140px" }}
                        src={TGBook}
                      ></Image>
                      <Text
                        mt={{ base: "0", md: "-15px", lg: "-15px" }}
                        textAlign="center"
                        mx={"auto"}
                        fontSize={{ base: "11px", md: "11px", lg: "15px" }}
                        color={"white"}
                        className="content_font"
                        fontWeight={"light"}
                      >
                        Token Distribution <br />
                        Platform
                      </Text>
                    </Flex>
                  </Box>
                </HashLink>

                {/* Asset Tracking Platform */}
                <HashLink smooth to={"/products#assettracking"}>
                  <Box p={{ base: "10px", md: "10px", lg: "20px" }}>
                    {" "}
                    <Flex
                      width={{ base: "100px", md: "180px", lg: "250px" }}
                      height={{ base: "110px", md: "100px", lg: "130px" }}
                      flexDir={"column"}
                      p={"20px"}
                      backgroundColor={"rgba(0, 0, 0, 0.15)"}
                      borderRadius={"5px"}
                      backdropFilter="auto"
                      backdropBlur="2px"
                      boxShadow={
                        "0px 0px 32.5px -12px rgba(1, 177, 216, 0.45) inset"
                      }
                    >
                      <Image
                        alt="Products of TrackGenesis"
                        mx={"auto"}
                        mt={{ base: "-50px", md: "-90px", lg: "-90px" }}
                        width={{ base: "100px", md: "130px", lg: "150px" }}
                        src={Asset}
                      ></Image>
                      <Text
                        mt={{ base: "0", md: "-15px", lg: "-15px" }}
                        textAlign="center"
                        mx={"auto"}
                        fontSize={{ base: "11px", md: "11px", lg: "15px" }}
                        color={"white"}
                        className="content_font"
                        fontWeight={"light"}
                      >
                        Asset Tracking <br /> Platform
                      </Text>
                    </Flex>
                  </Box>
                </HashLink>
              </Flex>
              {/* third row in phone */}

              <Flex
                mt={{ base: "20px", md: "20px", lg: "20px" }}
                mx={"auto"}
                width={"80%"}
                justifyContent={"center"}
              >
                {/* TG Data Sharing Platform */}
                <Flex display={{ base: "block", md: "block", lg: "none" }}>
                  <HashLink smooth to={"/products#datasharingplatform"}>
                    <Box p={{ base: "10px", md: "10px", lg: "20px" }}>
                      {" "}
                      <Flex
                        width={{ base: "100px", md: "180px", lg: "250px" }}
                        height={{ base: "110px", md: "100px", lg: "130px" }}
                        flexDir={"column"}
                        p={"20px"}
                        backgroundColor={"rgba(0, 0, 0, 0.15)"}
                        borderRadius={"5px"}
                        backdropFilter="auto"
                        backdropBlur="2px"
                        boxShadow={
                          "0px 0px 32.5px -12px rgba(1, 177, 216, 0.45) inset"
                        }
                      >
                        <Image
                          alt="Products of TrackGenesis"
                          mx={"auto"}
                          mt={{ base: "-50px", md: "-80px", lg: "-90px" }}
                          width={{ base: "100px", md: "130px", lg: "150px" }}
                          src={Data}
                        ></Image>
                        <Text
                          mt={{ base: "0", md: "-15px", lg: "-15px" }}
                          textAlign="center"
                          mx={"auto"}
                          fontSize={{ base: "11px", md: "11px", lg: "15px" }}
                          color={"white"}
                          className="content_font"
                          fontWeight={"light"}
                        >
                          TG Data Sharing <br /> Platform
                        </Text>
                      </Flex>
                    </Box>
                  </HashLink>
                </Flex>

                {/* certicheck */}
                <Flex display={{ base: "block", md: "block", lg: "none" }}>
                  <HashLink smooth to={"/products#tgcerticheck"}>
                    <Box p={{ base: "10px", md: "10px", lg: "20px" }}>
                      {" "}
                      <Flex
                        width={{ base: "100px", md: "180px", lg: "250px" }}
                        height={{ base: "110px", md: "100px", lg: "130px" }}
                        flexDir={"column"}
                        p={"20px"}
                        backgroundColor={"rgba(0, 0, 0, 0.15)"}
                        borderRadius={"5px"}
                        backdropFilter="auto"
                        backdropBlur="2px"
                        boxShadow={
                          "0px 0px 32.5px -12px rgba(1, 177, 216, 0.45) inset"
                        }
                      >
                        <Image
                          alt="Products of TrackGenesis"
                          mx={"auto"}
                          mt={{ base: "-50px", md: "-80px", lg: "-90px" }}
                          width={{ base: "100px", md: "130px", lg: "150px" }}
                          src={Cert}
                        ></Image>
                        <Text
                          mt={{ base: "0", md: "-15px", lg: "-15px" }}
                          textAlign="center"
                          mx={"auto"}
                          fontSize={{ base: "11px", md: "11px", lg: "15px" }}
                          color={"white"}
                          className="content_font"
                          fontWeight={"light"}
                        >
                          TG-Certicheck
                        </Text>
                      </Flex>
                    </Box>
                  </HashLink>
                </Flex>
              </Flex>

              {/* third row in desktop , and 4th row in phone*/}
              <Flex
                mt={{ base: "20px", md: "20px", lg: "20px" }}
                mx={"auto"}
                width={"80%"}
                justifyContent={"center"}
              >
                {/* Document Verification Platform */}
                <HashLink smooth to={"/products#documentverification"}>
                  <Box p={{ base: "10px", md: "10px", lg: "20px" }}>
                    {" "}
                    <Flex
                      width={{ base: "100px", md: "180px", lg: "250px" }}
                      height={{ base: "110px", md: "100px", lg: "130px" }}
                      flexDir={"column"}
                      p={"20px"}
                      backgroundColor={"rgba(0, 0, 0, 0.15)"}
                      borderRadius={"5px"}
                      backdropFilter="auto"
                      backdropBlur="2px"
                      boxShadow={
                        "0px 0px 32.5px -12px rgba(1, 177, 216, 0.45) inset"
                      }
                    >
                      <Image
                        alt="Products of TrackGenesis"
                        mx={"auto"}
                        mt={{ base: "-50px", md: "-90px", lg: "-90px" }}
                        width={{ base: "100px", md: "130px", lg: "140px" }}
                        src={Doc}
                      ></Image>
                      <Text
                        mt={{ base: "0", md: "-15px", lg: "-15px" }}
                        textAlign="center"
                        mx={"auto"}
                        fontSize={{ base: "11px", md: "11px", lg: "15px" }}
                        color={"white"}
                        className="content_font"
                        fontWeight={"light"}
                      >
                        Document Verification <br /> Platform
                      </Text>
                    </Flex>
                  </Box>
                </HashLink>
              </Flex>
            </Flex>
          </Flex>

          {/* Baas */}
          <Baas />

          {/* nft platform */}
          <NftPlatform />

          {/* Data Sharing Paltform */}
          <DataSharingPlatform />

          {/* TGCerticheck */}
          <TGCerticheck />
          {/* Supply chain platfrom */}
          <SupplyChain />
          {/* Token distribution platform */}
          <TokenDistribution/>
          {/* Asset Tracking */}
          <AssetTracking/>
          {/* Document Verification */}
          <DocumentVerification/>
        </Flex>

        <ParticleBackground />
        <Footer />
      </Flex>
    </>
  );
}

export default Products;
