import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Link,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { HashLink } from "react-router-hash-link";

import CommonButton from "../../../components/CommonButton.js";

import Certgif from "../../../assets/images/ProductsPage/Gifs/TG certicheck.gif";
import Underline from "../../../assets/images/HomePage/underline.webp";
import VideoButton from "./VideoButton.js";
function TGCerticheck() {
  return (
    // new code

    <Flex
      id="tgcerticheck"
      mt={{ base: "40px", md: "80px", lg: "200px" }}
      flexDir={"column"}
      width={"100%"}
    >
      {/* title */}
      <Flex my={{ base: "30px", md: "10px", lg: "10px" }} mx={"auto"}>
        <Image
          mt={{ base: "6px", lg: "15px" }}
          height={"40px"}
          width={"70px"}
          src={Underline}
          alt="underline"
        ></Image>
        <Text
          className="heading"
          color={"#94DAF8"}
          fontSize={{ base: "24px", md: "38px", lg: "45px" }}
        >
          TG-CertiCheck
        </Text>
        <Image
          mt={{ base: "6px", lg: "15px" }}
          height={"40px"}
          width={"70px"}
          src={Underline}
          alt="underline"
        ></Image>
      </Flex>
      {/* content */}
      <Flex
        flexDir={{ base: "column-reverse", md: "column-reverse", lg: "row" }}
        mx={"auto"}
        width={{ base: "100%", md: "90%", lg: "80%" }}
        mt={{ base: "10px", md: "40px", lg: "70px" }}
      >
        <Flex
          ml={{ base: "0px", md: "0px", lg: "0px" }}
          p={{ base: "10px", md: "10px", lg: "30px" }}
          mt={{ base: "40px", md: "40px", lg: "0px" }}
          width={{ base: "100%", md: "100%", lg: "50%" }}
        >
          <Flex
            width={"100%"}
            px={"40px"}
            height={"fit-content"}
            mx={"auto"}
            my={"auto"}
            flexDir={"column"}
          >
            {" "}
            <Text
              width={{ base: "100%", md: "100%", lg: "100%" }}
              fontSize={{ base: "14px", md: "14px", lg: "16px" }}
              color={"white"}
              className="content_font"
              fontWeight={"light"}
            >
              TrackGenesis developed a user-friendly  blockchain-based platform
              for effortlessly generating and verifying digital certificates
              without requiring specialized knowledge. Seamlessly integrate this
              platform into any existing systems using APIs. Perfect for
              educational institutions, colleges, or regulatory authorities
              aiming to provide easily verifiable certifications. Individuals
              can check the legitimacy of the document on the blockchain,
              complete with a timestamp and issuer details.
            </Text>
            <Flex>
              <HashLink smooth to={"/contact-us#contact"}>
                <CommonButton
                  width="100px"
                  btn="Book a Demo"
                  mt={{ base: "30px", md: "40px", lg: "30px" }}
                />
              </HashLink>
              <Link
                _hover={{ textDecoration: "none" }}
                isExternal
                href="https://youtu.be/PF_z1VDfutg?si=RGWmV04X6dQcEwo5"
              >
                <VideoButton
                  width={{ base: "110px", md: "130px", lg: "150px" }}
                  ml="10px"
                  mt={{ base: "30px", md: "40px", lg: "30px" }}
                />
              </Link>
            </Flex>
          </Flex>
        </Flex>

        <Flex
          px={{ base: "20px", md: "30px", lg: "20px" }}
          mt={{ base: "40px", md: "40px", lg: "0px" }}
          width={{ base: "100%", md: "100%", lg: "50%" }}
        >
          <Flex
            mx={"auto"}
            my={"auto"}
            borderRadius={"10px"}
            width={{ base: "400px", md: "500px", lg: "650px" }}
          >
            <Flex
              boxShadow="0px 0px 12.7px 0px #C3F29F"
              mt={{ base: "-10px", md: "-10px", lg: "-10px" }}
              borderRadius={{ base: "40px", md: "50px", lg: "70px" }}
              zIndex={-1}
              border={"1px solid #C3F29F"}
            >
              <Flex>
                <Image src={Certgif}></Image>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default TGCerticheck;
