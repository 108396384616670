import { Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import { HashLink } from "react-router-hash-link";

import BlockchainInFoodAndDrink from "../../../assets/images/IndustrysolutionsPage/blockchain-in-food-and-drink.webp";
import CommonButton from "../../../components/CommonButton.js";
import Underline from "../../../assets/images/HomePage/underline.webp";
function FoodAndBeveragesIndustry() {
  return (
    // new code
    <Flex mt={{ base: "20px", md: "80px", lg: "200px" }} width={"100%"}>
      {/* title */}
      <Flex
        mx={"auto"}
        flexDir={{ base: "column-reverse", md: "row", lg: "row" }}
      >
        <Flex
          width={{ base: "100%", md: "60%", lg: "50%" }}
          flexDir={"column"}
          ml={{ base: "6%", md: "6%", lg: "10%" }}
        >
          <Text
            className="heading"
            color={"#94DAF8"}
            fontSize={{ base: "24px", md: "38px", lg: "45px" }}
          >
            Blockchain in Food & Drink
          </Text>
          <Image
            mt={"-10px"}
            ml={"-10px"}
            width={"70px"}
            src={Underline}  alt="underline"
          ></Image>

          <Text
            mt={{ base: "30px", md: "40px", lg: "80px" }}
            pr={{ base: "40px", md: "30px", lg: "30px" }}
            maxW={{ base: "", md: "380px", lg: "650px" }}
            fontSize={{ base: "14px", md: "14px", lg: "16px" }}
            color={"white"}
            className="content_font"
            fontWeight={"light"}
          >
            Blockchain virtually connects the people who grow your food to the
            people who consume it, ensuring complete transparency in the food
            supply chain. It can calculate the net carbon emissions of a product
            during its entire journey to the customer, which takes you one step
            further to your sustainability goals. Businesses share the true
            story of their product with customers through a single QR code.
            Customers can trace a product’s journey, from what it was to what it
            is, in the most trusted way.
            <br /> <br />
            Seafood traceability is one of the finest applications of blockchain
            technology in food and drink. Blockchain assures transparency in the
            global supply chain to eliminate seafood fraud.
          </Text>
          <HashLink smooth to={"/contact-us#contact"}>
            <CommonButton
            width="100px"
              btn="Book a Demo"
              mt={{ base: "30px", md: "60px", lg: "30px" }}
            />
          </HashLink>
        </Flex>
        <Flex p={"20px"} width={{ base: "100%", md: "60%", lg: "50%" }}>
          <Image
            my={{ base: "30px", md: "0px", lg: "0px" }}
            objectFit={"contain"}
            // srcSet={`${BlockchainInFoodAndDrinkSmall} 1000w, ${BlockchainInFoodAndDrink} 2000w`}
            src={BlockchainInFoodAndDrink}
            width={"100%"}
            alt="Food supply beverage technology blockchain"
            pointerEvents={"none"}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default FoodAndBeveragesIndustry;
