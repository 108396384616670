
import Edublog from "../../src/assets/images/BlogsPage/edu.webp";
import foodblog from "../../src/assets/images/BlogsPage/blogone.webp";
import blogtwo from "../../src/assets/images/BlogsPage/blogtwo.webp";
import blogfive from "../../src/assets/images/BlogsPage/palmoil.webp";
import blogfour from "../../src/assets/images/BlogsPage/blogfour.webp";
import blogthree from "../../src/assets/images/BlogsPage/blogthree.webp";
import softblog from "../../src/assets/images/BlogsPage/softblog.webp";
import sherinblog from "../../src/assets/images/BlogsPage/sherinprofile.webp";
import anjublog from "../../src/assets/images/BlogsPage/anjublog.webp"
import chrisblog from "../../src/assets/images/BlogsPage/chrisblog.webp"
import justinblog from "../../src/assets/images/BlogsPage/justinprofile.webp"

const blogdata = [
  {
    bloglink: "/blogs/How-Blockchain-Technology-Help-Education-and-Training-Institutions-to-Fight-Fake-Certificates#Education-Trackgenesis",
     TopicimagePath: Edublog,
     blogerimagePath:sherinblog,
     Date:"15 July 2024",
     BlogTitle:"How Blockchain Technology Help Education and Training Institutions to Fight Fake Certificates?",
     description:"AI is the latest breakthrough in the .. "
   },
  {
    bloglink: "/blogs/your-perfect-software-development-partner-TrackGenesis-Aberdeen#Software-Trackgenesis",
     TopicimagePath: softblog,
     blogerimagePath:sherinblog,
     Date:"17 April 2024",
     BlogTitle:"Choosing the Right Software Development Company: A Guide for Businesses",
     description:"Your customers are having a horrible online.. "
   },
  
  {
    bloglink: "/blogs/brands-blockchain#luxurybrands-blockchain",
    TopicimagePath: "https://cdn-images-1.medium.com/max/1024/1*ce863znAlpKyPxr01czsFw.png",
    blogerimagePath:sherinblog,
    Date:"06 March 2024",
    BlogTitle:"From Counterfeits to Confidence: Luxury Brands Use Blockchain for Trust",
    description:"How do you feel when you can find duplicates of your expensive.."
  },
  {
    bloglink: "/blogs/blog-blockchain-palm-oil#blog-palmoil",
    TopicimagePath: blogfive,
    blogerimagePath:chrisblog,
    Date:"14 April 2023",
    BlogTitle:"Blockchain Technology for Palm Oil Traceability: A Game-Changer in Sustainability",
    description:"In recent years, the global demand for palm oil has surged, driven.."
  },
  {
    bloglink: "/blogs/blog-blockchain-technology#blog-whatisblockchain",
    TopicimagePath: blogtwo,
    blogerimagePath:justinblog,
    Date:"03 February 2023",
    BlogTitle:"Blockchain Explained In Simple Terms",
    description:" Blockchain is the foundation of web 3.0, that transforms..  "
  },
  {
    bloglink: "/blogs/blog-blockchain-supplychain#blog-supplychain",
    TopicimagePath: blogfour,
    blogerimagePath:sherinblog,
    Date:"16 December 2022",
    BlogTitle:"How Does Blockchain Revolutionize Supply Chain Management? ",
    description:" The supply chain network is built by multiple stakeholders and.."
  },

   {
    bloglink: "/blogs/blog-blockchain-cryptocurrency#blog-cryptocurrency",
    TopicimagePath: blogthree,
    blogerimagePath:sherinblog,
    Date:"09 November 2022",
    BlogTitle:"Blockchain Beyond Cryptocurrency",
    description:" 'Bitcoin and Blockchain' - these two terms are often heard.."
  },
 
    
  
    {
      bloglink: "/blogs/blog-blockchain#blog-food&drink",
       TopicimagePath: foodblog,
       blogerimagePath:anjublog,
       Date:"06 July 2022",
       BlogTitle:"Blockchain Technology in Food & Drink",
       description:"  Recently, Salmonella was found in some of the poultry used to.. "
     },
   

  
    
 
    

  ];
  
  export default blogdata;