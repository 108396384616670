import {  Flex, Heading, Image, Text } from "@chakra-ui/react";
import React from "react";
import Mobilenavbar from "../../../components/sections/Mobilenavbar";
import Navbar from "../../../components/sections/Navbar";
import Footer from "../../../components/sections/Footer";

import anjuprofile from "../../../assets/images/BlogsPage/anjublog.webp";
import nftblog from "../../../assets/images/BlogsPage/blogone1.webp";

import { Helmet } from "react-helmet";

function Blogpageone(props) {
  return (
    <>
      {/* helmet */}
      <Helmet>
        <title>
          Blogs | Blockchain | TrackGenesis&reg; | Aberdeen Scotland
        </title>
        <meta
          name="title"
          content="Blogs | Blockchain | TrackGenesis | Aberdeen Scotland"
        />
        <meta
          name="description"
          content=" Blockchain Technology in Food & Drink"
        />
        <link
          href="https://trackgenesis.com/blogs"
          rel="canonical"
          content="max-age: 1209600, no-cache, no-store, must-revalidate"
        />
      
      </Helmet>
      {/* helmet */}
      <Flex
        flexDirection={"column"}
        backgroundColor={"black"}
        id="blog-food&drink"
      >
        {/* heading Section */}
        <Flex
          backgroundColor={"black"}
          width="100%"
          flexDirection={"column"}
          alignItems="center"
          justifyContent={"center"}
          height={"400px"}
        >
          <Heading
            className="heading"
            color={"#94DAF8"}
            fontSize={{ md: "33px", base: "25px", lg: "37px" }}
            fontWeight="bold"
            textShadow="1px 1px #000000"
            mt={{ base: "36px", md: "35px", lg: "10px" }}
            textAlign="center"
            maxWidth={{ base: "80%", md: "65%", lg: "60%" }}
          >
            Blockchain Technology in Food & Drink
          </Heading>
        </Flex>

        <Navbar />
        <Mobilenavbar />

        <Flex
          backgroundColor={"black"}
          alignItems={"center"}
          justifyContent="center"
          width="100%"
        >
          <Image
            ml={{ base: "-5%", lg: "-5%" }}
            mt={{ base: "-90px", md: "-90px", lg: "-110px" }}
            width={{ base: "91%", md: "80%", lg: "70%" }}
            src={nftblog}
          />
        </Flex>

        <Flex
          flexDir={"column"}
          position={"relative"}
          className="mainborder"
          my={{ base: "2%", md: "2%", lg: "2%" }}
          mx={{ base: "10px", md: "15px", lg: "30px" }}
          backgroundColor={"rgba(27, 21, 78, 0.38)"}
          backdropFilter="auto"
          backdropBlur="8px"
          pb={"100px"}
        >
          <Flex flexDir={"column"}>
            <Flex
              color={"white"}
              className="content_font"
              width={"100%"}
              px={{ base: "10%", md: "15%", lg: "20%" }}
              fontSize={{ base: "15px", md: "16px", lg: "17px" }}
              flexDirection="column"
            >
              <Text mt={"30px"} className="content_font">
                Recently, Salmonella was found in some of the poultry used to
                make a number of products that contained chicken, prompting a
                major food manufacturer in the UK to recall these items. While
                the inquiry is ongoing, more goods were also recalled as a
                precaution. 
              </Text>

              <Text mt={"30px"} className="content_font">
                In the UK, this is hardly the only incidence. According to the
                CDC, salmonella germs cause roughly 1.35 million illnesses, 2650
                hospitalizations, and 420 fatalities just in the United States
                each year. Salmonella species are responsible for 93.8 million
                cases of gastroenteritis worldwide each year. According to
                estimates, 80.3 million of these cases were foodborne. 
              </Text>
              <Text mt={"30px"} className="content_font">
                Salmonella is not the only bacteria that can cause food
                poisoning. Numerous other deadly bacteria and viruses can lead
                to serious health conditions. Listeria is one among them.
                Listeria outbreaks linked to Ice cream, fish, meat and cheese
                was found by Centre for Disease Control and Prevention in recent
                times. 
              </Text>
              <Text
                mt={"30px"}
                className="content_font"
                fontWeight={"bold"}
                fontSize={"20px"}
              >
                Why Salmonella, Listeria Outbreaks Occur So Frequently through
                Food & Drink?
              </Text>
              <Text mt={"30px"} className="content_font">
                <Text as={"i"} fontWeight="bold">
                  Lack of transparency in the food supply chain is the main
                  reason for such food borne diseases.
                </Text>{" "}
                The person who consumes the food as well as the one who sell it
                are ignorant of number of factors, including the supply chain
                participants, the environment in which the ingredients were
                processed, where the ingredients were grown, who grew them for
                them, and much more.
              </Text>
              <Text mt={"30px"} className="content_font">
                They should have the option to confirm the quality checks &
                certifications (such as sustainable, cruelty-free, vegan,
                organic product, FSA certification, etc…) offered by the product
                rather than trusting anything that is labelled on it.
              </Text>
              <Text mt={"30px"} className="content_font">
                <Text as={"i"} fontWeight="bold">
                  A food company alone can’t resolve the issue. For that, the
                  entire food supply chain should take action.
                </Text>{" "}
                Most food producers only have visibility to tier 1 suppliers and
                they have limited knowledge of rest of their supply chain. They
                don’t know the suppliers in the lower tiers and the connection
                between them. Ignoring the data transparency in the supply chain
                can lead to serious problems on the go. 
              </Text>
              <Text mt={"30px"} className="content_font">
                As a result, food supply chain demands a comprehensive system to
                trace the entire journey of a product. This not only benefit the
                consumer, but also make it possible for the manufacturer/store
                to market a product with confidence. 
              </Text>
              <Text
                mt={"30px"}
                className="content_font"
                fontWeight={"bold"}
                fontSize={"20px"}
              >
                How Can Companies Fix Lack of Transparency in Food Supply Chain?
              </Text>
              <Text mt={"30px"} className="content_font">
                Knowing all the supply chain actors and their actions is the
                first step in establishing transparency in the network. 
              </Text>
              <Text mt={"30px"} className="content_font">
                In the traditional approach, recording of all these data require
                bulk paper works and human effort. It is indeed a time consuming
                and complex process. 
              </Text>
              <Text mt={"30px"} className="content_font">
                It’s high time to implement an efficient system in the food
                supply chain for better risk management. 
              </Text>
              <Text
                mt={"30px"}
                as={"i"}
                fontWeight="bold"
                className="content_font"
              >
                One of the latest trends in the technological world known as
                `Blockchain’ is the ideal solution for this problem. 
              </Text>
              <Text mt={"30px"} className="content_font">
                <Text as={"i"} fontWeight="bold">
                  Blockchain keeps a distributed ledger of immutable information
                  about a product dating back to its inception.
                </Text>{" "}
                Customers can have confidence in the products they purchase, and
                we can minimise health risks such as Salmonella virus attacks. 
              </Text>
              <Text mt={"30px"} className="content_font">
                <Text as={"i"} fontWeight="bold">
                  By incorporating blockchain technology into the supply chain
                  network, network participants can ensure the quality of their
                  product by tracing the journey of the ingredients they use.
                </Text>{" "}
                As a result, Blockchain Technology can ensure transparency in
                the food and beverage industry.
              </Text>
            </Flex>
            <Flex my={"20px"} width={"100%"}>
              <Flex ml={{ base: "40%", md: "65%", lg: "65%" }}>
                <Text
                  color={"white"}
                  className="content_font"
                  fontSize={{ md: "23px", base: "15px", lg: "25px" }}
                >
                  - by Anju K S
                </Text>
                <Image
                   objectFit={"contain"}
                   ml={{ base: "10px", md: "10px", lg: "30px" }}
                   mt={"-10px"}
                   width={{ base: "30px", md: "40px", lg: "55px" }}
                  src={anjuprofile}
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Footer />
      </Flex>
    </>
  );
}

export default Blogpageone;
