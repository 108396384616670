import Censis from "../../src/assets/images/HomePage/clients/censis.webp";
import Dyw from "../../src/assets/images/HomePage/clients/dyw.webp";
import One from "../../src/assets/images/HomePage/clients/one.webp";
import Onetech from "../../src/assets/images/HomePage/clients/onetech.webp";
import Ram from "../../src/assets/images/HomePage/clients/ram.webp";
import Rgu from "../../src/assets/images/HomePage/clients/rgu.webp";
import Ukspace from "../../src/assets/images/HomePage/clients/ukspace.webp";
import Roa from "../../src/assets/images/HomePage/clients/uoa.webp";
import Us from "../../src/assets/images/HomePage/clients/us.webp";
import Roehill from "../../src/assets/images/HomePage/clients/roehill.webp";
import Wee from "../../src/assets/images/HomePage/clients/wee.webp";
const newclientdata = [
  {
    imagePath: Censis,
  },
  {
    imagePath: Dyw,
  },
 
  {
    imagePath: One,
  },
  {
    imagePath: Onetech,
  },
  {
    imagePath: Ram,
  },
  {
    imagePath: Roa,

  },
  {
    imagePath: Us,
  },
  {
    imagePath: Ukspace,
  },
  {
    imagePath: Rgu,
  },
  {
    imagePath: Roehill,
  },
  {
    imagePath: Wee,
  },
  
];

export default newclientdata;
