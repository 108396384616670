import {
  Flex,
  Image,
  SimpleGrid,
  Text,
  Select,
  Button,
  FormControl,
  FormLabel,
  VStack,
  Input,
} from "@chakra-ui/react";
import React from "react";
import faqdatas from "../../../database/faqdata";
import Uparrow from "../../../assets/images/BlogsPage/arrw.png";
import Arrow from "../../../assets/images/BlogsPage/dwnarrw.png";
import Underline from "../../../assets/images/HomePage/underline.webp";
import { useState } from "react";
import { useForm as useFormspree } from "@formspree/react";
import { useForm } from "react-hook-form";
import "./blogstyle.css";
import { HashLink } from "react-router-hash-link";
import { useNavigate } from "react-router-dom";
function FAQ() {
  const [openIndex, setOpenIndex] = useState(null);

  const handleBoxClick = (index) => {
    setOpenIndex(index === openIndex ? null : index);
  };
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [service, setService] = useState();

  const navigate = useNavigate();

  // react form hook
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // formspree
  const [serverState, sendToFormspree] = useFormspree("xzbnevqr");

  // on succesfull submission of form in formspree
  if (serverState.succeeded) {
    navigate("/home");
  }

  // FAQ schema data
  const faqSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: faqdatas.map((faqdata, index) => ({
      "@type": "Question",
      name: faqdata.question,
      acceptedAnswer: {
        "@type": "Answer",
        text: faqdata.description,
      },
    })),
  };

  return (
    <Flex
      my={{ base: "20px", md: "30px", lg: "60px" }}
      flexDir={"column"}
      width={"100%"}
    >
      {/* heading */}
      <Flex my={{ base: "30px", md: "10px", lg: "10px" }} mx={"auto"}>
        <Image
          alt="underline"
          mt={{ base: "6px", lg: "15px" }}
          height={"40px"}
          width={"70px"}
          src={Underline}
        ></Image>
        <Text
          className="heading"
          color={"#94DAF8"}
          fontSize={{ base: "24px", md: "38px", lg: "45px" }}
        >
          FAQs
        </Text>
        <Image
          alt="underline"
          mt={{ base: "6px", lg: "15px" }}
          height={"40px"}
          width={"70px"}
          src={Underline}
        ></Image>
      </Flex>

      <Flex width={"100%"}>
        <SimpleGrid
          width={{ base: "90%", md: "90%", lg: "75%" }}
          mt={{ base: "20px", md: "20px", lg: "70px" }}
          mx={"auto"}
          columns={[1, null, 2]}
          spacing="10px"
        >
          {faqdatas.map((faqdata, index) => (
            <Flex
              key={index}
              flexDir={"column"}
              mx={"auto"}
              borderLeft="2px solid #01B1D8"
              p={"10px"}
              backgroundColor={"rgba(0, 0, 0, 0.15)"}
              borderRadius={"5px"}
              backdropFilter="auto"
              backdropBlur="2px"
              boxShadow={"0px 0px 22.5px -12px rgba(1, 177, 216, 0.45) inset"}
              onClick={() => handleBoxClick(index)}
              style={{
                height: index === openIndex ? "200px" : "60px",
                transition: "height 0.4s linear",
              }}
            >
              <Flex width={"100%"}>
                <Text
                  py={"10px"}
                  my={"auto"}
                  fontSize={{ base: "13px", md: "13px", lg: "14px" }}
                  color={"white"}
                  className="content_font"
                  fontWeight={"light"}
                >
                  {faqdata.question}
                </Text>
                <Image
                  mr={"10px"}
                  ml={"auto"}
                  objectFit={"contain"}
                  width={{ base: "12px", md: "13px", lg: "14px" }}
                  src={openIndex === index ? Uparrow : Arrow}
                ></Image>
              </Flex>

              <Flex
                style={{
                  opacity: index === openIndex ? 1 : 0,
                  transition: "opacity 0.1s linear",
                }}
              >
                <Text
                  py={{ base: "20px", md: "20px", lg: "30px" }}
                  fontSize={{ base: "12px", md: "12px", lg: "15px" }}
                  color={"white"}
                  className="content_font"
                  fontWeight={"light"}
                >
                  {faqdata.description}
                </Text>
              </Flex>
            </Flex>
          ))}
          {/* extra faq */}
          <Flex
            flexDir={"column"}
            mx={"auto"}
            borderLeft="2px solid #01B1D8"
            p={"10px"}
            backgroundColor={"rgba(0, 0, 0, 0.15)"}
            borderRadius={"5px"}
            backdropFilter="auto"
            backdropBlur="2px"
            boxShadow={"0px 0px 22.5px -12px rgba(1, 177, 216, 0.45) inset"}
            onClick={() => handleBoxClick(123)}
            style={{
              height: 123 === openIndex ? "200px" : "60px",
              transition: "height 0.4s linear",
            }}
          >
            <Flex width={"100%"}>
              <Text
                py={"10px"}
                my={"auto"}
                fontSize={{ base: "13px", md: "13px", lg: "14px" }}
                color={"white"}
                className="content_font"
                fontWeight={"light"}
              >
                How do you create an NFT?
              </Text>
              <Image
                mr={"10px"}
                ml={"auto"}
                objectFit={"contain"}
                width={{ base: "12px", md: "13px", lg: "14px" }}
                src={openIndex === 123 ? Uparrow : Arrow}
              ></Image>
            </Flex>

            <Flex
              style={{
                opacity: 123 === openIndex ? 1 : 0,
                transition: "opacity 0.1s linear",
              }}
            >
              <Text
                py={{ base: "20px", md: "20px", lg: "30px" }}
                fontSize={{ base: "12px", md: "12px", lg: "15px" }}
                color={"white"}
                className="content_font"
                fontWeight={"light"}
              >
                You can create NFT using our
                <HashLink smooth className="decor" to={"/products#nftplatform"}>
                  NFT plug-and-play platform
                </HashLink>
                , even without any coding skills. Through a simple user
                interface, our software will enable users to upload their
                digital assets and mint NFT for them.
              </Text>
            </Flex>
          </Flex>

          {/* quote faq */}
          <Flex
            width={"100%"}
            flexDir={"column"}
            mx={"auto"}
            borderLeft="2px solid #01B1D8"
            p={"10px"}
            backgroundColor={"rgba(0, 0, 0, 0.15)"}
            borderRadius={"5px"}
            backdropFilter="auto"
            backdropBlur="2px"
            boxShadow={"0px 0px 22.5px -12px rgba(1, 177, 216, 0.45) inset"}
            style={{
              height: 124 === openIndex ? "200px" : "60px",
              transition: "height 0.4s linear",
            }}
          >
            <Flex onClick={() => handleBoxClick(124)} width={"100%"}>
              <Text
                py={"0px"}
                my={"auto"}
                pr={"10px"}
                fontSize={{ base: "13px", md: "13px", lg: "14px" }}
                color={"white"}
                className="content_font"
                fontWeight={"light"}
              >
                What is the average pricing of blockchain, NFT, AR/VR and
                software development services?
              </Text>
              <Image
                mr={"10px"}
                ml={"auto"}
                objectFit={"contain"}
                width={{ base: "12px", md: "13px", lg: "14px" }}
                src={openIndex === 124 ? Uparrow : Arrow}
              ></Image>
            </Flex>

            <Flex
              style={{
                opacity: 124 === openIndex ? 1 : 0,
                transition: "opacity 0.1s linear",
              }}
            >
              <Flex flexDir={{ base: "column", md: "column", lg: "row" }}>
                <Text
                  py={{ base: "10px", md: "10px", lg: "10px" }}
                  fontSize={{ base: "12px", md: "12px", lg: "15px" }}
                  color={"white"}
                  className="content_font"
                  fontWeight={"light"}
                >
                  Know the pricing:
                </Text>
                {/* Pricing form */}
                <Flex
                  style={{
                    opacity: 124 === openIndex ? 1 : 0,
                    transition: "opacity 0.1s linear",
                  }}
                >
                  <form onSubmit={handleSubmit(sendToFormspree)}>
                    <SimpleGrid
                      ml={{ base: "", md: "", lg: "20px" }}
                      mt={{ base: "", md: "", lg: "10px" }}
                      columns={2}
                      spacing={2}
                    >
                      <FormControl>
                        <Input
                          border={"1px solid "}
                          borderColor={"#94DAF8"}
                          height={"30px"}
                          color={"white"}
                          className="content_font"
                          fontWeight={"light"}
                          boxShadow={"inner"}
                          bgColor={"#0B091E"}
                          id="name"
                          type="text"
                          value={name}
                          placeholder="Name"
                          name="name"
                          {...register("name", {
                            required: "Name field is required",
                          })}
                        />
                        <Text className="error">{errors?.name?.message}</Text>
                      </FormControl>

                      <FormControl>
                        <Input
                          height={"30px"}
                          placeholder="Email"
                          border={"1px solid "}
                          borderColor={"#94DAF8"}
                          color={"white"}
                          className="content_font"
                          fontWeight={"light"}
                          boxShadow={"inner"}
                          bgColor={"#0B091E"}
                          id="email"
                          value={email}
                          name="email"
                          {...register("email", {
                            required: "Email is required",
                            pattern: {
                              value: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i,
                              message: "Invalid email format",
                            },
                          })}
                        />
                        <Text className="error">{errors?.email?.message}</Text>
                      </FormControl>

                      <FormControl>
                        <Select
                          height={"30px"}
                          border={"1px solid "}
                          borderColor={"#94DAF8"}
                          color={"white"}
                          className="content_font"
                          fontWeight={"light"}
                          boxShadow={"inner"}
                          backgroundColor={"#0B091E"}
                          id="service"
                          value={service}
                          name="service"
                        >
                          <option
                            style={{
                              backgroundColor: "#0B091E",
                              color: "white",
                            }}
                            value="Blockchain"
                          >
                            Blockchain{" "}
                          </option>
                          <option
                            style={{
                              backgroundColor: "#0B091E",
                              color: "white",
                            }}
                            value="NFT"
                          >
                            NFT
                          </option>
                          <option
                            style={{
                              backgroundColor: "#0B091E",
                              color: "white",
                            }}
                            value="AR/VR"
                          >
                            AR/VR
                          </option>
                          <option
                            style={{
                              backgroundColor: "#0B091E",
                              color: "white",
                            }}
                            value="Software"
                          >
                            Software Solutions
                          </option>
                        </Select>
                      </FormControl>

                      <Flex width={"100%"} justifyContent="end">
                        <Flex
                          borderRadius={"6px"}
                          padding={"2px"}
                          width={"fit-content"}
                          height={"fit-content"}
                          border="1px solid #01B0D7"
                        >
                          <Button
                            width={{ base: "68px", md: "80px", lg: "90px" }}
                            height={{ base: "16px", md: "20px", lg: "30px" }}
                            className="heading"
                            backgroundColor={"#01B0D7"}
                            _hover={{ bg: "#8DE6F9" }}
                            fontSize={{
                              base: "12px",
                              md: "13px",
                              lg: "15px",
                            }}
                            type="submit"
                            disabled={serverState.submitting}
                          >
                            Submit
                          </Button>
                        </Flex>
                      </Flex>
                    </SimpleGrid>
                    {serverState.errors &&
                      serverState.errors.length > 0 &&
                      serverState.errors.map((err, index) => (
                        <Text key={index}>{err.message}</Text>
                      ))}
                  </form>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </SimpleGrid>
      </Flex>
      {/* JSON-LD script tag for FAQ schema */}
      <script type="application/ld+json">{JSON.stringify(faqSchema)}</script>
    </Flex>
  );
}

export default FAQ;
