import React from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { useCallback } from "react";

function ParticleTrail() {
  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);
  const particlesLoaded = useCallback(async (container) => {
    await console.log();
  }, []);

  return (
    <div>
      <Particles
        id="tsparticlestrail"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          fpsLimit: 120,

          particles: {
            number: {
              value: 0,
              density: {
                enable: true,
                value_area: 800,
              },
            },

            color: {
              value: ["#BB86D4", "#768FE1", "#BB86D4"],
            },

            shape: {
              type: "circle",

              stroke: {
                width: 0,
                color: "#000000",
              },
              polygon: {
                nb_sides: 6,
              },
            },
            opacity: {
              value: 0.5,
              random: false,
              anim: {
                enable: false,
                speed: 3,
                opacity_min: 0.1,
                sync: false,
              },
            },
            size: {
              value: 4,
              random: {
                enable: true,
                minimumValue: 2,
              },
              animation: {
                enable: true,
                speed: 10,
                minimumValue: 0.4,
                sync: true,
                startValue: "min",
                destroy: "max",
              },
            },
            links: {
              enable: false,
            },
            move: {
              enable: true,
              speed: 3.5,
              direction: "none",
              random: false,
              straight: false,
              outMode: "destroy",
              attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200,
              },
            },
          },
          interactivity: {
            detectsOn: "window",
            events: {
              onhover: {
                enable: true,
                mode: "trail",
              },
              onclick: {
                enable: true,
                mode: "push",
              },
              resize: true,
            },
            modes: {
              grab: {
                distance: 400,
                line_linked: {
                  opacity: 1,
                },
              },
              bubble: {
                distance: 400,
                size: 40,
                duration: 2,
                opacity: 0.8,
                speed: 3,
              },
              repulse: {
                distance: 200,
              },
              push: {
                particles_nb: 4,
              },
              remove: {
                particles_nb: 2,
              },
              trail: {
                delay: 0.002,
                quantity: 1,
                pauseOnStop: true,
              },
            },
          },
          detectRetina: true,
        }}
      />
    </div>
  );
}

export default ParticleTrail;
